import cookie from "react-cookies";
import axios from "axios";
import API from "../../settings/api";

export async function full(store) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из full video`);
    return false;
  }

  const { selected_locations, startDate } = store.state;

  const { status, data } = await axios.post(API.video.full, {
    token: token,
    location_id: selected_locations[0],
    date: startDate,
  });

  console.log(`FullVideo status: ${status}`);

  if (status === 200 && data?.link) {
    store.setState({
      full_video_link: data.link,
      full_video_location_name: data.location_name,
    });

    return data.link;
  } else {
    console.error(data.text);
  }
}
