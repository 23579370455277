import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

export async function all(store, reload = false) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из all visitors`);
    return false;
  }

  reload && reload();

  const { status, data } = await axios.post(API.visitors.all, { token: token });

  console.log(`Visitors status: ${status}`);

  if (status === 200 && data?.visitors) {
    store.setState({
      visitorTable: data.visitors,
      visitorTableLoaded: true,
    });

    return data.visitors;
  } else {
    console.error(data.text);
  }
}

export async function add(form, reload) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из add visitors`);
    return false;
  }

  const params = {
    token,
    ...form,
  };

  console.log(`visitors.add form:`, params);

  const res = await axios.post(API.visitors.add, params).catch(function (error) {
    if (error.response.status === 409) {
      return false;
    }
    return error.response;
  });

  if (!res) {
    return alert(
      "Транспортное средство с таким государственным номером уже было добавлено ранее!"
    );
  }

  const { status, data } = res;

  if (status === 200) {
    reload();
  } else {
    console.error(data.text);
  }
}

export async function edit(form, reload) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из edit visitors`);
    return false;
  }

  const params = {
    token,
    ...form,
  };

  console.log(`Visitors.edit form:`, params);

  const { status, data } = await axios.post(API.visitors.edit, params);

  if (status === 200) {
    reload();
  } else {
    console.error(data.text);
  }
}

export async function get({ id, set }) {
  const token = cookie.load("token");

  if (!token) {
    console.error(`Попытка разлогина из get visitors`);
    return false;
  }

  const { status, data } = await axios.post(API.visitors.get, {
    token: token,
    id: id,
  });

  if (status === 200 && data?.data) {
    set(data.data);
  } else {
    console.error(data.text);
  }
}

export async function remove(store, id) {
  if (
    window.confirm(
      "Вы действительно хотите удалить данного посетителя? Отменить это действие невозможно!"
    )
  ) {
    const token = cookie.load("token");
    if (!token) {
      console.error(`Попытка разлогина из remove visitors`);
      return false;
    }

    const { status, data } = await axios.post(API.visitors.remove, {
      token: token,
      id: id,
    });

    if (status === 200) {
      store.actions.visitors.all();
    } else {
      console.error(data.text);
    }
  }
}
