import axios from "axios";
import cookie from "react-cookies";
import fileConvertBase64 from "../../scripts/helpers/file.convert.base64";
import API from "../../settings/api";

export async function all(store, reload = false) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из all cameras`);
    return false;
  }

  const { status, data } = await axios.post(API.cameras.all, { token: token });

  console.log(`status: ${status}, tree:`, data.tree);

  if (status === 200 && data?.cameras) {
    store &&
      store.setState({
        camerasTable: data.cameras,
        camerasTableLoaded: true,
      });

    reload && reload();

    return data.cameras;
  } else {
    console.error(data.text);
  }
}

export async function allWithReturn({ token }) {
  if (!token) {
    console.error("Попытка разлогина из all cameras");
    return false;
  }

  const { status, data } = await axios.post(API.cameras.all, { token: token });

  console.log("status: " + status + ", tree:", data.tree);

  if (status === 200 && data?.cameras) {
    return data.cameras;
  } else {
    console.error(data.text);
  }
}

export async function add(form, reload) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из add cameras`);
    return false;
  }

  const params = {
    token,
    ...form,
  };

  reload();

  console.log(`Camera.add form:`, params);

  const { status, data } = await axios.post(API.cameras.add, params);

  if (status === 200) {
    console.log("Camera.add:", status);
  } else {
    console.error(data.text);
  }
}

export async function edit(form, reload) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из edit cameras`);
    return false;
  }

  const params = {
    token,
    ...form,
  };

  console.log(`Camera.edit form:`, params);

  reload();

  const { status, data } = await axios.post(API.cameras.edit, params);

  if (status === 200) {
    console.log(`Camera.edit:`, status);
  } else {
    console.error(data.text);
  }
}

export async function get({ id, set }) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из all cameras`);
    return false;
  }

  const { status, data } = await axios.post(API.cameras.get, {
    token: token,
    id: id,
  });

  if (status === 200 && data?.data) {
    set(data.data);
  } else {
    console.error(data.text);
  }
}

export async function remove(store, id) {
  if (
    window.confirm(
      "Вы действительно хотите удалить камеру и все связанные с ней локации и разметки? Отменить это действие невозможно!"
    )
  ) {
    const token = cookie.load("token");
    if (!token) {
      console.error(`Попытка разлогина из remove cameras`);
      return false;
    }

    const { status, data } = await axios.post(API.cameras.remove, {
      token: token,
      id: id,
    });

    if (status === 200) {
      store.actions.cameras.all();
    } else {
      console.error(data.text);
    }
  }
}

export async function getScreenshot(RTSPUrl, setPhotoUrl) {
  const token = cookie.load("token");

  const requestBody = {
    token: token,
    url: RTSPUrl,
  };

  try {
    const response = await axios.post(API.cameras.getScreenshot, requestBody);
    const { url } = response.data;
    setPhotoUrl(url);
  } catch (error) {
    console.error("Ошибка при получении снимка с камеры:", error);
  }
}

export async function getWrapperHashFromFile(file, set) {
  const base64 = await fileConvertBase64(file[0]);
  const result = base64.split("base64,")[1];
  //// console.log( result ); //? DEBUG OUTPUT
  if (!result) {
    alert(`Не удалось закодировать разметку в base64`);
    return false;
  }

  set(result);
}
