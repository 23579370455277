import React, { useState } from 'react';
import cssIf from '../../../../scripts/helpers/class.add.if';
import { ReactComponent as FullScreenBtn } from "./icons/fullscreen.svg";

import "./sectionWithSearch.scss";
import Search from "../../search/search.stats";

const SectionWithSearch = ({  // Для статистики!!!
  
  id = "", 
  scrollable_id = "", 
  title,
  scrollable = false,
  fullscreen = false,
  tip = false,
  minWidth = "unset", 
  minHeight = "unset", 
  maxHeight = "unset",
  className = "",
  children,
  search_disabled = false,

}) => {

  const [ fullScreenMode, setFullScreenMode ] = useState( false );

  return (

    <section

      id = { id }
      className = {`panel__sectionWithSearch ${ className } ${ cssIf( scrollable, "panel__sectionWithSearch--scrollable" ) } ${ cssIf( fullScreenMode, "fullscreen" ) }`}
      style = {{ 

        minWidth: minWidth,
        minHeight: minHeight,
        maxHeight: maxHeight

      }}
      title = { tip ? tip : null }
      onClick = { () => ( fullscreen && fullScreenMode ) && setFullScreenMode( false ) }

    >

      <div className = "title">{ title }</div>
      { !search_disabled && <div className='search'> <Search placeholder={ 'Поиск сотрудника' }/> </div> }
      { !scrollable ? children : <Scrollable id = { scrollable_id }>{ children }</Scrollable> }

      { fullscreen && 
      
        <FullScreenBtn 
          
          className = "fs_btn" 
          onClick = { () => setFullScreenMode( true ) } 
          title = "Развернуть на весь экран"
          
        /> 
        
      }
      
    </section>

  );

}

const Scrollable = ({ id = "", children }) => ( <div id = { id } className = "scrollable">{ children }</div> );

export default SectionWithSearch;