import React, {useEffect} from 'react';
import GridLikeSection from "../containers/grid-like-section";
import FaceExtended from "../face.extended";
import useGlobal from "../../../store";
import LoadingSpinner from "../loader";
import FaceWithoutPhoto from "../face.without.photo";


const FacesArea = () => {

  const noDataStyle = {
    position: "absolute",
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    top: '10%', left: 0, right: 0, bottom: 0,
    height: '12.5%', fontSize: '1.7rem', fontWeight: '500',
    color: 'var(--color-lightgray)', textShadow: '0 0 1px var(--color-darkgray)'
  }

  const [ globalState, globalActions ] = useGlobal();

  const { people } = globalActions;

  const {

    PeopleList,
    PeopleListSearched,
    PeopleListLoaded,
    facesMode,
    faces_filter_employees,
    faces_filter_visitors,
    faces_filter_unknown,
    selected_face,
    selected_face_photos,
    selected_face_photosLoaded

  } = globalState;

  useEffect(() => { !PeopleListLoaded && people.all() }, [people, PeopleListLoaded]);  // Загрузка списка сотрудников

  function sortAlphabetically(a, b) { // Сортировка по алфавиту с учётом цифр
    return a.localeCompare(b, 'ru', { numeric: true });
  }
  const filterArray = [];
  faces_filter_employees && filterArray.push('сотрудник');
  faces_filter_visitors && filterArray.push('посетитель');
  faces_filter_unknown && filterArray.push('неопределенно');


  const ActualList = (PeopleListSearched ? PeopleListSearched : PeopleList).sort( // Сортируем по выбранному режиму из popup'а справа от поиска
    facesMode === 'nameSort' ?
    (a, b) => {
    return sortAlphabetically(a.name, b.name)
    } :
    (a, b) => {
      return b.visits - a.visits
    }).filter(
      person => filterArray.includes(person.category.toLowerCase())
  )

  return (
    <div className={ 'FacesArea' }>
    { (PeopleListLoaded && ActualList.length > 0) ? <GridLikeSection
      scrollable title = {selected_face ? selected_face.name : "Лица"}
      gridTemplateColumns = {selected_face ? "repeat(auto-fill, minmax(175px, 1fr))": ""}>
      { !selected_face ? ActualList.length > 0 && ActualList.map(( item, i ) => (
        <div className={ 'face-click-division' } key = { i }>
          <FaceExtended
            id = { item.id }
            index = { i + 1 }
            name = { item?.name || "Посетитель" }
            old = { item?.old || "?" }
            sex = { item?.sex || "-" }
            role = { item?.role || "" }
            category = { item?.category || "" }
            photo = { item.photo }
            visits = { item.visits + 1 }

          />
        </div>
      )) : selected_face_photosLoaded && selected_face_photos.length > 0 ? selected_face_photos.map(( item, i ) => (
        <FaceWithoutPhoto
          key = { i }
          index = { i + 1 }
          photo = { item }
      />)) : !selected_face_photosLoaded ?
        <div className={'centerAbsolute'} style={
          {width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, position: 'absolute'}
        }>
        <LoadingSpinner/>
        </div>
        : <div className={'FacesArea__no-photos'} style={ noDataStyle }>Фотографии отсутствуют</div>  }

    </GridLikeSection> : !PeopleListLoaded ? <LoadingSpinner /> : <div className={ 'FacesArea__no-people' } style={ noDataStyle }
    >Нет зафиксированных людей</div>  }
    </div>
  )

} 

export default FacesArea;