import React from 'react';
import useGlobal from '../../../store';


import ExportView from "./export";

const Export = () => {

  const [ globalState ] = useGlobal();

  const { user_type } = globalState;

  return (

    <React.Fragment>

      { user_type <= 4 && <ExportView /> }

    </React.Fragment>

  );

}

export default Export;