import React from "react";
import useGlobal from "../../../store";
import ModelsConfigurationsView from "./ModelsConfigurationsPage";

const Models = () => {
  const [globalState] = useGlobal();

  const { user_type } = globalState;

  return (
    <React.Fragment>{user_type <= 3 && <ModelsConfigurationsView />}</React.Fragment>
  );
};

export default Models;
