import React from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import LoadMore from '../load.more';
import './table.scss';
import { Link } from "react-router-dom";

const TableExport = ( props ) => {

  const { 

    className = "",
    template = "",
    columns,
    rows, 
    selected = [],
    total, 
    loadMore, 
    openModal = () => {}, 
    maxCols = false, 
    subtitle = false,
    download = () => {},
    remove = () => {}
  
  } = props;

  const variant = !maxCols ? className : maxCols === 2 ? "list-with-controls" : "basic-list";

  return (

    <div className = {`table-container table-container--${ variant }`}>

      { !maxCols && <Row head content = { columns } template = { template } />}

      <div className="rows">

        {( rows && rows.length > 0 ) 
        
          ? rows.map(( content, key ) => {

            let isSelected = false;

            if ( selected?.length > 0 && content?.id && selected.indexOf( content.id ) !== -1 ) {
              isSelected = true;
            }
            
            return (

              <Row

                template = { template }
                key = { key }
                content = { content }
                selected = { isSelected }
                maxCols = { maxCols || columns.length }
                action = { () => { console.log(`${typeof content} content:`, content); openModal( content ) }}              
                actiondownload = { download }
                actionDelete = { remove }
                subtitle = { subtitle }

              />

            )
          
          })
        
          : maxCols !== 2 ? <p className="nothing tip">Ничего не найдено</p> : null
          
        }

        { rows.length < total && <LoadMore action = { loadMore } /> }

      </div>

    </div>

  );

}

const Row = ( props ) => {

  const { 
    
    template = "",
    content = [], 
    head = false, 
    selected = false, 
    action = false,
    actionDelete = false, 
    maxCols = false,

  } = props;


  return (
  
    <div 
      className = {`row ${ cssIf( selected, "row--selected" ) } ${ cssIf( head, "row-head" ) }`}
      style = {{ gridTemplateColumns: template }}
    >

      { content && Object.keys( content ).map(( col, colName ) => (

        ( !maxCols || colName < maxCols ) &&
      
          <div

            key = { colName }
            style = { content[ col ]?.width 
              ? { width: content[ col ].width }
              : undefined
            }
            onClick = { () => action && action() }
            className = {`col ${ cssIf( head, 'head-col')}`}
          
          >
            
            { col !== "img" 
            
              ? !head 

                ? <div className = "name_subtitle">

                    { content[ col ] !== "r,d"

                        ? content[ col ]

                        : <DeleteAndDownloadButtons

                            download_link = { content.link }
                            remove = { () => actionDelete( content.id ) }

                          />
                    }

                    {/* { subtitle && <div className="name_subtitle__subtitle">{ content.address }</div> } */}
                    
                  </div>

                : <div className = "row-head-col" onClick = { () => { ( head && content[ col ].action ) && content[ col ].action() } }> 

                    <div>{ content[ col ].name }</div>
                    
                    { content[ col ].sort && 
                      
                      <div className = "icon" /> 
                      
                    }
              
                  </div>
              
              : <div 
                  className="photo" 
                  style={{ backgroundImage: `url(${ content[ col ] })` }}
                />
  
            }
            
          </div>

      ))}

    </div>

  );

}

export default TableExport;

const DeleteAndDownloadButtons = ({ download_link, remove }) => (

  <div className="ead_btns">

    <div
      className = "ead_btns__btn ead_btns__btn__delete"
      onClick = { remove }
      title = "удалить"
    />

    <Link
      className = "ead_btns__btn ead_btns__btn__download"
      to={{ pathname: download_link }} target="_blank"
      title = "Скачать"
    />

  </div>

);