import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

export async function getLocationsColors(store) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из get locations colors`;
  }

  const { selected_locations } = store.state;

  let params = {
    token: token,
    locations: selected_locations.toString(),
  };

  try {
    store.setState({ locationsColorsIsLoaded: false });

    const { status, data } = await axios.post(API.stats.colors, params);
    if (status !== 200 || !data) {
      throw data?.text;
    }

    store.setState({
      locationsColorsIsLoaded: true,
      locationsColors: data.data,
    });

    return true;
  } catch (err) {
    console.error(`Не удалось получить данные для третьего графика:`, err);
    return false;
  }
}

export async function getLocationsRating(store) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из locations rating`;
  }

  try {
    store.setState({ locationsRatingIsLoaded: false });

    const { status, data } = await axios.post(API.stats.locations_rating, {
      token: token,
    });
    if (status !== 200 || !data) {
      throw data?.text;
    }

    store.setState({
      locationsRatingIsLoaded: true,
      locationsRating: data.data,
    });

    return true;
  } catch (err) {
    console.error(`Не удалось получить данные для третьего графика:`, err);
    return false;
  }
}

export async function getCountEvents(store) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из add location`;
  }

  const { selected_locations, selected_people, startDate, endDate } = store.state;

  // const _date = ( endDate && startDate !== endDate ) ? `${ startDate }-${ endDate }` : startDate;

  let params = {
    token: token,
    people: selected_people,
    locations: selected_locations,
    // date: _date
  };

  try {
    store.setState({ countEventsIsLoaded: false });

    const { status, data } = await axios.post(API.stats.counter, params);
    if (status !== 200 || !data) {
      throw data?.text;
    }

    store.setState({
      countEventsIsLoaded: true,
      countTodayEvents: data.today,
      countAllEvents: data.all,
    });

    return true;
  } catch (err) {
    console.error(`Не удалось получить данные для третьего графика:`, err);
    return false;
  }
}

export async function getChartOne(store) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из add location`;
  }

  const { selected_locations, startDate, endDate, selected_people } = store.state;

  const _date = endDate && startDate !== endDate ? `${startDate}-${endDate}` : startDate;

  let params = {
    token: token,
    locations: selected_locations.toString(),
    date: _date,
    people: selected_people,
  };

  try {
    store.setState({ chartOneIsLoaded: false });

    const { status, data } = await axios.post(API.stats.chart1, params);
    if (status !== 200 || !data) {
      throw data?.text;
    }

    store.setState({
      chartOneIsLoaded: true,
      chartOne: data.result,
      chartOneTimeline: data.timeline,
    });

    return true;
  } catch (err) {
    console.error(`Не удалось получить данные для третьего графика:`, err);
    return false;
  }
}

export async function getChartTwo(store) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из add location`;
  }

  const { chartTwoRequestID, selected_locations, startDate, endDate, selected_people } =
    store.state;

  const _date = endDate && startDate !== endDate ? `${startDate}-${endDate}` : startDate;

  let params = {
    token: token,
    locations: selected_locations.toString(),
    date: _date,
    people: selected_people,
  };

  try {
    const randomRequestID = Math.random(1, 9999999);

    store.setState({
      chartTwoIsLoaded: false,
      chartTwoRequestID: randomRequestID,
    });

    const { status, data } = await axios.post(API.stats.chart2, params);
    if (status !== 200 || !data) {
      throw data?.text;
    }

    store.setState({
      chartTwoIsLoaded: true,
      randomRequestID: "",
      chartTwo: data.monthStats,
      chartTwoTimeline: data.timeline,
    });

    return true;
  } catch (err) {
    console.error(`Не удалось получить данные для третьего графика:`, err);
    return false;
  }
}

export async function getChartThree(store) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из add location`;
  }

  const { selected_locations, startDate, endDate, startTime, endTime, selected_people } =
    store.state;

  const _date = endDate && startDate !== endDate ? `${startDate}-${endDate}` : startDate;

  let params = {
    token: token,
    locations: selected_locations.toString(),
    date: _date,
    people: selected_people,
    // time: `${ startTime }-${ endTime }`
  };

  try {
    store.setState({ chartThreeIsLoaded: false });

    const { status, data } = await axios.post(API.stats.chart3, params);
    if (status !== 200 || !data) {
      throw data?.text;
    }

    setTimeout(() => {
      const findedHeaders = document.querySelectorAll('[class*="styles_header"]');
      findedHeaders?.length > 0 &&
        findedHeaders.forEach(header => (header.style.display = "none"));
    }, 100);

    store.setState({
      chartThreeIsLoaded: true,
      chartThree: data?.chartData,
      chartThreeTime: {
        events: data?.eventsTime,
        free: data?.freeTime,
      },
    });

    return true;
  } catch (err) {
    console.error(`Не удалось получить данные для третьего графика:`, err);
    return false;
  }
}

export async function getChartFour(store) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из add location`;
  }

  const { chartFourRequestID, selected_locations, startDate, endDate, selected_people } =
    store.state;

  const _date = endDate && startDate !== endDate ? `${startDate}-${endDate}` : startDate;

  let params = {
    token: token,
    locations: selected_locations.toString(),
    date: _date,
    people: selected_people,
  };

  try {
    const randomRequestID = Math.random(1, 9999999);

    store.setState({
      chartFourIsLoaded: false,
      chartFourRequestID: randomRequestID,
    });

    const { status, data } = await axios.post(API.stats.chart4, params);
    if (status !== 200 || !data) {
      throw data?.text;
    }

    store.setState({
      chartFourIsLoaded: true,
      randomRequestID: "",
      chartFour: data.monthStats,
      chartFourTimeline: data.timeline,
    });

    return true;
  } catch (err) {
    console.error(`Не удалось получить данные для третьего графика:`, err);
    return false;
  }
}
