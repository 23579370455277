import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

const FS_TYPES = {
  folder: "f",
  camera: "c",
  events_group: "l",
};

export async function getTree(store, set) {
  const token = cookie.load("token");
  if (!token) {
    store.actions.sign.Out();
    console.error(`Попытка разлогина из get folders`);
  }

  const { status, data } = await axios.post(API.folders.tree, { token: token });

  console.log(`FILESTRUCTURE: status: ${status}, tree:`, data.tree);

  if (status === 200) {
    if (!data.zeroFolderId) {
      console.error(
        `Ошибка: в БД в таблице folders обязательно должна быть запись с parent_id = NULL и name = ALL. Она является главной, самой первой папкой для всех камер и папок.`
      );
    }

    set(data.tree);

    store.setState({
      isLoadingTree: false,
      tree: data.tree,
      treeZeroFolderId: data.zeroFolderId,
    });
  } else {
    console.error(data.text);
  }
}

export async function all(set) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из all folders`);
    return false;
  }

  const { status, data } = await axios.post(API.folders.all, { token: token });

  console.log(`status: ${status}, tree:`, data.tree);

  if (status === 200 && data?.folders) {
    set(data.folders);
  } else {
    console.error(data.text);
  }
}

export async function rename(store, type, id, value) {
  const token = cookie.load("token");
  if (!token) {
    store.actions.sign.Out();
    console.error(`Попытка разлогина из rename`);
  }

  const params = {
    token: token,
    type: FS_TYPES[type],
    id: id,
    name: value,
  };

  const { status, data } = await axios.post(API.folders.rename, params);

  if (status !== 200) {
    console.error(`Не удалось выполнить запрос на переименование`);
    return false;
  }

  console.log(`Успешно переименовано!`);
}

export async function move(store, type, id, new_parent) {
  const token = cookie.load("token");
  if (!token) {
    store.actions.sign.Out();
    console.error(`Попытка разлогина из rename`);
  }

  const params = {
    token: token,
    type: FS_TYPES[type],
    id: id,
    new_parent: new_parent,
  };

  const { status, data } = await axios.post(API.folders.move, params);

  if (status !== 200) {
    console.error(`Не удалось выполнить запрос на переименование`);
    return false;
  }

  console.log(`Позиция успешно сохранена`);
}

export async function add(store, id) {
  const token = cookie.load("token");
  if (!token) {
    store.actions.sign.Out();
    console.error(`Попытка разлогина из rename`);
  }

  const params = {
    token: token,
    parent: store.state.treeZeroFolderId,
    folder: id,
  };

  const { status, data } = await axios.post(API.folders.add, params);

  if (status !== 200) {
    console.error(`Не удалось выполнить запрос на добавление папки`);
    return false;
  }

  console.log(`Папка успешно создана`);
}

export async function remove(store, id) {
  const token = cookie.load("token");
  if (!token) {
    store.actions.sign.Out();
    console.error(`Попытка разлогина из rename`);
  }

  const params = {
    token: token,
    folder: id,
  };

  const { status, data } = await axios.post(API.folders.remove, params);

  if (status !== 200) {
    console.error(`Не удалось выполнить запрос на удаление папки`);
    return false;
  }

  console.log(`Папка успешно удалена`);
}
