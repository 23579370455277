import React, { useState } from 'react';
import cssIf from '../../../../scripts/helpers/class.add.if';
import { ReactComponent as FullScreenBtn } from "./icons/fullscreen.svg";

import "./section.scss";

const Section = ({ 
  
  id = "", 
  scrollable_id = "", 
  title,
  scrollable = false,
  fullscreen = false,
  tip = false,
  minWidth = "unset", 
  minHeight = "unset", 
  maxHeight = "unset",
  className = "",
  customAction = () => {},
  customActionIcon = false,
  children 

}) => {

  // calculate text offset function for title
  const calculateTextOffset = ( title ) => {

    const titleLength = title.length;
    var symbolOffset;  // correct offset for 1 symbol with length 20 symbols

    if ( titleLength < 10) symbolOffset = 14.75
    else if ( titleLength >= 18) symbolOffset = 11.35
    else if ( titleLength >= 23) symbolOffset = 11.45
    else if ( titleLength >= 30) symbolOffset = 10.55

    return titleLength * symbolOffset;

  }

  const [ fullScreenMode, setFullScreenMode ] = useState( false );

  return (

    <section

      id = { id }
      className = {`panel__section ${ className } ${ cssIf( scrollable, "panel__section--scrollable" ) } ${ cssIf( fullScreenMode, "fullscreen" ) }`}
      style = {{ 

        minWidth: minWidth,
        minHeight: minHeight,
        maxHeight: maxHeight

      }}
      title = { tip ? tip : null }
      onClick = { () => ( fullscreen && fullScreenMode ) && setFullScreenMode( false ) }

    >

      <div className = "title">{ title }</div>

      { !scrollable ? children : <Scrollable id = { scrollable_id }>{ children }</Scrollable> }

      { fullscreen && 
      
        <FullScreenBtn 
          
          className = "fs_btn" 
          onClick = { () => setFullScreenMode( true ) } 
          title = "Развернуть на весь экран"
          
        /> 
        
      }

      { (customAction && customActionIcon) &&
        <div className = "near_button" onClick={ customAction } style={
          {
            left: `${ calculateTextOffset(title) }px`
          }
        }>
          { customActionIcon }
        </div>
      }
      
    </section>

  );

}

const Scrollable = ({ id = "", children }) => ( <div id = { id } className = "scrollable">{ children }</div> );

export default Section;