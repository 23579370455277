function DateStringToDateTime(string) {
  const s = string.split("-");

  const d = s[0];
  const m = s[1];
  const y = s[2];

  return new Date("20" + y + "-" + m + "-" + d);
}

export default DateStringToDateTime;
