import React, { useEffect, useState } from "react";
import "./search.css";
import useGlobal from "../../../store";

const Search = ({ placeholder = "" }) => {
  const [globalState, globalActions] = useGlobal();

  const { changeState } = globalActions;

  const { PeopleList, PeopleListLoaded } = globalState;
  const [SearchValue, setSearchValue] = useState("");

  useEffect(
    (tempPL = []) => {
      if (PeopleListLoaded) {
        for (const item of PeopleList) {
          if (item.name.toLowerCase().includes(SearchValue.toLowerCase())) {
            tempPL.push(item);
          }
        }
        changeState("PeopleListSearched", tempPL);
      }
    },
    [PeopleList, PeopleListLoaded, SearchValue]
  );

  return (
    <div className="search-box">
      <input
        placeholder={placeholder}
        type="text"
        className="search-box__input"
        value={SearchValue}
        onChange={e => setSearchValue(e.target.value)}
        autoComplete={"off"}
      />
    </div>
  );
};

export default Search;
