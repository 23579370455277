import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import useGlobal from '../../../store';
import Textfield from '../../components/textfield';
import Button from '../../components/button';
import "./sign.scss";

const SignIn = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { sign } = globalActions;

  const navigate = useNavigate(); // Раньше был useHistory
  const redirect = () => navigate('/');

  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ error, setError ] = useState("");

  const [ title, setTitle ] = useState("Войдите в систему");
  const [ objects, setObjects ] = useState([]);

  const form = {

    show: () => document.getElementById('signform')?.classList.add('show'),
    hide: () => document.getElementById('signform')?.classList.remove('show')

  }

  const token = cookie.load('token');

  useEffect(() => { 
    
    setTimeout(() => { form.show(); }, 50 ); 
  
    if ( token ) { 
      
      (async () => {

        const tokenIsActual = await sign.Check( token );
        tokenIsActual && redirect();

      })();
    
    }

  }, []);


  useEffect(() => { error !== "" && form.show(); }, [ error ]);

  useEffect(() => { 
    
    if ( objects.length > 0 ) {
      
      setTitle("Выберите объект:");
      form.show();
    
    } 
  
  }, [ objects ]);

  return (
   
    <React.Fragment>

      <div className="signin">

        <div className="signin__frame" id="signform">

          <div className="signin__frame__inner signin__title">

            { title }

          </div>

          { objects.length === 0 

            ? <React.Fragment>

                <div className="signin__frame__inner">

                  <Textfield

                    title = "E-mail:"
                    value = { email }
                    set = { setEmail }

                  />

                  <Textfield

                    title = "Пароль:"
                    type = "password"
                    value = { password }
                    set = { setPassword }

                  />

                  { error !== "" && <div className="error">{ error }</div> }

                </div>

                <div className="signin__frame__inner">

                  <Button 

                    text = "войти"
                    action = { () => { form.hide(); sign.In( email, password, redirect, setObjects, setError ) }}
                    disabled = { email === "" || password === "" }

                  />

                </div>

              </React.Fragment>
              
            : objects.map(( obj, key ) => (

                <ObjectElement

                  key = { key }
                  name = { obj.name }
                  address = {`г.${ obj.city }, ${ obj.address }`}
                  action = { () => { form.hide(); sign.toObject( obj.id, redirect ); }}

                />

              ))

            
          }        

        </div>
      
      </div>

    </React.Fragment>

  );

}

const ObjectElement = ({ name, address, action }) => (

  <div 
    className = "signin__frame__inner signin__frame__object"
    onClick = { action }
  >

    <div className = "signin__frame__object__name">{ name }</div>
    <div className = "signin__frame__object__address">{ address }</div>

  </div>

);

export default SignIn;