function Dmy2Ymd(string) {
  const s = string.split("-");

  const d = s[0];
  const m = s[1];
  const y = s[2];

  return d + "." + m + ".20" + y;
}

export default Dmy2Ymd;
