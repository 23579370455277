import React, { useEffect, useState, useRef, Fragment } from 'react';
import cookie from 'react-cookies';
import useGlobal from '../../../store';

const WebSockets = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { selected_locations } = globalState;

  const [ wsConnected, setWsConnected ] = useState( false );
 
  const SOCKET_ACTIONS = {

    1: globalActions.events.realtimeCompare // пришло событие, интегрировать его в массив событий куда положено

  }

  const token = cookie.load('token');

  const wsTryReconnect = useRef( null );
  const ws = useRef( null );

  // useEffect(() => { TODO & FIXME
  //
  //   if ( token && token !== "" ) {
  //
  //     console.log(`Попытка подключения к WebSocket, token=${token}`);
  //
  //     // const wsConnect = () => ws.current = new WebSocket("wss://ws.any.link:3000");
  //     const wsConnect = () => ws.current = new WebSocket("ws://localhost:4323");
  //
  //     wsConnect();
  //
  //     ws.current.onopen = () => {
  //
  //       console.log("ws opened");
  //       setWsConnected( true );
  //       wsTryReconnect.current !== null && clearInterval( wsTryReconnect.current );
  //
  //       ws.current.send(
  //
  //         JSON.stringify({
  //
  //           cmd: 'setToken',
  //           data: token,
  //           d: new Date().toLocaleTimeString(),
  //           device: window.navigator.userAgent.toLowerCase()
  //
  //         })
  //
  //       );
  //
  //     }
  //
  //     ws.current.onclose = () => {
  //
  //       console.log("Соединение с WebSocket-сервером потеряно");
  //       setWsConnected( false );
  //
  //       wsTryReconnect.current = setInterval(() => {
  //
  //         console.log('Попытка переподключиться к WebSocket-серверу...')
  //         wsConnect();
  //
  //       }, 5000)
  //
  //     }
  //
  //     ws.current.onerror = error => { console.log('WebSocket error:', error); }
  //
  //     ws.current.onmessage = e => {
  //
  //       console.log(`ws.onmessage`, e);
  //
  //       var json = null,
  //           text = null;
  //
  //       try {
  //
  //         json = JSON.parse(e.data);
  //
  //       } catch( ex ) {
  //
  //         text = e.data;
  //
  //       }
  //
  //       if ( json !== null ){
  //
  //         console.log(`WEBSOCKET TAKE JSON: ${ typeof json }`, json);
  //
  //         SOCKET_ACTIONS?.[ json.whatis ]( json );
  //
  //       } else {
  //
  //         console.log('TEXT: ',text)
  //
  //       }
  //
  //     }
  //
  //     return () => {
  //
  //       ws.current.close();
  //
  //     };
  //
  //   }
  //
  // }, [ token ]);

  // useEffect(() => {
  //
  //   console.log('WebSocket useEffect: changed selected locations');
  //
  //   wsConnected && ws.current.send(
  //
  //     JSON.stringify({
  //
  //       cmd: 'setLocations',
  //       data: selected_locations
  //
  //     })
  //
  //   );
  //
  //
  // }, [ selected_locations.length ]);

  return (

    <Fragment />

  );

}

export default WebSockets;