import React, { useState, useEffect } from 'react';
import useGlobal from '../../../store';
import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';
import Section from '../../components/containers/section';
import Event from '../../components/event';
import Checkbox from '../../components/checkbox';
import LoadMore from '../../components/load.more';
import cssIf from '../../../scripts/helpers/class.add.if';
import LoadingSpinner from '../../components/loader';
import Textfield from '../../components/textfield';
import "./styles/events.scss";
import TimeFieldLongAutocomplete from "../../components/datepicker/timefieldlongautocompl";
import convertDuration from "../../../scripts/helpers/PluralizeNumToTime";

const FilterPopup = ({ isOpened }) => {

  const [ globalState, globalActions ] = useGlobal();
  const {

    eventsMode,

    allowCarPlateFilter,
    object_have_faces,
    filter_important,
    filter_second,
    filter_approved,
    filter_notapproved,
    filter_actual,
    filter_removed,
    filter_duration_min,
    filter_duration_max,
    filter_gosnumber,
    filter_person_name
    
  } = globalState;

  const { changeState, changeStates } = globalActions;

  const [ minDur, setMinDur ] = useState( filter_duration_min || "" );
  const [ maxDur, setMaxDur ] = useState( filter_duration_max || "" );
  const [ minDurAutocomplCount, setMinDurAutocomplCount ] = useState( 0 );
  const [ maxDurAutocomplCount, setMaxDurAutocomplCount ] = useState( 0 );
  const [ gosnumber, setGosnumber ] = useState( filter_gosnumber || "" );
  const [ PersonLookingName, setPersonLookingName ] = useState(filter_person_name || "")

  useEffect(() => {

    ( minDur.length === 0 || ( minDur.length > 7 && minDur.indexOf(':') !== -1 ) ) && changeState( "filter_duration_min", minDur );
    ( maxDur.length === 0 || ( maxDur.length > 7 && maxDur.indexOf(':') !== -1 ) ) && changeState( "filter_duration_max", maxDur );
    gosnumber.length > -1 && changeState( "filter_gosnumber", gosnumber );
    PersonLookingName.length > -1 && changeState( "filter_person_name", PersonLookingName)
    
  }, [minDur, maxDur, gosnumber, PersonLookingName]);
  useEffect( () => {

    TimeFieldLongAutocomplete(

      minDur,
      minDurAutocomplCount,
      setMinDur,
      setMinDurAutocomplCount

    )

  }, [ minDur ]);

  useEffect( () => {

    TimeFieldLongAutocomplete(

      maxDur,
      maxDurAutocomplCount,
      setMaxDur,
      setMaxDurAutocomplCount

    )

  }, [ maxDur ]);
  
  return (

    <div className={`filter_modal ${ cssIf( isOpened, "opened" )}`}>

      <Checkbox

        text = "Сначала старые"
        value = { eventsMode === "increase" }
        set = {( bool ) => bool && changeStates({ eventsMode: "increase", eventsOffset: 0 }) }

      />

      <Checkbox

        text = "Сначала новые"
        value = { eventsMode === "decrease" }
        set = {( bool ) => bool && changeStates({ eventsMode: "decrease", eventsOffset: 0 }) }

      />

      <hr />

      <Checkbox

        text = "Важные"
        value = { filter_important }
        set = {( bool ) => changeState( "filter_important", bool ) }

      />

      <Checkbox

        text = "Второстепенные"
        value = { filter_second }
        set = {( bool ) => changeState( "filter_second", bool ) }

      />

      <hr />

      <Checkbox

        text = "Подтверждённые"
        value = { filter_approved }
        set = {( bool ) => changeState( "filter_approved", bool ) }

      />

      <Checkbox

        text = "Не подтверждённые"
        value = { filter_notapproved }
        set = {( bool ) => changeState( "filter_notapproved", bool ) }

      />

      <hr />

      <Checkbox

        text = "Актуальные"
        value = { filter_actual }
        set = {( bool ) => changeState( "filter_actual", bool ) }

      />

      <Checkbox

        text = "Удалённые"
        value = { filter_removed }
        set = {( bool ) => changeState( "filter_removed", bool ) }

      />

      <hr />

      <p className="center">Длительность:</p>

      <div className="flex filter_modal__twoinputs">

        <Textfield

          title = "От:"
          value = { minDur }
          set = { setMinDur }
          placeholder = "HH:MM:SS"

        />

        <Textfield

          title = "До:"
          value = { maxDur }
          set = { setMaxDur }
          placeholder = "HH:MM:SS"

        />

      </div>
      {allowCarPlateFilter &&
        <div>
      <p className="center">Гос.Номер:</p>

      <Textfield

          value = { gosnumber }
          set = { setGosnumber }
          placeholder = "M777MH799"

      />
        </div>
      }

      {object_have_faces &&
        <div>
          <p className="center">Поиск по имени</p>

          <Textfield

            value = { PersonLookingName }
            set = { setPersonLookingName }
            placeholder = "Иван Иванов"

          />
        </div>
      }

    </div>

  );

}

const Events = ({ gotoPlayer }) => {

  const [ globalState, globalActions ] = useGlobal();

  const { 

    popupRandom,
    wsRandom,

    selected_locations,
    startDate,
    endDate,
    
    startTime,
    endTime,

    permissions,

    eventsList,
    eventsNowList,
    eventsCounter,
    eventsOffset,
    eventsMode,

    filter_important,
    filter_second,
    filter_approved,
    filter_notapproved,
    filter_actual,
    filter_removed,
    filter_duration_min,
    filter_duration_max,
    filter_gosnumber,
    filter_person_name,

    eventsListLoaded,
    eventsListMoreLoading,

    se_id

  } = globalState;

  const { changeState } = globalActions;

  const [ Dummy, reloadDummy ] = useReloadDummy();

  const [ showFilterPopup, setShowFilterPopup ] = useState( false );
  const [ showInnerFilterPopup, setShowInnerFilterPopup ] = useState( false );
  const [ previousLocationsLength, setPreviousLocationsLength ] = useState( 0 );

  const infoClick = () => alert('info');

  useEffect(() => { //? Мониторим изменение любых фильтров событий

    ( async () => {

      const list = selected_locations.length > 0 && await globalActions.events.all();
      setPreviousLocationsLength( selected_locations.length );
      list && reloadDummy();

    })();

  }, [

    selected_locations.length,
    eventsMode,
    startDate,
    endDate,
    startTime,
    endTime,
    filter_important,
    filter_second,
    filter_approved,
    filter_notapproved,
    filter_actual,
    filter_removed,
    filter_duration_min,
    filter_duration_max,
    filter_gosnumber,
    filter_person_name

  ]);

  useEffect(() => {

    //TODO генерировать на сервере доделать
    // if ( wsRandom !== "" ) {

    //   eventsList.length > 0 ? formatEventsList( eventsList, setEvents ) : setEvents([]);
    //   eventsNowList.length > 0 ? formatEventsList( eventsNowList, setEventsNow ) : setEventsNow([]);

    //   reloadDummy();
    
    // }

  }, [ wsRandom ]);

  useEffect(() => { reloadDummy() }, [ se_id ]);
  useEffect(() => { changeState( "eventsOffset", 0 ) }, [ endDate ]);

  async function loadMoreEvents() {

    const request = selected_locations.length > 0 && await globalActions.events.all( true );    
    request && reloadDummy();

  }

  function toggleFilterPopup() {

    if ( !showFilterPopup ) {

      setShowFilterPopup( true );
      setTimeout(() => { setShowInnerFilterPopup( true ) }, 50 );

    } else {

      setShowInnerFilterPopup( false );
      setTimeout(() => { setShowFilterPopup( false ) }, 300 );

    }

  }

  return (

    <Section 

      className='events'
      scrollable 
      scrollable_id = "eventslist" 
      title = {`События${ cssIf( !eventsListLoaded > 0, " загружаются...", cssIf( eventsCounter > 0 || eventsCounter === eventsNowList.length || (eventsNowList.length > 0 && eventsCounter === 0), ` (${ eventsList.length + eventsNowList.length }/${ eventsCounter + eventsNowList.length })` ) ) }`}

    >

      <div 
        className = "filter_btn" 
        onClick = { toggleFilterPopup }
      >

        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.62932 18.0625H6.97188V9.45404L1 2.34467V1H17.2094V2.33699L11.5219 9.44636V15.1699L8.62932 18.0625ZM8.10938 16.925H8.15818L10.3844 14.6988V9.04739L15.9123 2.1375H2.31168L8.10938 9.03971V16.925Z" fill="#FFA000" stroke="#FFA000" strokeWidth="0.5"/>
        </svg>
        
      </div>

      { showFilterPopup && <FilterPopup isOpened = { showInnerFilterPopup } /> }

      <Dummy />
      
      {/* <Face

        key = { 1 }
        index = { 1 }
        name = { "Новый посетитель" }
        old = { "" }
        sex = { "" }
        category = { "" }
        time = {{ start: '23:00:54', end: "" }}
        date = { '08.09.2022' }
        photo = { "https://habrastorage.org/getpro/habr/upload_files/ed2/909/566/ed2909566351df2e6f862b309266a4ec.jpg" }

      /> */}

      { !eventsListLoaded 
      
        ? <LoadingSpinner />
        
        : selected_locations.length > 0
      
          ? <React.Fragment>

              { eventsNowList.length > 0
              
                ? eventsNowList.map(( item, i ) => (
                  
                  <Event

                    key = { i }
                    i = { i + 1 }
                    id = { item.id }
                    se_id = { se_id }
                    isActive = { se_id === item.id }
                    date = { item.date }
                    timeline = { item.timeline }
                    timeline_end = { false }
                    timeStart = { item.timeStart }
                    timeEnd = { item.timeEndFormatted }
                    timeDiff = { convertDuration(item.timeDiff) }
                    black_list = { item.black_list }
                    white_list = { item.white_list }
                    text = { item.text }
                    path = { item.path }
                    vehicle = { item?.vehicle }
                    person = { item?.person }
                    label = { item.label }
                    controls = { permissions?.can_change_event_status }
                    isOneLocationEvent = { item?.location_id === item?.location_end_id }
                    isConfirmed = { item.is_confirmed }
                    isImportant = { item.is_important }
                    isRemoved = { item.is_removed }
                    showRemoved = { filter_removed }
                    onInfoClick = { infoClick }
                    onCheckClick = { () => { globalActions.events.modify( item.id, 'confirmed', !item.is_confirmed ) } }
                    onWarningClick = { () => { globalActions.events.modify( item.id, 'important', !item.is_important ) } }
                    onCancelClick = { () => { globalActions.events.modify( item.id, 'removed', !item.is_removed ) } }
                    onPlayFromStart = { () => {

                      if ( !item?.video || item.video === "" ) { alert("Для данного события видео ещё обрабатывается, и будет доступно позже"); return; }

                      console.log(`Перемотка на начало события: ${item.timeline}`)

                      globalActions.events.select( 

                        item.id, 
                        item.video, 
                        item.location_name, 
                        item.timeline
                        
                      );

                      gotoPlayer && gotoPlayer();
                                            
                    }}
                    
                    onPlayFromEnd = { () => { 

                      if ( !item?.video || item.video === "" ) { alert("Для данного события видео ещё обрабатывается, и будет доступно позже"); return; }

                      let timelineEnd = item.timeline_end;
                      if ( item.timeline === item.timeline_end && item?.video_time_end ) { timelineEnd = item.video_time_end }

                      console.log(`Перемотка на конец события: ${ timelineEnd }`)

                      globalActions.events.select( 

                        item.id, 
                        item.video_end, 
                        item.location_name, 
                        timelineEnd
                        
                      );

                      gotoPlayer && gotoPlayer();

                      }}

                  />

                ))
                
                : <div className="tip">Незавершённых событий нет</div>             

              }
          
              { eventsList.length > 0
              
                ? eventsList.map(( item, i ) => (
                  
                  <Event

                    key = { i }
                    i = { i + 1 + eventsNowList.length }
                    id = { item.id }
                    se_id = { se_id }
                    isActive = { se_id === item.id }
                    date = { item.date }
                    timeline = { item.timeline }
                    timeline_end = { item.timeline_end }
                    timeStart = { item.timeStart}
                    timeEnd = { item.timeEndFormatted}
                    timeDiff = { convertDuration(item.timeDiff) }
                    black_list = { item.black_list }
                    white_list = { item.white_list }
                    text = { item.text }
                    path = { item.path }
                    vehicle = { item?.vehicle }
                    person = { item?.person }
                    label = { item.label }
                    controls = { permissions?.can_change_event_status }
                    isConfirmed = { item.is_confirmed }
                    isImportant = { item.is_important }
                    isRemoved = { item.is_removed }
                    showRemoved = { filter_removed }
                    isOneLocationEvent = { item?.location_id === item?.location_end_id }
                    onInfoClick = { infoClick }
                    onCheckClick = { () => { globalActions.events.modify( item.id, 'confirmed', !item.is_confirmed ) } }
                    onWarningClick = { () => { globalActions.events.modify( item.id, 'important', !item.is_important ) } }
                    onCancelClick = { () => { globalActions.events.modify( item.id, 'removed', !item.is_removed ) } }
                    onPlayFromStart = { () => { 

                      if ( !item?.video || item.video === "" ) { alert("Для данного события видео ещё обрабатывается, и будет доступно позже"); return; }
                      
                      console.log(`Перемотка на начало события: ${item.timeline}`)

                      globalActions.events.select( item.id, item.video, item.location_name, item.timeline );
                      gotoPlayer && gotoPlayer();
                      
                    }}
                    
                    onPlayFromEnd = { () => { 

                      if ( !item?.video || item.video === "" ) { alert("Для данного события видео ещё обрабатывается, и будет доступно позже"); return; }

                      let timelineEnd = item.timeline_end;
                      if ( item.timeline === item.timeline_end && item?.video_time_end ) { timelineEnd = item.video_time_end }

                      console.log(`Перемотка на конец события: ${item.timeline_end}`)

                      globalActions.events.select( 

                        item.id, 
                        item.video_end,
                        item.location_name,
                        timelineEnd
                        
                      );

                      gotoPlayer && gotoPlayer();
                                            
                    }}

                  />

                ))

                : <div className="tip">Завершённых событий нет</div>

              }
            
            </React.Fragment>

          : <div className="tip">Выберите локацию в камере</div>
              
      }

      {( 
        
        eventsListLoaded &&
        ( eventsList.length < eventsCounter )
        
      ) &&

        <LoadMore stateFromProps isLoading = { eventsListMoreLoading } action = { loadMoreEvents } /> 
        
      }
            
    </Section>

  )

} 

export default Events;