import "./face.simillar.scss";

const SimillarFace = ({ 
  
  name = "",
  category = "",
  photo = ""

}) => (
  
  <div class="face__simillar">

    <div className="face__simillar__name">{ name }</div>

    <div 
      className="face__simillar__photo" 
      style = {{ backgroundImage: `url(${ photo })`}} 
    />

    <div className="face__simillar__category">{ category }</div>

  </div>

);

export default SimillarFace;