import React from "react";
import useGlobal from "../../../store";
import TemplateCreatorPage from "./templateCreatorPage";

const TemplateCreator = () => {
  const [globalState] = useGlobal();

  const { user_type } = globalState;

  return <React.Fragment>{user_type === 0 && <TemplateCreatorPage />}</React.Fragment>;
};

export default TemplateCreator;
