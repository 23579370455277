function groupMarkupsByClass(markups) {
  const markupsGroupedByClass = {};

  markups.forEach(markup => {
    const className = markup.className;
    if (markupsGroupedByClass[className]) {
      markupsGroupedByClass[className].push(markup);
    } else {
      markupsGroupedByClass[className] = [markup];
    }
  });

  return Object.values(markupsGroupedByClass);
}

export function convertMarkupsToData(markups, imageWidth, imageHeight, markupType) {
  const filteredMarkups = markups.filter(markup => markup.type === markupType);
  const groupedMarkups = groupMarkupsByClass(filteredMarkups);

  const counters = groupedMarkups.map(group => {
    return group.map(markup => {
      const startPointIntersection = [markup.startPoint.x, markup.startPoint.y];
      const endPointIntersection = [markup.endPoint.x, markup.endPoint.y];
      const startPointDirection = [
        markup?.arrow?.arrowStartPoint.x,
        markup?.arrow?.arrowStartPoint.y,
      ];
      const endPointDirection = [
        markup?.arrow?.arrowEndPoint.x,
        markup?.arrow?.arrowEndPoint.y,
      ];
      const points = markup.points
        ? markup.points.map(point => [point.x, point.y])
        : null;

      return markupType === "line"
        ? [
            [startPointIntersection, endPointIntersection],
            [startPointDirection, endPointDirection],
          ]
        : markupType === "rect"
        ? [[startPointIntersection, endPointIntersection]]
        : [points];
    });
  });

  return [counters, [imageWidth, imageHeight]];
}

export const dataPreparation = data => {
  let lines = [];
  let rectangles = [];
  let polygons = [];

  for (let i = 0; i < data.length; i++) {
    if (data[i].type === "line") {
      let line = {
        class: data[i].class,
        startPoint: data[i].startPoint,
        endPoint: data[i].endPoint,
        direction: {
          startPoint: data[i].arrow.arrowStartPoint,
          endPoint: data[i].arrow.arrowEndPoint,
        },
      };
      lines.push(line);
    } else if (data[i].type === "rect") {
      let rect = {
        class: data[i].class,
        startPoint: data[i].startPoint,
        endPoint: data[i].endPoint,
      };
      rectangles.push(rect);
    } else if (data[i].type === "polygon") {
      let polygon = {
        class: data[i].class,
        points: data[i].points,
      };
      polygons.push(polygon);
    }
  }
  return { lines, rectangles, polygons };
};

export function toJSON(data, image) {
  const { lines, rectangles, polygons } = dataPreparation(data);

  const json = {
    image: image?.src ? image.src : "",
    size: {
      width: image?.originWidth ? image.originWidth : 0,
      height: image?.originHeight ? image?.originHeight : 0,
    },
    lines,
    rectangles,
    polygons,
  };

  return JSON.stringify(json, null, 2);
}

export function toXML(data, image) {
  const { lines, rectangles, polygons } = dataPreparation(data);

  const linesXml = lines
    .map(line => {
      return `<line>
        <startPoint>
          <x>${line.startPoint.x}</x>
          <y>${line.startPoint.y}</y>
        </startPoint>
        <endPoint>
          <x>${line.endPoint.x}</x>
          <y>${line.endPoint.y}</y>
        </endPoint>
        <directionLine>
          <startPoint>
            <x>${line.direction.startPoint.x}</x>
            <y>${line.direction.startPoint.y}</y>
          </startPoint>
          <endPoint>
            <x>${line.direction.endPoint.x}</x>
            <y>${line.direction.endPoint.y}</y>
          </endPoint>
        </directionLine>
      </line>`;
    })
    .join("");

  const rectanglesXml = rectangles
    .map(rect => {
      return `<rect>
        <startPoint>
        <x>${rect.startPoint.x}</x>
        <y>${rect.startPoint.y}</y>
        </startPoint>
        <endPoint>
        <x>${rect.endPoint.x}</x>
        <y>${rect.endPoint.y}</y>
        </endPoint>
    </rect>
    `;
    })
    .join("");

  const polygonsXml = polygons
    .map(polygon => {
      const pointsXml = polygon.points
        .map(point => {
          return `<point>
          <x>${point.x}</x>
          <y>${point.y}</y>
        </point>`;
        })
        .join("");

      return `<polygon>
      ${pointsXml}
    </polygon>`;
    })
    .join("");

  const imageSrc = image?.src ? image.src : "";
  const imageWidth = image?.originWidth ? image.originWidth : 0;
  const imageHeight = image?.originHeight ? image.originHeight : 0;

  return `<data>
  <image>${imageSrc}</image>
  <size>
    <width>${imageWidth}</width>
    <height>${imageHeight}</height>
  </size>
  <lines>
    ${linesXml}
  </lines>
  <rectangles>
    ${rectanglesXml}
  </rectangles>
  <polygons>
    ${polygonsXml}
  </polygons>
</data>`;
}
