import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

export async function all(store, loadMore = false) {
  const {
    eventsOffset,
    eventsMode,

    selected_locations,
    startDate,
    endDate,
    startTime,
    endTime,

    filter_important,
    filter_second,
    filter_approved,
    filter_notapproved,
    filter_actual,
    filter_removed,
    filter_duration_min,
    filter_duration_max,
    filter_gosnumber,
    filter_person_name,
  } = store.state;

  if (endDate === null || endDate === "") {
    return false;
  }

  const _date = endDate && startDate !== endDate ? `${startDate}-${endDate}` : startDate;

  const token = cookie.load("token");
  if (!token) {
    store.actions.sign.Out();
    console.error(`Попытка разлогина из events.all:`);
  }

  if (
    startTime.length < 5 ||
    startTime.indexOf(":") === -1 ||
    endTime.length < 5 ||
    endTime.indexOf(":") === -1
  ) {
    return false;
  }

  let params = {
    token: token,
    mode: eventsMode,
    offset: eventsOffset,
    locations: selected_locations.toString(),
    date: _date,
    time: `${startTime}-${endTime}`,
    filter_confirmed: filter_approved,
    filter_notconfirmed: filter_notapproved,
    filter_important: filter_important,
    filter_notimportant: filter_second,
    filter_actual: filter_actual,
    filter_removed: filter_removed,
    filter_duration_min: filter_duration_min,
    filter_duration_max: filter_duration_max,
    filter_gosnumber: filter_gosnumber,
    filter_person_name: filter_person_name,
  };

  if (!loadMore) {
    params.offset = 0;

    await store.setState({
      eventsListLoaded: false,
      eventsListMoreLoading: false,
      eventsOffset: 0,
    });
  } else {
    store.setState({ eventsListMoreLoading: true });
    params.offset = eventsOffset + 20;
  }

  const { data, status } = await axios.post(API.events.all, params);

  if (status === 200 && data.success) {
    const random = Math.random(1, 9999);

    let newState = {
      eventsListLoaded: true,
      eventsCounter: data.eventsCounter,
      popupRandom: random,
    };

    if (!loadMore) {
      newState.eventsList = data.events;
      newState.eventsNowList = data.events_now;
    } else {
      newState.eventsListMoreLoading = false;

      newState.eventsList = [...store.state.eventsList, ...data.events];

      newState.eventsNowList = [...store.state.eventsNowList, ...data.events_now];

      newState.eventsOffset = params.offset;
    }

    await store.setState(newState);

    return newState;
  } else {
    console.error(
      `Ошибка при получении событий:`,
      data?.error ? data.error : "сервер недоступен"
    );

    store.actions.sign.Out();
  }
}

export async function select(store, event_id, url, source_name, time) {
  ////console.log(`called select(${ event_id}, ${ url }, ${ source_name }, ${ time } )`);
  store.setState({
    se_id: event_id,
    se_location_name: source_name,
    video_start: time,
    video: url,
  });
}

export async function modify(store, event, param, value) {
  const token = cookie.load("token");
  if (!token) {
    store.actions.sign.Out();
  }

  const params = {
    token: token,
    event: event,
    param: param,
    value: value,
  };

  const { status, data } = await axios.post(API.events.modify, params);

  console.log(`modify(${event},${param},${value})`, data);

  if (status !== 200) {
    store.actions.sign.Out();
  }
}

export async function realtimeCompare(store, ws_event) {
  try {
    const { eventsNowList, eventsList, eventsCounter, eventsMode, eventsOffset } =
      store.state;

    let newState = {};

    if (!ws_event?.id) {
      if (!ws_event?.counterIDs) {
        alert("ID категорий-счётчиков не переданы");
        return;
      }

      if (ws_event.counterIDs.indexOf(+ws_event.category_id) !== -1) {
        console.log(`Пришло цельное событие: [${ws_event.event_id}]`);

        const eventCounter = {
          ...ws_event,
          id: ws_event.event_id,
          timeDiff: "счётчик",
          timeEnd: "",
        };

        if (eventsMode === "decrease") {
          newState.eventsList = [eventCounter, ...eventsList];
        } else {
          newState.eventsList = [...eventsList, eventCounter];
        }

        newState.eventsCounter = eventsCounter + 1;
        newState.eventsOffset = eventsOffset + 1;
      } else {
        console.log(`Пришло начало события: [${ws_event.event_id}]`);

        newState.eventsNowList = [
          //// is event_now

          {
            ...ws_event,
            id: ws_event.event_id,
          },
          ...store.state.eventsNowList,
        ];
      }
    } else {
      const eventStart = eventsNowList.find(el => el.id === ws_event.id);
      if (!eventStart) {
        console.error(
          `Начало события не найдено для события ${typeof ws_event}`,
          ws_event
        );
      }

      newState.eventsNowList = [];
      let comparedObject = {};

      for (let el of eventsNowList) {
        let object = el;

        if (el.id === ws_event.id) {
          console.log(
            `Найдено начало события [${el.id}], пришло завершение: [${ws_event.id}]`
          );

          object.category_end = ws_event.category_end;
          object.timeEnd = ws_event.time;
          object.video_time_end = ws_event.video_time;
          object.duration = ws_event.duration;

          object.timeDiff = ws_event.duration;
          object.timeEndFormatted = ws_event.time;

          comparedObject = object;
        } else {
          newState.newEventsNowList.push(object);
        }
      }

      if (eventsMode === "decrease") {
        newState.eventsList = [comparedObject, ...store.state.eventsList];
      } else {
        newState.eventsList = [...store.state.eventsList, comparedObject];
      }

      newState.eventsCounter = store.state.eventsCounter + 1;
      newState.eventsOffset = eventsOffset + 1;
    }

    //// console.log(`----- newState -----`, newState );
    newState.wsRandom = Math.random(1, 9999);
    store.setState(newState);
  } catch (err) {
    console.error(`events.realtimeCompare error:`, err);
  }
}
