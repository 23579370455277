import React, { useState, useRef } from 'react';
import cssIf from "../../../scripts/helpers/class.add.if";
import useGlobal from '../../../store';
import Button from '../button';
import Textfield from '../textfield';
import { ReactComponent as Arrow } from "./arrow.svg";
import { ReactComponent as Exit } from "./exit.svg";
import "./user.menu.scss";

const UserMenu = ({ name }) => {

  const [ showMenu, setShowMenu ] = useState( false );
  
  return (
  
    <div className={`user_menu ${ cssIf( showMenu, 'user_menu--opened' )} relative`}>

      <div className = "flex items-center" onClick = {() => setShowMenu( !showMenu )}>

        <div className = "user_menu__name font-semibold">{ name }</div>
        <div className = "user_menu__photo" />
        <Arrow />

      </div>
      
      <Menu isOpened = { showMenu } />

    </div>

  );

}

const Menu = ({ isOpened }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { balance, monthly, card } = globalState;
 
  const { sign, wallet } = globalActions;

  const [ showAmountField, setShowAmountField ] = useState( false );
  const [ amount, setAmount ] = useState("");
  const amountFieldRef = useRef( null );

  function handleShowAmountField() {

    setShowAmountField( true );
    setTimeout(() => { amountFieldRef.current.focus() }, 150 );

  }
  
  return (

    // <div className = {`filter_modal filter_modal-usermenu ${ cssIf( 1 === 1 , "opened" )}`}>
    <div className = {`filter_modal filter_modal-usermenu ${ cssIf( isOpened, "opened" )}`}>

      <div className = "filter_modal__section">

        <div className = "flex justify-between items-end">

          <div className = "filter_modal__key">Баланс счёта:</div>
          <div className = "filter_modal__value text-green">

            {( balance && balance?.split('.')?.[ 0 ] ) ? balance?.split('.')?.[ 0 ] : 0 }
            {( balance && balance?.split('.')?.[ 1 ] ) && <span style={{ paddingLeft: 'unset', opacity: '0.6', fontSize: '20px'}}>.{ balance.split('.')[ 1 ] }</span> }
            <span>₽</span>

          </div>

        </div>

        { showAmountField &&

          <React.Fragment>

            <br />

            <Textfield

              type = "number"
              title = "Сумма пополнения"
              value = { amount }
              set = { setAmount }
              refDOM = { amountFieldRef }

            />

          </React.Fragment>
        
        }

        <Button

          // icon = "+"
          text = { !showAmountField ? "Пополнить" : "Оплатить" }
          color = "border-green"
          action = { () => { 
            !showAmountField 
              ? handleShowAmountField()
              : wallet.increaseMoney( amount )
          }}

        />

          {/*<div className = "filter_modal__key" style={{*/}
          {/*  marginTop: "12px"*/}
          {/*}}>Платежи:</div>*/}
          {/*<Button*/}

          {/*  // icon = "+"*/}
          {/*  text = "Управлять"*/}
          {/*  color = "border-green"*/}
          {/*  action = { () => { window.location.href = '/payments' } }*/}

          {/*/>*/}

        </div>

      <hr />

      <div className = "filter_modal__section">

        <div className = "flex justify-between items-end">

          <div className = "filter_modal__key">Привязанная карта:</div>
          <div className = "filter_modal__value text-orange">{ !card ? "НЕТ" : card }</div>

        </div>

        <Button

          // icon = "+"
          text = "Привязать"
          color = "border-orange"
          action = { wallet.addCard }

        />

      </div>

      <hr />

      <div className="filter_modal__section">

        <div className = "filter_modal__key text-center">Сумма ежемесячного списания:</div>
        <div className = "filter_modal__value text-center text-orange">

          { monthly ? <React.Fragment>{ monthly.split('.')[ 0 ] }<span>₽</span></React.Fragment> : "НЕ ВЫБРАНО" }

        </div>

      </div>

      <hr />

      <div className = "filter_modal__section filter_modal__section_exit flex items-center" onClick = { sign.Out }>

        <Exit />
        <span className="flex-1 text-red text-center">Выйти из аккаунта</span>

      </div>

    </div>

  );

}

export default UserMenu;