import React, { Fragment, useState, useEffect, useCallback } from 'react';
import useGlobal from "../../store";

import { CameraModalAdd, CameraModalEdit } from './camera';
import { UserModalAdd, UserModalEdit } from './user';

import { BackgroundBlur } from './backgrounds';

import './popups.scss';
import './animations.scss';
import { PaymentResult } from './payment.result';
import { ExportModalAdd } from "./export";
import { VisitorsModalAdd, VisitorsModalEdit } from "./visitors";
import { PeopleEdit } from "./people";
import { PeopleAddPhoto } from "./people/popup.people.add.photo";
import {MarkupModalAdd, MarkupModalEdit} from "./markup.confirm";

const PopupController = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { popup } = globalState;

  useEffect(() => { 
    
    globalActions.popup.hidePopups(); 
    return () => { return null }
  
  }, [ ]);

  const POPUPS = {

    cameraAdd: <CameraModalAdd />,
    cameraEdit: <CameraModalEdit id = { popup.id } />,

    exportAdd: <ExportModalAdd />,

    peopleEdit: <PeopleEdit person = { popup.id }/>,
    peoplePhotoAdd: <PeopleAddPhoto/>,

    visitorsAdd: <VisitorsModalAdd />,
    visitorsEdit: <VisitorsModalEdit id = { popup.id }/>,

    userAdd: <UserModalAdd />,
    userEdit: <UserModalEdit id = { popup.id } />,

    markupAdd: <MarkupModalAdd markup = { popup.markup }/>,
    markupEdit: <MarkupModalEdit id = { popup.id } markup = { popup.markup }/>,

    cardSuccess: <PaymentResult mode = "cardSuccess" />,
    cardFail: <PaymentResult mode = "cardFail" />,
    balanceSuccess: <PaymentResult mode = "balanceSuccess" />,
    balanceFail: <PaymentResult mode = "balanceFail" />
    
  }

  return ( popup.show ? POPUPS?.[ popup.name ] : null );

}

export default PopupController;

export function ClosePopup() {

  const [ globalState, globalActions ] = useGlobal();

  useEffect(() => { 

    globalActions.popup.hidePopups() 
    return () => { return null }

  }, []);

  return ( <React.Fragment/> );
  
}

export const Popup = (props) => {
  const [ , globalActions ] = useGlobal();
  const CN = props.className || "";
  const [popupClass, setPopupClass] = useState(`popup ${CN} zoomIn`);
  const [blurClass, setBlurClass] = useState(``);

  useEffect(() => {
    document.addEventListener("keydown", closeByEsc, false);

    return () => {
      document.removeEventListener("keydown", closeByEsc, false);
    };
  }, []);

  function close() {
    setPopupClass(`popup ${CN} zoomOut`);
    setBlurClass("fadeOut");
    setTimeout(() => {
      globalActions.popup.hidePopups();
    }, 250);
  }

  const closeByEsc = useCallback(
    (e) => {
      (e.keyCode === 27 || e.keyCode === "Escape") && close();
    },
    []
  );

  return (
    <Fragment>
      <BackgroundBlur className={blurClass} onClick={close} />
      <div className="popup-container">
        <div id="popup" className={popupClass}>
          <div id="popupClose" onClick={close} />
          {props.children}
        </div>
      </div>
    </Fragment>
  );
};

export const popupClose = () => document.getElementById('popupClose').click();