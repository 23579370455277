import React, { useEffect, useState } from 'react';

import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';
import useGlobal from '../../../store';

import Textfield from '../../components/textfield';

import { Popup, popupClose } from '../index';

import * as user from '../../../store/actions/users';

import './popup.user.scss';
import Section from '../../components/containers/section';
import Checkbox from '../../components/checkbox';
import Button from '../../components/button';

export const UserModalAdd = () => {

  const [ globalState, globalActions ] = useGlobal();
  
  const reload = () => {
    
    globalActions.users.all();
    popupClose();

  }

  return (

    <UserModal

      mode = "add" 
      func = { ( form ) => { user.add( form, reload ); }} 
      
    />

  );

}

export const UserModalEdit = ({ id }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { users, cameras } = globalActions;
  
  const reload = () => {
    
    users.all();
    popupClose();

  }

  const [ data, setData ] = useState({});
  const [ isDataLoaded, setIsDataLoaded ] = useState( false );

  useEffect(() => {

    if ( !isDataLoaded ) {

      ( async () => {

        await user.get({

          id: id,
          set: setData

        });

        setIsDataLoaded( true );

      })();
    
    }

  }, []);

  return (

    isDataLoaded

      ? <UserModal

          id = { id }
          mode = "edit" 
          func = { ( form ) => {  user.edit( form, reload ); }}
          { ...data }
          
        />

      : null

  );

}

export const UserModal = ( props ) => {

  const [ globalState, globalActions ] = useGlobal();
  const [ Dummy, reloadDummy ] = useReloadDummy();

  const { 

    camerasTable,
    camerasTableLoaded,

  } = globalState;

  const TITLE = {
    add: "Добавление нового",
    edit: "Редактирование",
  }
  const FUNC_BTN_TEXT = {
    add: "Добавить",
    edit: "Сохранить",
  }
  const SENDMAIL_TEXT = {
    add: ( <div> <b>Внимание:</b> перед добавлением обязательно отправьте сотруднику данные для входа,<br/>иначе он не сможет войти. Можно сделать это нажав на кнопку ниже: </div>),
    edit: ( <div> <b>Внимание:</b> вы изменили данные для входа сотрудника.<br/>Обязательно известите его об этом, иначе он не сможет войти: </div>),
  }

  const [ isDataSended, setIsDataSended ] = useState( false );

  const [ isPasswordChanged, setIsPasswordChanged ] = useState( false );

  const [ password, setPassword ] = useState("");
  const [ name, setName ] = useState( props.name || "" );
  const [ email, setEmail ] = useState( props.email || "" );
  const [ phone, setPhone ] = useState( props.phone || "" );
  const [ canModifyEvents, setCanModifyEvents ] = useState( props?.canModifyEvents !== undefined ? props.canModifyEvents : true );
  const [ ignoreCameras, setIgnoreCameras ] = useState( props.ignoreCameras || [] );

  const isLoginOrPasswordChanged = ( ( isPasswordChanged && password.length > 0 ) || props?.email !== email );
  
  const isFormValidated = (

    ( password.length > 2 || !isPasswordChanged )
    && ( email.length > 7 && email.indexOf('@') !== -1 && email.indexOf('.') !== -1 )
    && phone.length >= 10

  );

  function generatePassword() {

    let length = 8,
    charset = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$";

    if ( window.crypto && window.crypto.getRandomValues ) {

      return Array(length)
        .fill(charset)
        .map(x => x[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * (x.length + 1))])
        .join('');   

    } else {

      let res = '';

      for ( let i = 0, n = charset.length; i < length; ++i ) {

        res += charset.charAt( Math.floor( Math.random() * n ) );

      }

      return res;

    }

  }

  function toggleIgnoreCamera( id ) {

    const temp = ignoreCameras;

    ignoreCameras.indexOf( id ) === -1 
      ? temp.push( id )
      : temp.splice( ignoreCameras.indexOf( id ), 1 )

    console.log( `toggleIgnoreCamera(${ id }), temp:`, temp );

    setIgnoreCameras( temp );
    reloadDummy();

  }

  function sendForm() {

    let form = {

      name,
      email,
      phone,
      password: ( props.mode === "add" || isPasswordChanged ) ? password : false,
      ignore_cameras: ignoreCameras,
      can_change_event_status: canModifyEvents

    }

    if ( props.mode === "edit" ) { form.id = props.id; }

    console.log( `sendForm:`, form );
    props.func( form );

  }

  return (

    <Popup className = "popup_user">

      <div className="popup__title">{ TITLE[ props.mode ]} сотрудника</div>

      <div className="flex">

        <div className="popup_user__inputs">

          <Textfield

            title = "Фамилия Имя Отчество:"
            value = { name }
            set = { setName }
            placeholder = "Смотрителев Ревизор Наблюдателевич"

          />

          <div className="flex popup_user__inputs__contacts">

            <Textfield

              title = "E-mail:"
              value = { email }
              set = { setEmail }
              placeholder = "example@mail.ru"

            />

            <Textfield

              title = "Телефон:"
              value = { phone }
              set = { setPhone }
              placeholder = "+7XXXXXXXXXX"

            />

          </div>

          { ( props.mode === "add" || isPasswordChanged ) &&
            
            <div className="popup_user__inputs__password flex">

              <Textfield

                title = "Пароль:"
                value = { password }
                set = { setPassword }
                placeholder = "Англ.буквы, цифры"

              />

              <Button

                text = "Сгенерировать"
                color = "border-gray"
                action = { () => setPassword( generatePassword() ) }

              />

            </div>
          
          }

          { ( props.mode === "edit" && !isPasswordChanged ) &&

            <div className="textfield popup_user__inputs__resetpassword">

              <div className="textfield__title">Пароль:</div>

              <div 
                className="popup_user__inputs__resetpassword__text"
                onClick={() => { 
                  window.confirm("Вы точно хотите сбросить пароль?") && setIsPasswordChanged( true );
                }}
              >

                Мы не храним пароли в открытом виде.<br/>
                Установить новый пароль вы можете <span>сбросив старый</span>.
                
              </div>

            </div>

          }

          <Checkbox

            text = "Может подтверждать события, отмечать их как важные, и удалять"
            value = { canModifyEvents }
            set = { setCanModifyEvents }

          />

        </div>

        { !camerasTableLoaded ? "Загрузка камер..." :
            
          <Section
          
            scrollable 
            title = "Может смотреть камеры:" 
            className = "popup_user__cameras"
            
          >

            <Dummy />

            { camerasTable.length > 0 && camerasTable.map(( cam, key ) => (

              <Checkbox

                text = { cam.comment }
                value = { ignoreCameras.indexOf( cam.id ) === -1 }
                set = { () => toggleIgnoreCamera( cam.id ) }

              />

            ))}

          </Section> 
          
        }

      </div>

      <div className = "popup_user__controls">

        {( isFormValidated && ( props.mode === "add" || isLoginOrPasswordChanged ) ) &&
        
          <div className="popup__area popup_user__controls__share flex flex-col items-center">

            { !isDataSended && 
            
              <React.Fragment>
                  
                { SENDMAIL_TEXT[ props.mode ]}

                <a 
                  href={`mailto:${email}?body=${ props.mode !== "edit" ? "Данные" : "Изменились данные"} для входа в <a href="https://info-vision.ru">info-vision.ru</a>: <br>логин: ${( props.mode !== "edit" || email !== props.email ) ? email : "тот же, что и был"} <br>пароль: ${( props.mode !== "edit" || isPasswordChanged ) ? password : "тот же, что и был"}&subject=${ props.mode !== "edit" ? "Добро пожаловать" : "Изменились данные для входа" } в Info Vision!`}
                  rel="norefferer"
                  target="_blank"
                >
                  <Button

                    text = "Отправить сотруднику данные для входа на e-mail"
                    color = "orange"
                    action = { () => { setIsDataSended( true ) } }

                  />

                </a>

              </React.Fragment> 

            }

            <Checkbox

              text = "Я отправил этому сотруднику его данные для входа"
              value = { isDataSended }
              set = { () => { setIsDataSended( !isDataSended )}}

            />

          </div>

        }

        <div className="flex">

          {( ( isDataSended || ( props.mode === "edit" && !isLoginOrPasswordChanged )) && isFormValidated ) &&
          
            <Button

              text = { FUNC_BTN_TEXT[ props.mode ]}
              action = { sendForm }
              disabled = { !isFormValidated }

            />
            
          }

          {( !isDataSended || ( props.mode === "edit" && !isLoginOrPasswordChanged ) ) &&

            <Button

              text = "Отмена"
              action = { popupClose }
              color = "gray"

            />

          }
        
        </div>

      </div>


    </Popup>

  );

}