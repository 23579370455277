import { Fragment } from 'react';
import './stats.numbers.scss';

const StatsNumbers = ({ data }) => (

  <div className="stats_numbers flex justify-evenly items-center">

    { data.length > 0 && data.map(( stat, key ) => (

      <Fragment key = { key }>

        { key !== 0 && <div className="stats_numbers__line" /> }

        <div className="stats_numbers__item" key = { key }>

          { stat?.title && 

            <div className="stats_numbers__item__title">
              { stat.title }
            </div>
            
          }

          <div 
            className="stats_numbers__item__value" 
            style = {{ backgroundImage: stat?.gradient || 'unset' }}
          >

            { stat.value }

          </div>

        </div>

      </Fragment>

    ))}

  </div>

);

export default StatsNumbers;