import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

export async function all(set, setLoaded) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из all EventType`);
    return false;
  }

  const { status, data } = await axios.post(API.eventCategories.all, {
    token: token,
  });

  console.log(`status EventCategories: ${status}`);

  if (status === 200 && data?.eventCategories) {
    set && set(data.eventCategories);
    setLoaded && setLoaded(true);
    return data.eventCategories;
  } else {
    setLoaded && setLoaded(false);
    console.error(data.text);
  }
}
