import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

export async function all(set) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из get models`);
    return false;
  }

  const { status, data } = await axios.post(API.models.all, { token: token });

  console.log(`status: ${status}, tree:`, data.models);

  if (status === 200 && data?.models) {
    set && set(data.models);

    return data.models;
  } else {
    console.error(data.text);
  }
}

export async function configurations(store, reload = false) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из all modelsConfs`);
    return false;
  }

  const { status, data } = await axios.post(API.models.configurations, {
    token: token,
  });

  if (status === 200 && data?.configurations) {
    store.setState({
      modelsConfTable: data.configurations,
      modelsConfTableLoaded: true,
    });

    reload && reload();

    return data.configurations;
  } else {
    console.error(data.text);
  }
}

export async function getTemplate(store, id) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из getTemplate`);
    return false;
  }

  const { status, data } = await axios.post(API.models.getTemplate, {
    token: token,
    id: id,
  });

  if (status === 200 && data?.template) {
    store.setState({
      modelTemplate: data.template,
      modelTemplateLoaded: true,
    });
    return data.template;
  } else {
    console.error(data.text);
  }
}

export async function getConfiguration(store, id) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из getConfiguration`);
    return false;
  }

  const { status, data } = await axios.post(API.models.getConfiguration, {
    token: token,
    id: id,
  });

  if (status === 200 && data?.configuration) {
    store.setState({
      modelConfiguration: data.configuration,
      modelConfigurationLoaded: true,
    });
    return data.configuration;
  } else {
    console.error(data.text);
  }
}

export async function allTemplates(store) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из all modelsTemplates`);
    return false;
  }

  const { status, data } = await axios.post(API.models.allTemplates, {
    token: token,
  });

  if (status === 200 && data?.templates) {
    store.setState({
      modelsTemplates: data.templates,
      modelsTemplatesLoaded: true,
    });

    return data.templates;
  } else {
    console.error(data.text);
  }
}

export async function addConfiguration(_, data, reload = false) {
  // store, data, reload = false
  // Я забыл на самом деле почему первым автоматически передается store, но это не важно.
  // Важно, что вторым параметром передается data, а третьим reload

  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из add modelsConf`);
    return false;
  }

  const form = {
    token: token,

    ...data,
  };

  console.log(`addConfiguration form:`, data);

  const { status, data: response } = await axios.post(API.models.addConfiguration, form);

  if (status === 200 && response?.text) {
    console.log(response.text);

    reload && reload();

    return true;
  }

  console.log("Ошибка при добавлении конфигурации модели");
}

export async function editConfiguration(_, data, reload = false) {
  // store, data, reload = false
  // Я забыл на самом деле почему первым автоматически передается store, но это не важно.
  // Важно, что вторым параметром передается data, а третьим reload

  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из edit modelsConf`);
    return false;
  }

  const form = {
    token: token,

    ...data,
  };

  console.log(`editConfiguration form:`, data);

  const { status, data: response } = await axios.post(API.models.editConfiguration, form);

  if (status === 200 && response?.text) {
    reload && reload();

    return true;
  }

  console.log("Ошибка при редактировании конфигурации модели");
}

export async function removeConfiguration(_, id, reload) {
  // store, id, reload

  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из remove modelsConf`);
    return false;
  }

  if (window.confirm(`Вы уверены, что хотите удалить конфигурацию модели?`)) {
    const { status, data } = await axios.post(API.models.removeConfiguration, {
      token: token,
      id: id,
    });

    if (status === 200) {
      reload && reload();
    } else {
      console.error(data.text);
    }
  }
}
