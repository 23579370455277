export const serviceDropdown3 = [
  // Владелец объекта

  {
    id: 1,
    title: "Пользователи",
    path: "/admin",
    cName: "submenu-item",
  },

  {
    id: 2,
    title: "Экспорт данных",
    path: "/export",
    cName: "submenu-item",
  },

  {
    id: 3,
    title: "Посетители",
    path: "/visitors",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "Разметка",
    path: "/markups",
    cName: "submenu-item",
  },
];

export const serviceDropdown2 = [
  // Администратор и прочие

  {
    id: 1,
    title: "Камеры",
    path: "/admin",
    cName: "submenu-item",
  },

  {
    id: 2,
    title: "Экспорт данных",
    path: "/export",
    cName: "submenu-item",
  },

  {
    id: 3,
    title: "Посетители",
    path: "/visitors",
    cName: "submenu-item",
  },

  {
    id: 4,
    title: "Конфигурации моделей",
    path: "/models",
    cName: "submenu-item",
  },
  {
    id: 5,
    title: "Разметка",
    path: "/markups",
    cName: "submenu-item",
  },
];
