import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

export async function all(store, reload = false) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из all export`);
    return false;
  }

  const { status, data } = await axios.post(API.export.all, { token: token });

  console.log(`status: ${status}`);

  if (status === 200 && data?.records) {
    store.setState({
      exportTable: data.records,
      exportTableLoaded: true,
    });

    reload && reload();

    return data.export;
  } else {
    console.error(data.text);
  }
}

export async function add(form, reload) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из add export`);
    return false;
  }
  const params = {
    token,
    ...form,
  };

  console.log(`Export.add form:`, params);
  const { status, data } = await axios.post(API.export.add, params);
  reload();
  if (status === 400) {
    alert("Введены некорректные данные, проверьте правильность введенных параметров");
  }
  if (status === 204) {
    alert("За указанный промежуток нет данных");
  }
}

export async function addPeople(form, reload) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из addPeople export`);
    return false;
  }
  const params = {
    token,
    ...form,
  };

  console.log(`Export.addPeople form:`, params);
  if (form.people.length > 3) {
    alert(
      "Экспорт данных займет некоторое время, ввиду большого количества выбранных сотрудников"
    );
  }

  reload();

  const { status, data } = await axios.post(API.export.addPeople, params);
  if (status === 400) {
    alert("Введены некорректные данные, проверьте правильность введенных параметров");
  }
  if (status === 204) {
    alert("За указанный промежуток нет данных");
  } else {
    console.error(data.text);
  }
}

export async function remove(store, id) {
  if (
    window.confirm(
      "Вы действительно хотите удалить запись экспорта? Отменить это действие невозможно!"
    )
  ) {
    const token = cookie.load("token");
    if (!token) {
      console.error(`Попытка разлогина из remove export`);
      return false;
    }

    const { status, data } = await axios.post(API.export.remove, {
      token: token,
      id: id,
    });

    if (status === 200) {
      store.actions.exportDB.all();
    } else {
      console.error(data.text);
    }
  }
}
