import './intraday.scss';

const IntraDay = ({ data = [], timeline }) => {

  return (

    <div className="intraday flex flex-col-reverse flex-1">
      
      <div className="intraday__content flex flex-col-reverse flex-1">

        <IntraDayTimeLine content = { timeline } />

        { data.length > 0 && data.map(( row, key ) => (

          <IntraDayRow 

            key = { key }
            events = { row } 
          
          />

        ))}

      </div>

    </div>

  );

}

const IntraDayRow = ({ events = [] }) => (

  <div className="intraday__row relative flex items-center">

    { events?.length > 0 && events.map(( event, key ) => (

      <IntraDayEvent

        key = { key }
        width = { event.width }
        offset = { event.offset }
        color = { event.color }
        tip = { event?.type !== 'счётчик' ? `${ event.start }-${ event.end } (${ event.diff })` : `счётчик (${ event.start })` }

      />

    ))}

  </div>

);

const IntraDayEvent = ({ width, offset, color, tip = "" }) => (

  <div 

    className = "intraday__row__event absolute"
    title = { tip }
    style = {{

      width: `${ width }%`,
      left: `${ offset }%`,
      background: color

    }}

  />

);

const IntraDayTimeLine = ({ content = [] }) => (

  <div className="intraday__row intraday__row-timeline relative flex items-center">

    { content.length > 0 && content.map(( step, key ) => (

      <IntraDayTime

        key = { key }
        time = { step.time }
        offset = { step.offset }

      />

    ))}

  </div>

);

const IntraDayTime = ({ time, offset }) => (

  <div 

    className = "intraday__row__time relative"
    style = {{ left: `${ offset }%` }}

  >

    {/* { time } */}
    <div className="absolute">{ time }</div>

  </div>

);

export default IntraDay;