import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

export async function add({ camera, name, comment, eventType }) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из add location`;
  }

  const params = {
    token,
    camera,
    name,
    comment,
    eventType,
  };

  try {
    const { status, data } = await axios.post(API.locations.add, params);
    if (status !== 200 || !data.id) {
      throw data.text;
    }

    console.log("Локация успешно добавлена");
    return data.id;
  } catch (err) {
    console.error(`Не удалось добавить локацию:`, err);
    return false;
  }
}

export async function all(set) {
  const token = cookie.load("token");

  if (!token) {
    console.error(`Попытка разлогина из all cameras`);
    return false;
  }

  const { status, data } = await axios.post(API.locations.all, { token });

  if (status === 200 && data?.locations) {
    set(data.locations);

    return data.locations;
  } else {
    console.error(data.text);
  }
}

export async function edit({ id, camera, name, comment, eventType }) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из edit location`;
  }

  const params = {
    token,
    id,
    camera,
    name,
    comment,
    eventType,
  };

  try {
    const { status, data } = await axios.post(API.locations.edit, params);
    if (status !== 200) {
      throw data.text;
    }

    console.log("Локация успешно отредактирована");
  } catch (err) {
    console.error(`Не удалось отредактировать локацию:`, err);
  }
}

export async function remove(id, camera) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из edit location`;
  }

  const params = {
    token,
    id,
    camera,
  };

  try {
    const { status, data } = await axios.post(API.locations.remove, params);
    if (status !== 200) {
      throw data.text;
    }

    console.log("Локация успешно удалена");
  } catch (err) {
    console.error(`Не удалось удалить локацию:`, err);
  }
}
