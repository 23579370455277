import cookie from "react-cookies";
import axios from "axios";
import API from "../../settings/api";

export async function all(store) {
  const token = cookie.load("token");

  if (!token) {
    console.error(`Попытка разлогина из People.all`);
    return false;
  }

  store.setState({
    PeopleListLoaded: false,
  });

  const { status, data } = await axios.post(API.people.all, { token: token });

  console.log(`People all status: ${status}`);

  if (status === 200 && data?.people) {
    store.setState({
      PeopleList: data.people,
      PeopleListLoaded: true,
    });

    return data.people;
  } else {
    console.error(data.text);
  }
}

export async function photos(store, face) {
  const token = cookie.load("token");

  if (!token) {
    console.error(`Попытка разлогина из People.all`);
    return false;
  }

  store.setState({
    selected_face_photosLoaded: false,
  });

  const { status, data } = await axios.post(API.people.photos, {
    token: token,
    id: face.id,
  });

  console.log(`People photos status: ${status}`);

  if (status === 200 && data?.photos) {
    store.setState({
      selected_face_photos: data.photos,
      selected_face_photosLoaded: true,
    });

    return data.people;
  } else {
    console.error(data.text);
  }
}

export async function edit(form, reload = false) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из edit people`);
    return false;
  }

  const params = {
    token,
    ...form,
  };

  const { status, data } = await axios.post(API.people.edit, params);

  if (status === 200) {
    console.log(`people.edit 200`);

    reload && reload();
  } else {
    console.error(data.text);
  }
}

export async function allEmployees(store) {
  const token = cookie.load("token");

  if (!token) {
    console.error(`Попытка разлогина из allEmployees`);
    return false;
  }

  const { status, data } = await axios.post(API.people.allEmployees, {
    token: token,
  });

  if (status === 200 && data?.people) {
    store.setState({
      FacilityEmployeeList: data.people,
      FacilityEmployeeListLoaded: true,
    });

    return data.people;
  } else {
    console.error(data.text);
  }
}

export async function Categories(set) {
  const token = cookie.load("token");

  if (!token) {
    console.error(`Попытка разлогина из Categories`);
    return false;
  }

  const { status, data } = await axios.post(API.people.categories, {
    token: token,
  });

  if (status === 200 && data?.categories) {
    set(data.categories);

    return data.categories;
  } else {
    console.error(data.text);
  }
}

export async function Roles(set) {
  const token = cookie.load("token");

  if (!token) {
    console.error(`Попытка разлогина из Roles`);
    return false;
  }

  const { status, data } = await axios.post(API.people.roles, { token: token });

  if (status === 200 && data?.roles) {
    set(data.roles);

    return data.roles;
  } else {
    console.error(data.text);
  }
}

export async function setAll(set) {
  const token = cookie.load("token");

  if (!token) {
    console.error(`Попытка разлогина из setAll visitors`);
    return false;
  }

  const { status, data } = await axios.post(API.people.all, { token: token });

  if (status === 200 && data?.people) {
    set(data.people);

    return data.people;
  } else {
    console.error(data.text);
  }
}

export async function rename(store, id, name, force_mapping = true) {
  const token = cookie.load("token");

  if (!token) {
    store.actions.sign.Out();
    console.error(`Попытка разлогина из rename`);
  }

  const params = {
    token: token,
    id: id,
    name: name,
  };

  const { status } = await axios.post(API.people.rename, params);

  if (status !== 200) {
    console.error(`Не удалось выполнить запрос на переименование`);
    return false;
  }

  if (force_mapping) {
    const { eventsList, eventsNowList } = store.state;
    const ActualEventsList =
      eventsNowList.length > eventsList.length ? eventsNowList : eventsList;

    for (let i = 0; i < ActualEventsList.length; i++) {
      if (ActualEventsList[i].person.id === id) {
        ActualEventsList[i].person.name = name;
      }
    }

    if (eventsList.length > 0) {
      await store.setState({
        eventsList: ActualEventsList,
      });
    } else {
      await store.setState({
        eventsNowList: ActualEventsList,
      });
    }
  }
}

export async function BestPhoto(store, id, photo, index) {
  const token = cookie.load("token");

  const { PeopleList, selected_face } = store.state;
  if (!token) {
    store.actions.sign.Out();
    console.error(`Попытка разлогина из rename`);
  }

  const params = {
    token,
    id,
    photo,
  };
  selected_face._dbp = undefined; // _dbp => debug best photo, нужна ли смена состояний звездочки

  await store.setState({
    selected_face: selected_face,
  });
  const { status } = await axios.post(API.people.BestPhoto, params);

  if (status !== 200) {
    console.error(`Не удалось выполнить запрос на изменение фотографии`);
    return false;
  }

  for (let i = 0; i < PeopleList.length; i++) {
    if (PeopleList[i].id === id) {
      PeopleList[i].photo = photo;
    }
  }
  selected_face.photo = photo;
  selected_face._dbp = index;

  await store.setState({
    PeopleList: PeopleList,
    selected_face: selected_face,
  });
}

export async function deletePhoto(store, id, photo) {
  const token = cookie.load("token");

  const { selected_face_photos } = store.state;
  if (!token) {
    store.actions.sign.Out();
    console.error(`Попытка разлогина из rename`);
  }

  const params = {
    token,
    id,
    photo,
  };

  const { status } = await axios.post(API.people.deletePhoto, params);

  if (status !== 200) {
    console.error(`Не удалось выполнить запрос на удаление фотографии`);
    return false;
  }

  for (let i = 0; i < selected_face_photos.length; i++) {
    if (selected_face_photos[i] === photo) {
      selected_face_photos.splice(i, 1);
    }
  }

  await store.setState({
    selected_face_photos,
  });

  console.log(`Успешно удалено!`);
}

export async function addPhoto(form, reload = false) {
  const token = cookie.load("token");

  if (!token) {
    console.error(`Попытка разлогина из rename`);
    return false;
  }

  const params = {
    token,
    id: form.id,
    photos: form.photos,
  };

  const { status } = await axios.post(API.people.addPhoto, params);

  if (status === 200) {
    reload && reload();
    console.log(`Успешно добавлено!`);
    return true;
  } else {
    console.error(`Не удалось выполнить запрос на добавление фотографий`);
    return false;
  }
}

export async function deletePerson(store, id) {
  const token = cookie.load("token");

  const { PeopleList } = store.state;
  if (!token) {
    store.actions.sign.Out();
    console.error(`Попытка разлогина из rename`);
  }

  const params = {
    token,
    id,
  };

  const { status } = await axios.post(API.people.delete, params);

  if (status !== 200) {
    console.error(`Не удалось выполнить запрос на удаление персоны`);
    return false;
  }

  for (let i = 0; i < PeopleList.length; i++) {
    if (PeopleList[i].id === id) {
      PeopleList.splice(i, 1);
    }
  }

  await store.setState({
    PeopleList,
  });

  console.log(`Успешно удалено!`);
}
