function pluralize(value, singular, plural, many) {
  const lastDigit = value % 10;
  const lastTwoDigits = value % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return singular;
  }
  if (lastDigit >= 2 && lastDigit <= 4 && (lastTwoDigits < 12 || lastTwoDigits > 14)) {
    return plural;
  }
  return many;
}

function convertDuration(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600);
  const remainingSeconds = durationInSeconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  const hourStr = hours > 0 ? `${hours} ${pluralize(hours, "час", "часа", "часов")}` : "";
  const minuteStr =
    minutes > 0 ? `${minutes} ${pluralize(minutes, "минута", "минуты", "минут")}` : "";
  const secondStr =
    seconds > 0 ? `${seconds} ${pluralize(seconds, "секунда", "секунды", "секунд")}` : "";

  const parts = [hourStr, minuteStr, secondStr].filter(part => part !== "");
  return parts.join(" ");
}
export default convertDuration;
