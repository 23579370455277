import React, {useEffect, useRef, useState} from "react";
import "./face.scss";
import Editable from "../editable/editable";
import useGlobal from "../../../store";
import { IconWrapper } from "../filestructure/styled";
import { getIcon } from "../filestructure/helpers";
import Tooltip from "../tooltip/tooltip";

const Face = ({  // Расширенное лицо для страницы по сотрудникам (с возможностью редактирования)

  id = "",
  index = "0",
  name = "",
  old = "",
  sex = "",
  role = "",
  category = "",
  photo = "" ,
  visits = 1


}) => {
  const person = {

    id,
    name,
    old,
    sex,
    role,
    category,
    photo

  }

  const [ globalState, globalActions ] = useGlobal();

  const {
    selected_face_photosLoaded  // фотографии загружены
  } = globalState

  const { popup, people, changeState } = globalActions;

  const [ nameValue, setName ] = useState(name);

  useEffect( () => { // Поддержка обновления имени у всех карточек этого человека
    if ( nameValue !== name ){ // Если имя изменилось
      setName(name) // Обновляем имя
    }
  }, [name, nameValue])

  function handleFaceSelect(e) {
    if (e.target !== DeletePersonRef.current) {
      changeState("selected_face", person) // Выбираем лицо
      !selected_face_photosLoaded && people.photos(person) // Загружаем фотографии
    }
  }

  const EditIconRef = useRef();

  let DeletePersonRef = useRef();

  function onDeleteClick() {
    people.Delete(person.id)
  }


  return (

  <div className = {`faceExtended`}>

    <Tooltip text = "Удалить человека из базы" TooltipPosition = "top" ChildrenRef={ DeletePersonRef } Offset={{
      top: -170,
      left: 10
    }} onCriticalPositionOffset={ 185 }/>

    <div className = "faceExtended__image" style = {{ backgroundImage: `url(${ photo })` }}  onClick={(e) => handleFaceSelect(e)}>

      <div className = {`faceExtended_DeleteButton`}>
        <button
          ref = { DeletePersonRef }
          className = {`faceExtended_DeleteButton__item event__btn_icon event__btn_icon__cancel left_sided`}
          onClick = { () => { if ( window.confirm(`Вы действительно хотите полностью удалить человека и всего его фотографии?`) )
          { onDeleteClick(); }} }

        />
      </div>
      <div className = "faceExtended__image__number" onClick={(e) => handleFaceSelect(e)}>
        { index }
      </div>

    </div>

    <div className = "faceExtended__sidebar">

      <div className = "border-bottom faceExtended__sidebar__name">
        <Editable
          onClick={() => handleFaceSelect(person)}
          id = { id }
          text = { nameValue }
          oldText = { name }
          handler = { people.rename }
          type = "input"
        >
          <input
            onClick={() => handleFaceSelect(person)}
            className = { "text-field__input" }
            type = "text"
            name = "EmployeeInput"
            value = { nameValue }
            onChange = { e => setName( e.target.value ) }
            autoComplete = { 'off' }
            autoFocus = { true }
          />
        </Editable>
        <Tooltip text={'Редактирование данных о человеке'} ChildrenRef={EditIconRef} Offset={{
          top: -170,
          left: 20
        }}/>
        <div ref={ EditIconRef } className = { 'faceExtended__sidebar__editable' } onClick={ () => popup.showPopup('peopleEdit', { id: person }) }>
          <IconWrapper margin_right = {'0'} margin_bottom={'2px'} margin_top={'4px'}>

            { getIcon('edit') }

          </IconWrapper>
        </div>
      </div>
      <div className = {`border-bottom faceExtended__sidebar__visits`} onClick={(e) => handleFaceSelect(e)}>

        <span className = "faceExtended_front">

          <span className="faceExtended__sidebar__time__btn">Посещений: { visits }</span>

        </span>


        <span className = "faceExtended_back">Пол: { +sex === 1 ? "мужской" : +sex === 2 ? "женский" : "неизвестен" }</span>

      </div>

      <div className = "borer-bottom face__sidebar__post" onClick={(e) => handleFaceSelect(e)}>

        <span className = "faceExtended_front">{ category }</span>
        <span className = "faceExtended_back">{ old > 0 ? `Возраст: ${ old }` : `Возраст: Неизвестно` }</span>

      </div>

      <div className = "faceExtended__sidebar__role" onClick={(e) => handleFaceSelect(e)}>

        <span className = "faceExtended_front">{ role?.length > 0 ? `Должность: ${ role }` : `Должность: Неизвестно` }</span>

      </div>

    </div>


  </div>

)};

export default Face;