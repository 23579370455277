import React, { useEffect } from 'react';
import useGlobal from '../../../store';
import TableHead from '../../components/table.head';
import './export.scss';

import LoadingSpinner from '../../components/loader';
import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';
import TableExport from "../../components/table.export";

const ExportView = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { 

    exportTable,
    exportTableLoaded

  } = globalState;

  const { popup, exportDB } = globalActions;

  const [ Dummy, reloadDummy ] = useReloadDummy();

  useEffect(() => { exportDB.all( reloadDummy ) }, []);

  const TABLE_HEAD = [
    {
      name: "Название",
      sort: true,
      // action: () => handleSort("alphabetical")
    },
    {
      name: "Комментарий",
      sort: false,
      // action: () => handleSort("checkinsCount")
    },
    {
      name: "Начало",
      sort: true,
      // action: () => handleSort("createdAt")
    },
    {
      name: "Окончание",
      sort: true
    },
    {
      name: "Формат экспорта",
      sort: true
    },
    {
      name: "Действия",
      sort: false
    }

  ]

  return (

    <div className="export-container">

      <div className="export export-records">

        <TableHead

          title = "Экспорт данных"
          action = { () => popup.showPopup('exportAdd') }

        />

        <Dummy />

        { !exportTableLoaded
          
          ? <LoadingSpinner />
          
          : <TableExport

              className = "export"
              template = "158px 1fr 130px 130px 207px 155px"
              columns = { TABLE_HEAD }
              rows = { exportTable }
              total = { exportTable.length }
              remove = { ( id ) => { exportDB.remove(id) } }
              loadMore = { () => {} } 
              openModal = { () => {} }
              
            />

        }

      </div>

    </div>

  );

}

export default ExportView;