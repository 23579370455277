import { Link } from 'react-router-dom';
import cssIf from "../../../scripts/helpers/class.add.if";
import "./navbar.scss";
import {useEffect, useRef, useState} from "react";
import Dropdown from "../dropdown/Dropdown";
import useGlobal from "../../../store";

const NavBar = ({ type }) => {

  const [ globalState, ] = useGlobal();

  const { user_type, object_have_faces } = globalState;

  const TABS = [
    {
      link: "/",
      text: "События",
      type: 4
    },
    {
      link: "../stats",
      text: "Статистика",
      type: 3
    },

    {
      link: "../faces",
      text: "Лица",
      type: 3
    },
    {
      link: "../export",
      text: "Экспорт данных",
      type: 4
    },

  ];

  if ( !object_have_faces ){ TABS.splice(2, 1) }

  return (

    <nav className = "flex items-center">

      { TABS.map(( el, key ) => (

        (parseInt( type ) <= parseInt( el.type ) && (parseInt( el.type ) <= 4
          &&
          (parseInt( type ) < 4 && el.link !== '../export'))) &&  // Разграничение показа для 3 и 4

        // Подробнее:
          // У 4-го типа на данный момент должны быть видны в навигации: "События" и "Экспорт данных"
          // У 3-го типа на данный момент должны быть видны в навигации: "Камеры", "Статистика", "Администрация" с ..
          // .. выпадающим меню "Пользователи" и "Экспорт данных"
          // У 2-го типа и ниже на данный момент схожая с 3-м типом компоновка за исключением замены в меню ячейки ..
          // .. пользователей на камеры

        <Tab

          key    = {   key   }
          index  = {   key   }
          name   = { el.text }
          link   = { el.link }
          active = { window.location.pathname.replace( '/', '' ) }

        />





      )
      )}

      {(user_type <= 3 && <TabAdmin

        key    = {   2   }
        index  = {   2   }
        name   = { "Администрирование"  }
        link   = { "admin" }
        active = { window.location.pathname.replace( '/', '' ) }
      />)
      }

    </nav>

  );

}


const Tab = ({ index, name, link, active }) => (

  <Link

    to = { link }
    className = {`tab ${ cssIf( ( link.replaceAll('../', '') === active || ( index === 0 && active === "" ) ), "tab--active" ) } flex justify-center items-center height=10vh`}
  >


    <span>{ name }</span>

  </Link>


);


const TabAdmin = ({ index, name, active }) => {
  const [DropDown, setDropDown] = useState(false)
  const menuRef = useRef();

  useEffect(() => {
    let handler = (e)=>{
      if(!menuRef?.current?.contains(e.target)){
        setDropDown(false);
      }
    };

    document.addEventListener("mousedown", handler);


    return() =>{
      document.removeEventListener("mousedown", handler);
    }

  });

  return (
    <div
      className = {`tab ${ cssIf( ( (['admin', 'export', 'visitors', 'models', 'markups'].includes(active)) || ( index === 0 && active === "" ) ), "tab--active" ) } flex justify-center items-center`}
      onClick={ () => { setDropDown(!DropDown)} }
      ref={ menuRef }
    >

      { DropDown && <Dropdown active = { active }/> }


      <span>{ name }</span>

    </div>

  )};

export default NavBar;