import React, { useEffect } from 'react';
import cookie from 'react-cookies';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import originalScale from '../../scripts/helpers/forced.original.scale';

import useGlobal from '../../store';

import PopupController from '../popups';
import Header from '../components/header';

import Panel from './panel';
import SignIn from './signin';
import Admin from './admin';
import Stats from './stats';
import Export from "./export";
import Visitors from "./visitors";
import FacesPage from "./faces";
import Models from "./models";
import ModelsManager from "./modelsManager";
import TemplateCreator from "./templateCreator";
import DrawingCanvas from "./MarkupDrawer/MarkupDrawTool";
import Markups from "./Markups";

const AppRouter = () => {

  useEffect(() => { originalScale({ mainContainer: 'App' }); }, [] );

  return (

    <React.Fragment>

      <BrowserRouter>

        <Routes>

          <Route exact path='/' element={<Protected Component={ Panel } />} />
          <Route exact path='/signin' element={ <Container><SignIn /></Container> } />
          <Route exact path='/admin' element={ <Protected Component={Admin} /> } />
          <Route exact path='/export' element={ <Protected Component={Export} /> } />
          <Route exact path='/faces' element={ <Protected Component={FacesPage} /> } />
          <Route exact path='/stats' element={ <Protected Component={Stats} /> } />
          <Route exact path='/visitors' element={ <Protected Component={Visitors} /> } />
          <Route exact path='/models' element={ <Protected Component={Models} /> } />
          <Route exact path='/modelsManager' element={ <Protected Component={ModelsManager} /> } />
          <Route exact path='/template_creator' element={ <Protected Component={TemplateCreator} /> } />
          <Route exact path='/draw' element={ <Protected Component={ DrawingCanvas } /> } />
          <Route exact path='/markups' element={ <Protected Component={ Markups } /> } />

        </Routes>

      </BrowserRouter>

    </React.Fragment>

  );

}

const Container = ({ children }) => (

  <React.Fragment>

    <PopupController />
    <Header clean={window.location.pathname === "/signin"} />
    {children}

  </React.Fragment>

);

const Protected = ({ Component }) => {

  const [, globalActions] = useGlobal();
  const token = cookie.load('token');

  useEffect(() => {

    token
      ? globalActions.sign.Check(token)
      : globalActions.sign.Out()

  }, []);

  return (
    <Container>
      <Component />
    </Container>
  );

}

export default AppRouter;
