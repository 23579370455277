import React, {useEffect, useRef, useState} from "react";
import cssIf from "../../../scripts/helpers/class.add.if";
import "./face.scss";
import Editable from "../editable/editable";
import useGlobal from "../../../store";

const Face = ({

  id = "",
  index = "0",
  name = "",
  old = "",
  sex = "",
  role = "",
  date = "",
  endDate = "",
  category = "",
  time = { start: "", end: "" }, 
  photo = "" ,

  isOneLocationEvent = false,

  isConfirmed = false,
  isImportant = false,
  isRemoved = false,

  onCheckClick = () => {},
  onWarningClick = () => {},
  onCancelClick = () => {},

  controls = false,

  thisEvent,
  isActive = false,
  timeDiff,

  onPlayFromStart,
  onPlayFromEnd,

}) => {
  const [ globalState, globalActions ] = useGlobal();

  const { people } = globalActions;

  const [ nameValue, setName] = useState(name);

  const [ confirmed, setConfirmed ] = useState( isConfirmed );
  const [ important, setImportant ] = useState( isImportant );
  const [ removed, setRemoved ] = useState( isRemoved );

  const labelRef = useRef();

  useEffect(() => {

    important !== isImportant && setImportant( isImportant );
    confirmed !== isConfirmed && setConfirmed( isConfirmed );
    removed   !== isRemoved   && setRemoved( isRemoved );

    console.log(`labelRef:`, labelRef);

  }, [ isImportant, isConfirmed, isRemoved ]);

  useEffect( () => { // Поддержка обновления имени у всех карточек этого человека
    if ( nameValue !== name ){
      setName(name)
    }
  }, [ name ])

  return (
  
  <div className = {`face ${cssIf(isOneLocationEvent, 'one-location')} ${ cssIf( isActive, 'face-active' ) }`} ref = { thisEvent }>

    <div onClick = { onPlayFromStart } className = "face__image" style = {{ backgroundImage: `url(${ photo })` }}>

      <div className = "face__image__number">
        { index }
      </div>

    </div>

    <div className = "face__sidebar" >

      { controls &&
      <div className = {`face_buttons ${ cssIf( ( date.indexOf('/') !== -1 && ( date.split('/')?.[ 0 ]?.replaceAll(" ", "") !== date.split('/')?.[ 1 ]?.replaceAll(" ", "") ) ), `face_buttons--twodates` ) }`}>

        <button

          className={`event_buttons__item event__btn_icon event__btn_icon__check ${ cssIf( confirmed, "checked" )}`}
          onClick = { () => { onCheckClick(); setConfirmed( !confirmed ); } }

        />

        <button

          className = {`event_buttons__item event__btn_icon event__btn_icon__warning ${ cssIf( important, "checked" )}`}
          onClick = { () => { onWarningClick(); setImportant( !important ); } }

        />

        <button

          className = {`event_buttons__item event__btn_icon event__btn_icon__cancel ${ cssIf( removed, "checked" )}`}
          onClick = { () => { if ( removed || window.confirm(`Удалить событие?`) ) { onCancelClick(); setRemoved( !removed ); }} }

        />

      </div>
      }

      <div onClick = { onPlayFromStart } className = {`border-bottom face__sidebar__date ${ cssIf( ( date.indexOf('/') !== -1 && ( date.split('/')?.[ 0 ]?.replaceAll(" ", "") !== date.split('/')?.[ 1 ]?.replaceAll(" ", "") ) ), `face__sidebar__date--twodates` ) }`}>

        <span className = "face_front">

          { ( date.split('/')?.[ 0 ]?.replaceAll(" ", "") !== date.split('/')?.[ 1 ]?.replaceAll(" ", "") ) ? date : date.split('/')[ 0 ] }

        </span>
        
        <span className = "face_back">
          Возраст: { old }
        </span>



      </div>


      <div className = {`border-bottom face__sidebar__time ${ cssIf( time.end.length > 0, `face__sidebar__time--twotimes` ) }`}>
        
        <span className = "face_front">

          <span className="face__sidebar__time__btn" onClick = { onPlayFromStart }>{ time.start }</span>

          { time.end.length > 0 && 
          
            <React.Fragment> 
              {` `}- <span className="face__sidebar__time__btn" onClick = { onPlayFromEnd }>{ time.end }</span>
            </React.Fragment>
            
          }

        </span>

        <span className = "face_back" onClick = { onPlayFromStart }>{ timeDiff }</span>

      </div>

      <div className = "border-bottom face__sidebar__name">

        <Editable
          id = { id }
          text = { nameValue }
          oldText = { name }
          onClick = { onPlayFromStart }
          handler = { people.rename }
          type = "input"
        >
          <input
            className = { "text-field__input" }
            type = "text"
            name = "EmployeeInput"
            value = { nameValue }
            onChange = { e => setName( e.target.value ) }
            autoComplete = { 'off' }
            autoFocus = { true }
          />
        </Editable>

        <span className = "face_back">Пол: { +sex === 1 ? "мужской" : +sex === 2 ? "женский" : "неизвестен" }</span>

      </div>

      <div className = "face__sidebar__post" onClick = { onPlayFromStart }>
        
        <span className = "face_front" onClick = { onPlayFromStart }>{ category }</span>
        <span className = "face_back">{ role?.length > 0 ? `Роль: ${ role }` : `` }</span>
          
      </div>

    </div>

  </div>

)};

export default Face;