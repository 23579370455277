import * as popup from "./popups";
import * as sign from "./sign";
import * as folders from "./folders";
import * as events from "./events";
import * as cameras from "./cameras";
import * as exportDB from "./export.db";
import * as models from "./models";
import * as workers from "./workers";
import * as users from "./users";
import * as stats from "./stats";
import * as wallet from "./wallet";
import * as visitors from "./visitors";
import * as people from "./people";
import * as video from "./video";
import * as userMarkups from "./user.markups";

async function changeState(store, state, value) {
  store.setState({ [state]: value });
}

async function changeStates(store, states) {
  store.setState(states);
}

async function changeObjectState(store, object, state, value) {
  store.setState({
    [object]: {
      ...store.state[object],
      [state]: value,
    },
  });
}

export {
  changeState,
  changeStates,
  changeObjectState,
  popup,
  sign,
  folders,
  events,
  cameras,
  models,
  workers,
  users,
  stats,
  people,
  wallet,
  visitors,
  video,
  exportDB,
  userMarkups,
};
