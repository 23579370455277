export const showPopup = async (store, popupName, options = {}) => {
  try {
    const random = Math.random(1, 9999);

    store.setState({
      popup: {
        show: true,
        name: popupName,
        ...options,
      },
      popupRandom: random,
    });
  } catch (error) {
    alert(`Error ${error}`);
  }
};

export const hidePopups = async store => {
  try {
    const random = Math.random(1, 9999);

    store.setState({
      popup: {
        show: false,
        name: "",
      },
      popupRandom: random,
    });
  } catch (error) {
    alert(`Error ${error}`);
  }
};

export const resetSelector = async store => {
  // store.setState({
  //   detailsMode: "default",
  //   detailsSelectorList: "loading",
  //   detailsSelectorPage: 1,
  //   detailsSelectorTotal: -1,
  //   detailsSelectorSearch: "",
  //   timeselectorSelectedDays: []
  // });
};
