import { useEffect, useState } from "react";
import "./tooltip.scss";
import ReactDOM from "react-dom";

const Tooltip = ({
  ChildrenRef,
  text = "",
  Offset = { left: 0, top: 0 },
  className = "tooltip-wrapper",
  animationDirection = "bottom",
  ...props
}) => {
  // TODO add position
  props = {
    onCriticalPositionOffset: props.onCriticalPositionOffset || 320,
    CriticalPosition: props.CriticalPosition || 90,
    ...props,
  };
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  // props
  // OnCriticalPositionOffset -> The offset of the element when reaching a position in more than 90% of the screen width (int)
  // CriticalPosition -> The position of the element when reaching a position in more than YOUR_VALUE% of the screen width (int)

  const handleMouseOver = () => {
    if (!show) {
      let { top, left } = ReactDOM.findDOMNode(
        ChildrenRef.current
      ).getBoundingClientRect();
      if (left > window.innerWidth * (props.CriticalPosition / 100)) {
        left = left - props.onCriticalPositionOffset;
      }
      setPosition({ top, left });
    }
    setShow(true);
  };

  useEffect(() => {
    let handler = e => {
      if (!ChildrenRef?.current?.contains(e.target)) {
        setShow(false);
      } else {
        handleMouseOver();
      }
    };

    document.addEventListener("mousemove", handler);

    return () => {
      document.removeEventListener("mousemove", handler);
    };
  });

  return (
    <div className={className}>
      {show && (
        <div
          className="tooltip"
          style={{
            top: position.top + Offset.top,
            left: position.left + Offset.left,
            pointerEvents: "none",
            animation: `tooltip-animation-${animationDirection} 0.35s ease`,
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
