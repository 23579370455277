function originalScale({ mainContainer, ignoreIds = [] }) {
  var App = document.getElementById(mainContainer);
  const dpr = devicePixelRatio === 2 ? "1.25" : devicePixelRatio;

  if (window.innerWidth >= 1225) {
    App.style.zoom = 1 / dpr;

    if (ignoreIds.length > 0) {
      for (let ignoringScaleBlock of ignoreIds) {
        const el = document.getElementById(ignoringScaleBlock);
        // console.log(`document.getElementById(${ ignoringScaleBlock }) = `, el);
        if (el) {
          el.style.zoom = dpr;
        }
      }
    }
  } else {
    App.style.zoom = 1;

    for (let ignoringScaleBlock of ignoreIds) {
      const el = document.getElementById(ignoringScaleBlock);
      // console.log(`document.getElementById(${ ignoringScaleBlock }) = `, el);
      if (el) {
        el.style.zoom = "unset";
      }
    }
  }

  function refreshScale() {
    console.log(`refreshScale`);
    originalScale({
      mainContainer: mainContainer,
      ignoreIds: ignoreIds,
    });
  }

  var resizeTimeout;

  window.onresize = function () {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(refreshScale, 100);
  };
}

try {
  if (window) {
    document.window.originalScale = originalScale;
  }
} catch (err) {}

export default originalScale;
