import React, { useEffect } from 'react';
import useGlobal from '../../../store';
import TableHead from '../../components/table.head';
import './visitors.scss';

import LoadingSpinner from '../../components/loader';
import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';
import TableVisitors from "../../components/table.visitors";

const VisitorsView = ({}) => {

  const [ globalState, globalActions ] = useGlobal();
  const { 

    visitorTable,
    visitorTableLoaded

  } = globalState;

  const { popup, visitors } = globalActions;

  const [ Dummy, reloadDummy ] = useReloadDummy();

  useEffect(() => { visitors.all( reloadDummy ) }, []);

  const TABLE_HEAD = [
    {
      name: "Идентификация",
      sort: false,
      // action: () => handleSort("alphabetical")
    },
    {
      name: "Классификация",
      sort: false,
      // action: () => handleSort("checkinsCount")
    },
    {
      name: "Черный список",
      sort: false,
      // action: () => handleSort("createdAt")
    },
    {
      name: "Белый список",
      sort: false
    },
    {
      name: "Дата создания",
      sort: false
    },
    {
      name: "Действия",
      sort: false
    }

  ]

  return (

    <div className="admin-container">

      <div className="admin admin-cameras">

        <TableHead

          title = "Посетители"
          action = { () => popup.showPopup('visitorsAdd') }

        />

        <Dummy />

        { ! visitorTableLoaded
          
          ? <LoadingSpinner />
          
          : <TableVisitors

              className = "cameras"
              template = "158px 1fr 130px 130px 207px 155px"
              columns = { TABLE_HEAD }
              rows = {  visitorTable }
              total = {  visitorTable.length }
              edit = { ( id ) => {popup.showPopup( "visitorsEdit", { id } )}}
              remove = { ( id ) => { visitors.remove(id) } }
              loadMore = { () => {} } 
              openModal = { () => {} }
              
            />

        }

      </div>

    </div>

  );

}

export default VisitorsView;