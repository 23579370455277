const timeZones = [
  "Москва-01:00 - Калининград",
  "Москва+00:00 - Москва/Казань",
  "Москва+01:00 - Самара/Ижевск",
  "Москва+02:00 - Уфа/Пермь",
  "Москва+03:00 - Челябинск/Омск",
  "Москва+04:00 - Новосибирск/Барнаул",
  "Москва+05:00 - Бурятия/Иркутск",
  "Москва+06:00 - Якутск/Благовещенск",
  "Москва+07:00 - Владивосток/Хабаровск",
  "Москва+08:00 - Магадан/Анадырь",
  "Москва+12:00 - Камчатка",
];

export default timeZones;
