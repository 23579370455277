import React from 'react';
import './table.head.scss';

const TableHead = ({ title, action = false, btnText = "Добавить", btnIcon = "add", AdditionalButton = false }) => {
  // additional button это кнопка, которая может быть рядом с основной (например для админов, управление шаблонами)

  return (

    <div className = "table_head">

      <div className = "table_head__title">

        { title }

      </div>

      { action &&

        <button

          className = "table_head__btn"
          onClick= { () => { action() }}

        >

          <div className = {`table_head__btn__icon table_head__btn__icon--${ btnIcon }`} />

          <div className = "table_head__btn__text">

            { btnText }

          </div>

        </button>

      }

      {
        AdditionalButton && <div className={ 'table_head__second_btn' }>
          <AdditionalButton />
        </div>
      }

    </div>

  );

}

export default TableHead;