import axios from "axios";
import API from "../../settings/api";
import cookie from "react-cookies";

export async function addCard(store) {
  try {
    const token = cookie.load("token");
    if (!token) {
      throw `Попытка разлогина из sign.toObject`;
    }

    const { data } = await axios.post(
      API.wallet.addCard,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (!data?.payment_url) {
      throw (`Ссылка на платёжную систему не получена`, data);
    }

    cookie.save("addCard", data.token, { path: "/" });
    window.location = data.payment_url;
  } catch (err) {
    console.error(err);
  }
}

export async function increaseMoney(store, amount) {
  try {
    const token = cookie.load("token");
    if (!token) {
      throw `Попытка разлогина из sign.toObject`;
    }

    const { status, data } = await axios.post(
      API.wallet.increaseMoney,
      { amount },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (!data?.payment_url) {
      throw (`Ссылка на платёжную систему не получена`, data);
    }

    cookie.save("increaseWallet", data.token, { path: "/" });
    window.location = data.payment_url;
  } catch (err) {
    console.error(err);
  }
}
