import React from 'react';
import Section from '../../components/containers/section';
import Face from '../../components/face';

const Faces = () => {

  const FACES_DATA = [
    {
      index: "1",
      name: "Пирогова Мария",
      old: "взрослый",
      sex: "женский",
      category: "сотрудник",
      time_start: "12:05", 
      time_end: "12:08",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },
    {
      index: "2",
      name: "Пирогова Мария",
      old: "взрослый",
      sex: "женский",
      category: "сотрудник",
      time_start: "12:15", 
      time_end: "12:18",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },
    {
      index: "3",
      name: "Пирогова Мария",
      old: "взрослый",
      sex: "женский",
      category: "сотрудник",
      time_start: "13:45", 
      time_end: "13:48",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },
    {
      index: "4",
      name: "Пирогова Мария",
      old: "взрослый",
      sex: "женский",
      category: "сотрудник",
      time_start: "12:05", 
      time_end: "12:08",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },
    {
      index: "5",
      name: "Пирогова Мария",
      old: "взрослый",
      sex: "женский",
      category: "сотрудник",
      time_start: "12:15", 
      time_end: "12:18",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },
    {
      index: "6",
      name: "Пирогова Мария",
      old: "взрослый",
      sex: "женский",
      category: "сотрудник",
      time_start: "13:45", 
      time_end: "13:48",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },
    
  ];

  return (

    <Section scrollable title = "Лица">

      { FACES_DATA.length > 0 && FACES_DATA.map(( item, i ) => (
        
        <Face

          id = { item.id }
          key = { i }
          index = { item.index }
          name = { item.name }
          old = { item.old }
          sex = { item.sex }
          category = { item.category }
          time = {{ start: item.time_start, end: item.time_end }}
          photo = { item.photo }

        />

      ))}
            
    </Section>

  )

} 

export default Faces;