import React from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import "./checkbox.scss";

const Checkbox = ({ value, set = () => {}, text = "", disabled = false }) => (

  <div 
    className = {`checkbox flex items-center ${ cssIf( value, "checked" ) } ${ cssIf( disabled, "disabled" ) }`}
    onClick = { () => set( !value ) }
  >

    <div className = "checkbox__anchor" />

    { text !== "" && <div className = "checkbox__text">{ text }</div> }

  </div>

);

export default Checkbox;