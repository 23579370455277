import React from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import useGlobal from '../../../store';
import Section from '../../components/containers/section';
import FileStructure from '../../components/filestructure';

const Cameras = ( props ) => {

  const { onlyOneCameraMultiselect = false } = props;
  const [ globalState, globalActions ] = useGlobal();
  const {

    selected_locations,
    selected_locations_type_ref,
    locs_with_gosnumber_events_counter,
    locations_camera,
    realtime_camera,

    treeZeroFolderId,

    allowCarPlateFilter

  } = globalState;

  const { changeStates, folders } = globalActions;

  function updateSelectedLocations( id, is_counter, type_of_events, is_stats, have_cars, camera_id = false ) {
    // is_counter -> true - счетчик, false - иной тип событий
    // type_of_events -> тип событий, строка
    let gosnumber_in_events = locs_with_gosnumber_events_counter

    let temp = [];
    let type_refs = {};  // Пояснение в Global -> (selected_locations_type_ref)
    if ( !onlyOneCameraMultiselect || locations_camera === camera_id ) { temp = selected_locations; }

    if (is_stats !== undefined){ type_refs = selected_locations_type_ref }

    const pos = selected_locations.indexOf( id );

    if (is_stats !== undefined){

      if (temp.length > 0 && (type_refs[temp[temp.length - 1]] !== undefined && type_refs[temp[temp.length - 1]] !== is_counter) ){

        temp = []

      }
    }

    if (pos === -1){
      temp.push( id )

      if ([ "CARBOX", "CARCOUNT"].includes(type_of_events)){

        gosnumber_in_events += 1

      }

      if (is_stats !== undefined){ type_refs[id] = is_counter; }
    }

    else{

      if ( is_stats !== undefined ){ delete type_refs[temp[pos]] }

      temp.splice( pos, 1 );

      if ( [ "CARBOX", "CARCOUNT" ].includes(type_of_events)){

        gosnumber_in_events -= 1

      }

    }

    console.log(`updSelLocs temp=`, temp);

    const random = Math.random(1,9999);

    let updated = {

      popupRandom: random,
      selected_locations: temp,
      selected_location_type_ref: type_refs,
      locs_with_gosnumber_events_counter: gosnumber_in_events,
      realtime_camera: "",
      realtime_watch: "",
      allowCarPlateFilter: have_cars && allowCarPlateFilter

    }

    if ( onlyOneCameraMultiselect ) { temp.locations_camera = camera_id; }

    changeStates( updated );

  }

  function startRealTimePlaying( id, camera, url ) {

    changeStates({

      selected_locations: [],
      video: "",
      video_start: "",
      se_location_name: `${ camera } - прямой эфир`,
      realtime_camera: id,
      realtime_watch: url,

    });

  }

  return (

    <Section 

      scrollable 
      title = "Камеры" 
      className = {`p-0 filestructure ${ cssIf( props.disabled, "disabled" ) }`}
      tip = { props.disabled ? "Дождитесь загрузки всех графиков" : null }
      { ...props }  
    >

      <FileStructure

        zeroID = { treeZeroFolderId }
        is_stats = {props.disabled}
        add = { folders.add }
        move = { folders.move }
        rename = { folders.rename }
        remove = { folders.remove }
        realtime_camera = { realtime_camera }
        allIsOpened = { props?.allIsOpened }
        hideRealtime = { props?.hideRealtime }
        setLocation = { updateSelectedLocations }
        setRealtime = { startRealTimePlaying }

      />
            
    </Section>

  )

} 

export default Cameras;