import React, { useEffect } from "react";
import useGlobal from "../../../store";
import TableHead from "../../components/table.head";
import "./ModelsConfigurationsPage.scss";

import LoadingSpinner from "../../components/loader";
import useReloadDummy from "../../../scripts/hooks/use.reload.dummy";
import Table from "../../components/table";

const ModelsConfigurationsView = () => {
  const [globalState, globalActions] = useGlobal();
  const { modelsConfTable, modelsConfTableLoaded, user_type } = globalState;

  const { models } = globalActions;

  const [Dummy, reloadDummy] = useReloadDummy();

  useEffect(() => {
    models.configurations(reloadDummy);
  }, []);

  const reload = () => {
    models.configurations(reloadDummy);
  };

  const isAdmin = user_type < 2;

  const TABLE_HEAD = [
    {
      name: "Название",
      sort: true,
      // action: () => handleSort("alphabetical")
    },
    {
      name: "Название модели",
      sort: true,
    },
    {
      name: "Комментарий",
      sort: true,
    },
    {
      name: "Тип модели",
      sort: true,
    },
    {
      name: "Действия",
      sort: false,
    },
  ];

  function switchToEdit(id) {
    window.location.href = "/modelsManager?id=" + id;
  }

  return (
    <div className="models-container">
      <div className="models models-records">
        <TableHead
          title="Конфигурации моделей"
          action={() => (window.location.href = "/modelsManager")}
          // AdditionalButton={ isAdmin && AdminTemplateButton }
        />

        <Dummy />

        {!modelsConfTableLoaded ? (
          <LoadingSpinner />
        ) : (
          <Table
            className="models"
            template="160px 210px 250px 185px 100px 155px"
            columns={TABLE_HEAD}
            rows={modelsConfTable}
            total={modelsConfTable.length}
            remove={id => {
              models.removeConfiguration(id, reload);
            }}
            edit={id => {
              switchToEdit(id);
            }}
            loadMore={() => {}}
            openModal={() => {}}
          />
        )}
      </div>
    </div>
  );
};

const AdminTemplateButton = () => {
  // Компонент для дополнительной кнопки в шапке таблицы (для админов)
  return (
    <div className="models_admin">
      <button
        className="models_admin__btn"
        onClick={() => {
          window.location.href = "/template_creator";
        }}
      >
        <div className={`models_admin__btn__icon models_admin__btn__icon--add `} />
        <div className="models_admin__btn__text">Создать форму</div>
      </button>
    </div>
  );
};

export default ModelsConfigurationsView;
