import React from "react";

const Controls = ({ 

  name, 
  speed,
  action,
  isPlaying,
  isRealTime

}) => {

  return (

    <div className="video_controls flex justify-between items-center py-10">

      <div className="video_controls__source">{ name }</div>
      
      { !isRealTime && 
          
        <React.Fragment>
          
          <div className="video_controls__buttons flex">

            <Begin onClick = { action.begin } />
            <Back onClick = { action.back } />

            { isPlaying

              ? <Pause onClick = { action.pause } /> 
              : <Play onClick = { action.play } />
            }

            <Forward onClick = { action.forward } />
            <End onClick = { action.end } />
          
          </div>

          <div 
            className = "video_controls__speed flex items-center" 
            onClick = { !isRealTime && action.changeSpeed }
          >

            <div className="video_controls__speed__title">Скорость:</div>
            <div className="video_controls__speed__value">{ speed }</div>
          
          </div>

        </React.Fragment>
          
      }

    </div>

  );

}

export default Controls;

const Play = ({ onClick }) => (

  <svg 
    onClick={onClick}
    width="38" 
    height="38" 
    viewBox="0 0 38 38" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"

  >
    
    <g clipPath="url(#clip0_289_1360)">
      
      <path d="M12.458 4.34646L29.7496 18.9451L12.458 33.5438L12.458 18.9451L12.458 4.34646Z" fill="#C4C4C4"/>
    
    </g>

    <defs>

      <clipPath id="clip0_289_1360">

        <rect width="38" height="38" fill="white"/>

      </clipPath>

    </defs>

  </svg>


);

const Pause = ({ onClick }) => (

  <svg onClick={onClick} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="11" y="5" width="5" height="28" fill="#C4C4C4"/>
  <rect x="22" y="5" width="5" height="28" fill="#C4C4C4"/>
  </svg>

);

const Forward = ({ onClick }) => (

  <svg onClick={onClick} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_289_1361)">
    <path d="M20.2762 8.05498L33.8245 19.4934L20.2762 30.9318L20.2762 19.4934L20.2762 8.05498Z" fill="#C4C4C4"/>
    <path d="M6.28096 8.05498L19.8293 19.4934L6.28096 30.9318L6.28096 19.4934L6.28096 8.05498Z" fill="#C4C4C4"/>
    </g>
    <defs>
    <clipPath id="clip0_289_1361">
    <rect width="38" height="38" fill="white"/>
    </clipPath>
    </defs>
  </svg>

);

const Back = ({ onClick }) => (

  <svg onClick={onClick} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_289_1364)">
    <path d="M17.5484 30.9318L4.00004 19.4934L17.5484 8.05499L17.5484 19.4934L17.5484 30.9318Z" fill="#C4C4C4"/>
    <path d="M31.5436 30.9318L17.9953 19.4934L31.5436 8.05499L31.5436 19.4934L31.5436 30.9318Z" fill="#C4C4C4"/>
    </g>
    <defs>
    <clipPath id="clip0_289_1364">
    <rect width="38" height="38" fill="white"/>
    </clipPath>
    </defs>
  </svg>

);

const Begin = ({ onClick }) => (

  <svg onClick={onClick} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10.9993" y="30.971" width="1.99931" height="22.9921" transform="rotate(-180 10.9993 30.971)" fill="#C4C4C4"/>
    <path d="M25.5519 30.9318L12.0036 19.4934L25.5519 8.05499L25.5519 19.4934L25.5519 30.9318Z" fill="#C4C4C4"/>
  </svg>

);

const End = ({ onClick }) => (

  <svg onClick={onClick} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_289_1362)">
    <rect x="25.8168" y="8.01578" width="1.99931" height="22.9921" fill="#C4C4C4"/>
    <path d="M11.2642 8.05498L24.8126 19.4934L11.2642 30.9318L11.2642 19.4934L11.2642 8.05498Z" fill="#C4C4C4"/>
    </g>
    <defs>
    <clipPath id="clip0_289_1362">
    <rect width="38" height="38" fill="white"/>
    </clipPath>
    </defs>
  </svg>

);
