import React from "react";

const PanelButton = ({
  startDate,
  selectedDate,
  endDate,
  showCalendar,
  showCalendarCb,
  setchoiceType,
  checkDate,
}) => {
  return (
    <div className="panel-button-select" onClick={showCalendarCb}>
      <div>
        {typeof startDate}, {typeof endDate}
        {startDate}
      </div>

      {endDate && startDate !== endDate && (
        <React.Fragment>
          <div>{`-`}</div>

          <div>{endDate}</div>
        </React.Fragment>
      )}

      <div className={"panel-button-select-icon"}>
        {!startDate ? (
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 9H6V11H4V9ZM4 13H6V15H4V13ZM8 9H10V11H8V9ZM8 13H10V15H8V13ZM12 9H14V11H12V9ZM12 13H14V15H12V13Z"
              fill="#68E4FF"
            />
            <path
              d="M2 20H16C17.103 20 18 19.103 18 18V4C18 2.897 17.103 2 16 2H14V0H12V2H6V0H4V2H2C0.897 2 0 2.897 0 4V18C0 19.103 0.897 20 2 20ZM16 6L16.001 18H2V6H16Z"
              fill="#68E4FF"
            />
          </svg>
        ) : (
          <svg
            onClick={checkDate}
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_2:1062)">
              <path
                d="M4.52429 11.946C5.44193 13.0563 7.56061 15.3447 9.08163 18.3802C9.55662 19.3281 11.5233 19.3389 11.9195 18.3554C13.6636 14.0258 16.7705 8.13743 21.024 4.44603"
                stroke="#68E4FF"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_2:1062"
                x="0.524292"
                y="0.445984"
                width="24.4998"
                height="22.6461"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_2:1062"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_2:1062"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        )}
      </div>
    </div>
  );
};

export default PanelButton;
