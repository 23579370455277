import "./timeline.scss";

const ChartTimeLine = ({ content = [], className = "" }) => (

  <div className = {`chart_timeline relative flex items-center ${ className }`}>

    { content.length > 0 && content.map(( step, key ) => (

      <Time

        key = { key }
        value = { step.value || step.day || step.time }
        offset = { step.offset }

      />

    ))}

  </div>

);

const Time = ({ value, offset }) => (

  <div 

    className = "chart_timeline__time relative"
    style = {{ left: `${ offset }%` }}

  >

    <div className="absolute">{ value }</div>

  </div>

);

export default ChartTimeLine;