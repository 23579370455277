import React from 'react';

import { Popup, popupClose } from '../index';

import Button from '../../components/button';

export const PaymentResult = ({ mode }) => {

  const TEXT = {

    cardSuccess: "Карта успешно привязана",
    cardFail: "Ошибка привязки карты",
    balanceSuccess: "Баланс счёта успешно пополнен",
    balanceFail: "Ошибка при пополнении баланса счёта"

  }

  return (

    <Popup className = "popup_user">

      <div className="popup__title">{ TEXT[ mode ] }</div>

      <div className = "popup_user__controls flex justify-center">

        <Button

          text = "ОК"
          action = { popupClose }
          color = "orange"

        />

      </div>

    </Popup>

  );

}