import './popup.export.scss';

import React, { useEffect, useState, useRef } from 'react';
import { add as addExport, addPeople }        from '../../../store/actions/export.db';
import { setAll as getPeople }                from '../../../store/actions/people'
import { all as getLocations }                from '../../../store/actions/locations';
import { Popup, popupClose }                  from '../index';
import useGlobal                              from '../../../store';
import DateFieldAutocomplete                  from "../../components/datepicker/datefieldautocompl";
import DateStringToDateTime                   from "../../../scripts/helpers/DateStringToDateTime";
import PopupTab                               from "../../components/popup.tab";
import Selectfield                            from '../../components/selectfield';
import Textfield                              from '../../components/textfield';
import Textarea                               from '../../components/textarea';
import Button                                 from '../../components/button';
import Multiselectfield                       from "../../components/multiselectfield";
import DMY_TO_YMD_format                      from "../../../scripts/helpers/DMY_TO_YMD_format";
import CurrentYear                            from "../../../scripts/helpers/CurrentYear";
import GenerateYears                          from "../../../scripts/helpers/GenerateYears";
import CurrentTextMonthRU                     from "../../../scripts/helpers/CurrentTextMonthRU";
import ruMonthNumberAlias                     from "../../../scripts/helpers/storage/ruMonthNumberAlias";
import DaysInMonth                            from "../../../scripts/helpers/DaysInMonth";
import TwoDigitFormat                         from "../../../scripts/helpers/two.digit.format";


// Проверяет правильность введеной даты и соответствию формату -> "dd-mm-yy"
function isValidDate(MonthString)
{
  // Первичная проверка по паттерну

  if(!/^\d{1,2}-\d{1,2}-\d{2}$/.test(MonthString))  return false;

  // Разбиение
  let parts = MonthString.split("-");
  let day = parseInt(parts[0], 10);
  let month = parseInt(parts[1], 10);
  let year = parseInt(parts[2], 10);

  // Проверяем принадлежность пределам
  if(year < 0 || year > 99 || month === 0 || month > 12)  return false;

  let monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  // Високосные года тоже нужно обрабатывать
  if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Не выходит ли день за рамки количества суток в введенном месяце
  return day > 0 && day <= monthLength[month - 1];
}

export const ExportModalAdd = () => {

  const [ globalState, globalActions ]  = useGlobal()

  const reload = () => {

    globalActions.exportDB.all();
    popupClose();

  }

  return (

    <ExportModal  // Popup экспорта

      mode = "add"
      func = { ( form ) => {

        addExport( form, reload );  // POST запрос добавления записи на бэк

      }}

      funcPeople = { ( form ) => {

        addPeople( form, reload ) // POST запрос добавления записи на бэк

      }}

    />

  );

}


export const ExportModal = ( props ) => {

  const TITLE = {
    add: "Добавление новой записи",
  }
  const FUNC_BTN_TEXT = {
    add: "Добавить",
  }

  const dateEndField = useRef();
  const commentField = useRef();

  const [ PeopleSectionSelected, setPeopleSectionSelected ] = useState(false)

  const [ LocationsSectionSelected, setLocationSectionSelected ] = useState(true)

  const [ LocationSelected,  setLocationSelected  ] = useState( '' );  // LocationS потому что раньше был множественный выбор локаций
  const [ PeopleSelected, setPeopleSelected ] = useState([])

  const [ name, setName ] = useState( "" );
  const [ exportFormat, setExportFormat ] = useState( "" );

  const [ dateBegin, setdateBegin ] = useState( "" );
  const [ dateBeginAutocomplCount, setdateBeginAutocomplCount ] = useState( 0 );
  const [ dateEnd, setdateEnd ] = useState( "" );
  const [ dateEndAutocomplCount, setdateEndAutocomplCount ] = useState( 0 );

  const [ MonthString, setMonthString ] = useState( "" );
  const [ yearSelected, setYearSelected] = useState("")

  const [ comment, setComment ] = useState( "" );

  const [ locations, setLocation ] = useState([])  // Список локаций которые можно будет выбрать в Popup-е
  const [ people, setPeople ] = useState([])  // Список сотрудников которых можно будет выбрать в Popup-е

  // Варианты эскпорта данных (статичны, к бд не привязываются, передаются строкой)
  const export_formats = [
    'CSV',
    'Excel'
  ]

  const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ]

  useEffect(() => {

    getLocations( setLocation )
    getPeople( setPeople )

  }, []);




  useEffect(() => { // autoComplete dateBegin

    DateFieldAutocomplete(

      dateBegin,
      dateBeginAutocomplCount,
      setdateBegin,
      setdateBeginAutocomplCount,
      () => dateEndField?.current?.focus()

    );

  }, [ dateBegin ]);

  useEffect(() => { // autoComplete dateEnd

    DateFieldAutocomplete(

      dateEnd,
      dateEndAutocomplCount,
      setdateEnd,
      setdateEndAutocomplCount,
      () => commentField?.current?.focus()

    );

  }, [ dateEnd ]);

  let formIsValidated = (

    name !== "" && exportFormat !== "" && ((dateBegin !== "" && dateEnd !== "") || (MonthString !== "" && yearSelected !== "")) && LocationSelected !== ''

  );

  function sendForm() {

    const DateValid = !PeopleSectionSelected ? isValidDate(dateBegin) && isValidDate(dateEnd): true
    if (!DateValid) { return alert('Данные о дате начала/конца указаны неверно.') }

    const Start = DateStringToDateTime(dateBegin).toJSON()
    const End = DateStringToDateTime(dateEnd).toJSON()

    const LocationSelectedId = LocationSelected.id

    let form = {

      name,
      export_format: exportFormat,
      Start,
      End,
      comment,
      LocationSelected: LocationSelectedId

    }

    if ( PeopleSectionSelected ) {
      let month = ruMonthNumberAlias[MonthString]
      let year = yearSelected.toString().substring(2)

      let tempDateBegin = `01-${TwoDigitFormat(month)}-${year}`
      let tempDateEnd = `${DaysInMonth(month, year)}-${TwoDigitFormat(month)}-${year}`

      form.people = PeopleSelected;
      form.Start = DMY_TO_YMD_format(tempDateBegin)
      form.End = DMY_TO_YMD_format(tempDateEnd)

      if (PeopleSelected.length === 0){
        return alert('Сотрудники не выбраны!')
      }

      return props.funcPeople( form )
    }

    console.log( `sendForm:`, form );
    props.func( form );

  }

  function SelectAllPeople() { setPeopleSelected(people) }
  function ClearAllPeople() { setPeopleSelected([]) }

  return (

    <Popup className = "popup_export">

      <div className={'popup_export__tab flex items-center'}>
        <PopupTab

          action = { () => { setLocationSectionSelected(true); setPeopleSectionSelected(false) } }

          color= { LocationsSectionSelected ? 'orange' : 'gray'}

          text = "Локации"

        />

        <PopupTab

          action = { () => { setPeopleSectionSelected(true); setLocationSectionSelected(false); } }

          color= { PeopleSectionSelected ? 'orange' : 'gray'}

          text = "Сотрудники"

        />
      </div>

      <div className="popup__title">{ TITLE[ props.mode ]} экспорта { props?.name && `- ${ props.name }` }</div>

      <div className="flex">

        <div className="popup_export__inputs">

          {!PeopleSectionSelected ?

          <div className="popup_export__inputs__row popup_export__inputs__row-1 flex items-center">

            <Selectfield

              title = "Выбор формата экспорта"
              object = "Формат экспорта"
              list = { export_formats }
              selected = { exportFormat }
              select = { setExportFormat }

            />

              < Textfield
              title = "Начало"
              placeholder = "01-09-23"
              value = { dateBegin }
              set = {setdateBegin}

              />

              <Textfield

              title = "Завершение"
              placeholder = "01-10-23"
              value = { dateEnd }
              set = { setdateEnd }
              refDOM = { dateEndField }

              />
          </div>
            : <div className="popup_export__inputs__row popup_export__inputs__row-2 flex items-center">

              <Selectfield

                title = "Выбор формата экспорта"
                object = "Формат экспорта"
                list = { [export_formats[1]] }
                selected = { exportFormat }
                select = { setExportFormat }

              />

              <Selectfield

                list = { months }
                title = "Месяц"
                placeholder = { CurrentTextMonthRU() }
                selected = { MonthString }
                select = { setMonthString }

              />

              <Selectfield

                list = { GenerateYears() }
                title = "Год"
                placeholder = { CurrentYear() }
                selected = { yearSelected }
                select = { setYearSelected }

              />
            </div>}


          <div className="popup_export__inputs__row popup_export__inputs__row-len flex items-center">



          </div>
          <div className="popup_export__inputs__row popup_export__inputs__row-2 flex items-center">

            <Textarea

              title = "Комментарий"
              placeholder = "Описание записи"
              value = { comment }
              set = { setComment }
              refDOM = { commentField }

            />

          </div>

          <div className="popup_export__inputs__row popup_export__inputs__row-3 flex items-center">

            <Textfield

              title = "Название записи"
              placeholder = "Экспорт за декабрь"
              value = { name }
              set = { setName }

            />


          </div>


          <div className = "flex">

            <Button

              text = { FUNC_BTN_TEXT[ props.mode ]}
              action = { sendForm }
              disabled = { !formIsValidated }

            />

            <Button

              text = "Отмена"
              action = { popupClose }
              color = "gray"

            />

          </div>

        </div>

        <div className = "popup_export__column">

          <div className = "popup__area">

            <Selectfield
              list = { locations }
              title = "Локации"
              object = "Локация"
              selected = { LocationSelected }
              select = { ( c ) => { setLocationSelected( c ) }}
            />

            {
              PeopleSectionSelected &&
              <div>
              <Multiselectfield
                list = { people }
                title = "Сотрудники"
                object = "Локация"
                selected = { PeopleSelected }
                select = { ( c ) => { setPeopleSelected( c ) }}
              />
              <div className = "flex">
             <Button
                text = "Выбрать всех"
                action = { SelectAllPeople }
              />
              <Button
                  text = "Очистить"
                  action = { ClearAllPeople }
              />
                </div>
              </div>}


          </div>

        </div>



      </div>

    </Popup>

  );

}