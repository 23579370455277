import React, { useState, useEffect, useRef } from 'react';
import cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
import Section from '../../components/containers/section';
import { Grid, Col } from '../../components/containers/grid';

import Events from './events';
import Cameras from './cameras';
import Faces from './faces';
import Play from './play';
import DateTime from './datetime';
import SimillarFaces from './faces.simillar';

import useGlobal from '../../../store';
import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';
import WebSockets from '../../components/websockets';
import MobileBottomBar from '../../components/mobile.bottom.bar';
import useDebouncedFunction from '../../../scripts/hooks/use.debounce';
import getAdaptiveVariant from '../../../scripts/helpers/get.adaptive';
import PlayCenterButton from "../../components/playCenterButton";



const Panel = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { popup } = globalActions;

  const refShowCalendar = useRef( false );

  const navigate = useNavigate();

  const {

    selected_locations,
    se_id,
    se_location_name,
    se_have_faces,
    se_have_simillar_faces,
    se_have_numbers,
    video,
    realtime_watch,
    full_video_link,
    full_video_location_name,
    allowCarPlateFilter

  } = globalState;

  const [ Dummy, reloadDummy ] = useReloadDummy();

  const { video: GetVideo } = globalActions;

  const [ gridTemplateCols, setGridTemplateCols ] = useState("18% 1fr");
  const [ section, setSection ] = useState("tree");
  const [ adaptive, setAdaptive ] = useState( getAdaptiveVariant() );
  const [ isNotMobile, setIsNotMobile ] = useState( false );
  const [ showMobileCalendar, setShowMobileCalendar ] = useState( false );

  useEffect(() => {
    // Независимая от событий загрузка ссылки на видео, чтобы можно было смотреть видео по нажатию на новую кнопку в центре справа
    if ( selected_locations.length > 0 && full_video_link === ""){

      GetVideo.full()

    }
  }, [ selected_locations.length  ])

  useEffect(() => { setupGrid(); updateWidth(); console.log(`selected_locations.length:`, selected_locations.length); }, [ selected_locations.length, se_id, se_have_faces, se_have_numbers, realtime_watch ]);
  useEffect(() => { reloadDummy() }, [ gridTemplateCols ]);

  useEffect(() => {

    window.location.search.indexOf( 'goto=' ) !== -1 && setSection( window.location.search.split('=')[ 1 ] );

    const paymentStatus = window.location.search.indexOf( 'payment-status=' ) !== -1 ? window.location.search.split('=')[ 1 ] : false;


    if ( paymentStatus ) {

      if ( cookie.load('increaseWallet') ) {

        popup.showPopup( paymentStatus === "success" ? "balanceSuccess" : "balanceFail" );
        cookie.remove("increaseWallet");

      } else if ( cookie.load('addCard') ) {

        popup.showPopup( paymentStatus === "success" ? "cardSuccess" : "cardFail" );
        cookie.remove("addCard");

      }

    }

    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);

  }, []);

  const updateWidth = useDebouncedFunction(() => {

    const _adaptive = getAdaptiveVariant();

    setAdaptive( _adaptive )
    setIsNotMobile( _adaptive !== "mobile" )
    setupGrid( _adaptive );

  }, 500 );

  const GRID = {

    desktopExtra: { cameras: "18%", events: " 21%", finded: " 16%", player: " 1fr" },
    desktopBig: { cameras: "18%", events: " 22%", finded: " 16%", player: " 1fr" },
    desktopMedium: { cameras: "18%", events: " 23%", finded: " 16%", player: " 1fr" },
    desktopSmall: { cameras: "18%", events: " 24%", finded: " 16%", player: " 1fr" },
    laptop: { cameras: "280px", events: " 400px", finded: " 200px", player: " 1fr" }

  }

  function setupGrid( adpt = false ) {

    const _adaptive = adpt || adaptive;

    let template = "";

    if ( _adaptive !== "mobile" ) {

      template = GRID[ _adaptive ].cameras;

      if ( selected_locations.length > 0 ) { template = template + GRID[ _adaptive ].events; }
      if ( se_have_faces || se_have_numbers ) { template = template + GRID[ _adaptive ].finded; }

      template = template + ` 1fr`;

    } else {

      template = "unset";

    }

    console.log(`setupGrid template=`,template);

    setGridTemplateCols( template );

  }

  useEffect( () => { // Т.к в Debounced Function обнуляются по неизвестной мне причины данные с GlobalState'а,
    // пришлось сделать useEffect для проверки типа карточки события для корректной адаптации интерфейса

    if ( ["17.5%", "20%", "25%", "27.5%"].includes(gridTemplateCols.split(' ')[1]) && allowCarPlateFilter){
      // Другая сетка для автомобилей (они чуток шире)
      const newTemplate = gridTemplateCols.replace("17.5%", '18%').replace('25%', '25.5%').replace("27.5%", "28%")
      console.log('[INFO] Rescaled:', newTemplate)
      setGridTemplateCols(newTemplate)
    }
  }, [ gridTemplateCols ])



  return (

    <React.Fragment>

      <WebSockets />

      <Dummy />

      <Grid cols = { gridTemplateCols } className="main_panel">

        <Col visible = { isNotMobile ? true : section === "tree" }>

          {( !isNotMobile || !refShowCalendar.current ) &&

            <svg
              onClick={() => {
                setShowMobileCalendar( true );
              }}
              className={"mobile-calendar"}
              width="43"
              height="43"
              viewBox="0 0 43 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="21.5"
                cy="21.5"
                r="20.9487"
                fill="#333333"
                stroke="#6A6A6A"
                strokeWidth="1.10256"
              />
              <path
                d="M16.7775 19.5H18.6664V21.3889H16.7775V19.5ZM16.7775 23.2778H18.6664V25.1667H16.7775V23.2778ZM20.5552 19.5H22.4441V21.3889H20.5552V19.5ZM20.5552 23.2778H22.4441V25.1667H20.5552V23.2778ZM24.333 19.5H26.2219V21.3889H24.333V19.5ZM24.333 23.2778H26.2219V25.1667H24.333V23.2778Z"
                fill="#68E4FF"
              />
              <path
                d="M14.8889 29.8889H28.1111C29.1528 29.8889 30 29.0417 30 28V14.7778C30 13.7361 29.1528 12.8889 28.1111 12.8889H26.2222V11H24.3333V12.8889H18.6667V11H16.7778V12.8889H14.8889C13.8472 12.8889 13 13.7361 13 14.7778V28C13 29.0417 13.8472 29.8889 14.8889 29.8889ZM28.1111 16.6667L28.1121 28H14.8889V16.6667H28.1111Z"
                fill="#68E4FF"
              />
            </svg>

          }

          {( isNotMobile || ( !isNotMobile && showMobileCalendar) ) &&

            <DateTime

              calendarOpened = { showMobileCalendar }
              showMobileCalendar = { showMobileCalendar }
              setShowMobileCalendar = { setShowMobileCalendar }
              refShowCalendar = { refShowCalendar }

            />

          }

          <Cameras />

          { isNotMobile &&

            <Section title = "Отображать" maxHeight = "30%">



            </Section>

          }

        </Col>


        {( selected_locations.length > 0 ) &&

          <Col visible = { isNotMobile ? true : section === "events" }>

            <Events gotoPlayer = { () => !isNotMobile && setSection("player") } />

          </Col>

        }

        {( ( isNotMobile || section === "player" ) && ( se_have_faces || se_have_numbers ) ) &&

          <Col>

            <Faces />
            {/*// TODO or numbers */}

          </Col>

        }

        {( ( video !== "" || realtime_watch !== "" ) ) && //( isNotMobile || section === "player" ) &&

          <Col visible = { isNotMobile ? true : section === "player" }>

            { se_have_simillar_faces && <SimillarFaces /> }
            <Play />

          </Col>


        }
        {(selected_locations.length > 0 && (se_id !== null && se_location_name === "" && full_video_link !== ""))  &&

          <div className={'panel__grid__column flex flex-col playVideo'}>

            <PlayCenterButton action={() => { // TODO Имя локации
              globalActions.events.select(null, full_video_link, full_video_location_name, '00:00:00' );  // id, video-url, video-timecode
              !isNotMobile && setSection("player");
            }

            }
            />
          </div>}

      </Grid>
      

      { adaptive === "mobile" &&

        <MobileBottomBar

          current = { section }
          navigate = { ( to ) => to !== "stats" ? setSection( to ) : navigate( '/'+to ) }

        />

      }

    </React.Fragment>

  );

}

export default Panel;