import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../loader';
import "./load.more.scss";

const LoadMore = ({ action, stateFromProps = false, isLoading = null, textInitial = "Показать больше", textLoading = "Загружаю..." }) => {

  const [ loading, setLoading ] = useState( false );

  function handleLoad() {

    if (( stateFromProps && !isLoading ) || !loading ) {

      action();
      setLoading( true );
      setTimeout( () => { setLoading( false ) }, 4000 );
    
    }

  }
  
  return (

    <div 
      className="load_more" 
      onMouseOver={ () => ( stateFromProps ? !isLoading : !loading ) && handleLoad() }
      style={{ cursor: ( stateFromProps ? isLoading : loading ) ? 'progress' : 'pointer' }}
    >

      {( stateFromProps ? isLoading : loading ) ? <LoadingSpinner /> : textInitial }

    </div>

  );

}

export default LoadMore;