import React from 'react';
import "./textfield.scss";

const Textfield = ({ value, set, title = "", type = "text", placeholder = "", refDOM = false, disabled = false }) => (

  <div className="textfield">

    { title !== "" && <div className="textfield__title">{ title }</div> }

    <input

      type = { type }
      value = { value }
      placeholder = { placeholder }
      onChange = { ( event ) => { set( event.target.value ) }}
      ref = { refDOM ? refDOM : null }
      disabled = { disabled }

    />

  </div>

);

export default Textfield;