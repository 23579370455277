import React, { useState } from 'react';
import cssIf from '../../../../scripts/helpers/class.add.if';
import { ReactComponent as FullScreenBtn } from "./icons/fullscreen.svg";

import "./section.scss";

const GridLikeSection = ({
  
  id = "", 
  scrollable_id = "", 
  title,
  scrollable = false,
  fullscreen = false,
  tip = false,
  minWidth = "unset", 
  minHeight = "unset", 
  maxHeight = "unset",
  className = "",
  gridTemplateColumns = "",
  children 

}) => {

  const [ fullScreenMode, setFullScreenMode ] = useState( false );

  return (

    <section

      id = { id }
      className = {`grid_like_section ${ className } ${ cssIf( scrollable, "grid_like_section--scrollable" ) } ${ cssIf( fullScreenMode, "fullscreen" ) }`}
      style = {{ 

        minWidth: minWidth,
        minHeight: minHeight,
        maxHeight: maxHeight

      }}
      title = { tip ? tip : null }
      onClick = { () => ( fullscreen && fullScreenMode ) && setFullScreenMode( false ) }

    >

      <div className = "title">{ title }</div>

      { !scrollable ? children : <Scrollable id = { scrollable_id } GTC = {gridTemplateColumns}>{ children }</Scrollable> }

      { fullscreen && 
      
        <FullScreenBtn 
          
          className = "fs_btn" 
          onClick = { () => setFullScreenMode( true ) } 
          title = "Развернуть на весь экран"
          
        /> 
        
      }
      
    </section>

  );

}

const Scrollable = ({ id = "", children, GTC }) => ( <div id = { id } style = {{ gridTemplateColumns: GTC }} className = "grid_like_section_scrollable">{ children }</div> );

export default GridLikeSection;