import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

export async function add({ camera, model, file, comment }) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из add wrapper`;
  }

  const params = {
    token,
    camera,
    model,
    file,
    comment,
  };

  try {
    const { status, data } = await axios.post(API.wrappers.add, params);
    if (status !== 200 || !data.id) {
      throw data.text;
    }

    console.log("Разметка успешно добавлена");
    return data.id;
  } catch (err) {
    console.error(`Не удалось добавить разметку:`, err);
  }
}

export async function edit({ id, camera, model, file, comment }) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из edit wrapper`;
  }

  const params = {
    token,
    camera,
    id,
    model,
    file,
    comment,
  };

  try {
    const { status, data } = await axios.post(API.wrappers.edit, params);
    if (status !== 200) {
      throw data.text;
    }

    console.log("Разметка успешно отредактирована");
  } catch (err) {
    console.error(`Не удалось отредактировать разметку:`, err);
  }
}

export async function remove(id, camera) {
  const token = cookie.load("token");
  if (!token) {
    throw `Попытка разлогина из edit wrapper`;
  }

  const params = {
    token,
    id,
    camera,
  };

  try {
    const { status, data } = await axios.post(API.wrappers.remove, params);
    if (status !== 200) {
      throw data.text;
    }

    console.log("Разметка успешно удалена");
  } catch (err) {
    console.error(`Не удалось удалить разметку:`, err);
  }
}
