import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const Inner = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: linear-gradient(
      0deg,
      #2a2a2a 25%,
      #323232 25%,
      #323232 50%,
      #2a2a2a 50%,
      #2a2a2a 75%,
      #323232 75%,
      #323232 100%
    );
    background-size: 160px 160px;
  }
`;

export const TreeWrapper = styled.div`
  position: relative;
  left: -17px;

  /* > * > *:before {
    display: none;
  } */
`;

export const Node = styled.div`
  padding-left: 20px;
  position: relative;
  overflow: hidden;
  height: ${({ isCollapsed }) => (!isCollapsed ? "40px" : "auto")};

  /* Vertical tree line */
  &:before {
    position: absolute;
    content: ${({ hasVerticalLine }) => (hasVerticalLine ? '""' : "none")};
    background-color: #4a4a4a;
    z-index: -1;
    width: 1px;
    height: calc(100% - 44px);
    top: 25px;
    left: 29px;
  }
`;

export const NodeRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 30px;
  cursor: pointer;
  position: relative;
  background-color: ${({ isReadyToDrop }) =>
    isReadyToDrop ? "#FF820E55" : "transparent"};

  /* Horizontal tree line */
  &:before {
    position: absolute;
    content: "";
    height: 1px;
    width: 12px;
    background-color: #4a4a4a;
    top: 15px;
    left: -10px;
    z-index: -1;
  }
`;

export const Arrow = styled.div`
  width: 30px;
  height: 39px;
  display: flex;
  align-items: center;

  > * {
    margin-left: 1px;
  }
`;

export const ArrowFiller = styled.div`
  width: 20px;
  height: 30px;
`;

export const ArrowFillerRealtime = styled.div`
  width: 1px;
`;

export const CheckboxWrapper = styled.div`
  margin-left: 4px;
`;

export const NodeName = styled.div`
  width: 100%;

  input {
    cursor: ${({ disabled }) => (disabled ? "pointer" : "text")};
    background-color: ${({ disabled }) => (disabled ? "transparent" : "#fff")};
    border: 0;
    color: ${({ disabled }) => (disabled ? "#aaa" : "#333")};
    font-size: inherit;
    padding-top: 0;
    padding-bottom: 0;
    outline: 0;
    width: calc(100% - 10px);
  }
`;

export const NoData = styled.div`
  margin-left: 20px;
`;

export const IconWrapper = styled.div`
  pointer-events: auto;
  background-color: transparent;
  width: 40px;
  margin-right: ${({ margin_right }) => (margin_right ? margin_right : "12px")};
  margin-bottom: ${({ margin_bottom }) => (margin_bottom ? margin_bottom : "0")};
  margin-top: ${({ margin_top }) => (margin_top ? margin_top : "0")};
  display: flex;
  justify-content: center;
  padding-bottom: ${({ padding_bottom }) => (padding_bottom ? padding_bottom : "0")};
`;

export const WorkerName = styled.div`
  width: 100%;

  input {
    cursor: ${({ disabled }) => (disabled ? "pointer" : "text")};
    background-color: ${({ disabled }) => (disabled ? "transparent" : "#fff")};
    border: 0;
    color: ${({ disabled }) => (disabled ? "#aaa" : "#333")};
    font-size: inherit;
    padding-top: 0;
    padding-bottom: 0;
    outline: 0;
    width: calc(100% - 10px);
  }
`;
