import useGlobal from "../../../store";
import Textfield from "../../components/textfield";
import { useEffect, useState } from "react";
import "./templateCreatorPage.scss";

const TemplateGenerator = () => {
  // const [ form, setForm ] = useState({});
  //
  // const [ globalState, globalActions ] = useGlobal();

  return (
    <div className={"template-generator"}>
      <div className={"template-generator__add__section"}>
        <button onClick={() => {}} className={"template-generator__add__section__btn"}>
          <div
            className={
              "template-generator__add__section__btn__icon template-generator__add__section__btn__icon--add"
            }
          />

          <div className={"template-generator__add__section__btn__text"}>
            Добавить секцию
          </div>
        </button>
      </div>
    </div>
  );
};

const TemplateCreatorPage = () => {
  const [globalState, globalActions] = useGlobal();

  const { models } = globalActions;

  const [templateName, setTemplateName] = useState("");
  const [comment, setComment] = useState("");

  // get id param from url
  const ConfigurationId = window.location.href.includes("?id")
    ? window.location.href.split("?")[1].split("=")[1]
    : null;

  useEffect(() => {
    ConfigurationId && models.getConfiguration(ConfigurationId);
  }, [ConfigurationId]);

  return (
    <div className="models-manager-container">
      <div className={"models-manager-form-header"}>
        <div className={"models-manager-form-header-item"}>
          <Textfield
            value={templateName}
            title={"Название шаблона"}
            set={setTemplateName}
          />
        </div>
        <div className={"models-manager-form-header-item"}>
          <Textfield value={comment} title={"Комментарий"} set={setComment} />
        </div>
      </div>

      <div className={"horizontal-line-box"}>
        <div className={"horizontal-line"} />
      </div>
      <TemplateGenerator />
    </div>
  );
};

export default TemplateCreatorPage;
