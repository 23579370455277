import GlobalHook from "use-global-hook";
import twoDigitFormat from "../scripts/helpers/two.digit.format";

import * as actions from "./actions";

const today = new Date();

const initialState = {
  popup: {
    show: false,
    name: "",
    id: "",
    // show: true,
    // name: "cameraAdd"
  },
  popupRandom: "",
  wsRandom: "",

  user_name: "",
  user_type: 4,
  balance: 0,
  monthly: 0,
  card: false,
  permissions: {
    ignoreCameras: [],
    can_change_event_status: false,
  },
  isLoadingTree: true,
  tree: [],
  treeZeroFolderId: null,
  object_have_faces: false, // Для показа вкладки "Лица"

  location: "",
  selected_locations: [],
  selected_locations_type_ref: {}, // Словарь с референсами локаций и их типов (счетчик/не_счетчик)
  selected_people: [],
  is_cleared: false,
  locations_camera: "",

  // Страница "Конфигурации моделей"

  modelsTemplates: [],
  modelsTemplatesLoaded: false,

  modelTemplate: {},
  modelTemplateLoaded: false,

  selectedModel: "",
  selectedTemplate: "",
  modelConfigurationName: "",

  ConfigurationName: "",
  SelectedModel: "",

  ConfigurationId: "",
  modelConfiguration: {},
  modelConfigurationLoaded: false,

  // selected_locations hints

  locs_with_gosnumber_events_counter: 0,

  eventsListLoaded: false,
  eventsListMoreLoading: true,
  eventsList: [],
  eventsNowList: [],
  eventsOffset: 0,
  eventsCounter: 0,
  eventsMode: "increase",

  startDate: `${twoDigitFormat(today.getDate())}.${twoDigitFormat(
    today.getMonth() + 1
  )}.${today.getFullYear()}`,
  endDate: `${twoDigitFormat(today.getDate())}.${twoDigitFormat(
    today.getMonth() + 1
  )}.${today.getFullYear()}`,
  startTime: "00:00",
  endTime: "23:59",

  allowCarPlateFilter: true,

  filter_important: true,
  filter_second: true,
  filter_approved: true,
  filter_notapproved: true,
  filter_actual: true,
  filter_removed: false,
  filter_duration_min: "",
  filter_duration_max: "",
  filter_gosnumber: "",
  filter_person_name: "",

  //se = selected event
  se_id: "",
  se_location_name: "",
  se_have_faces: false,
  se_have_simillar_faces: false,
  se_have_numbers: false,

  playing_time: "",

  PeopleList: [], // Список людей
  PeopleListSearched: false,
  PeopleListLoaded: false,

  selected_face: false,
  selected_face_photos: [],
  selected_face_photosLoaded: false,

  facesMode: "nameSort",
  faces_filter_employees: true,
  faces_filter_visitors: true,
  faces_filter_unknown: false,

  EmployeeCategories: [], // Список категорий людей

  EmployeeRoles: [], // Список ролей людей

  video: "",
  video_start: "",
  full_video_link: "",
  full_video_location_name: "",
  realtime_camera: "",
  realtime_watch: "",

  camerasTable: [],
  camerasTableLoaded: false,

  //stats
  locationsColorsIsLoaded: false,
  locationsColors: [],

  locationsRatingIsLoaded: false,
  locationsRating: [],

  countEventsIsLoaded: false,
  countTodayEvents: "",
  countAllEvents: "",

  chartOneIsLoaded: false,
  chartOne: [],
  chartOneTimeline: [],

  chartTwoIsLoaded: false,
  chartTwoRequestID: "",
  chartTwo: [],
  chartTwoTimeline: [],

  chartThreeIsLoaded: false,
  chartThree: {},
  chartThreeTime: {},

  chartFourIsLoaded: false,
  chartFourRequestID: "",
  chartFour: [],
  chartFourTimeline: [],

  exportTable: [],
  exportTableLoaded: false,

  visitorTable: [],
  visitorTableLoaded: false,

  FacilityEmployeeList: [], // Список сотрудников объекта (в статистике)
  FacilityEmployeeListLoaded: false,
  FacilityEmployeeListSearched: false, // Был ли произведен поиск по сотрудникам объекта

  modelTemplateMarkups: [],
};

const useGlobal = GlobalHook(initialState, actions);

export default useGlobal;
