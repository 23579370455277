import CurrentYear from "./CurrentYear";

const GenerateYears = (endYear = CurrentYear(), amount = 5) => {
  const result = [];
  let tempYear = endYear;

  for (let i = 0; i < amount; i++) {
    result.push(tempYear);
    tempYear -= 1;
  }

  return result;
};

export default GenerateYears;
