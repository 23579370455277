import React, { useState } from "react";
import "./editable.css";

const Editable = ({
  id,
  text,
  type,
  placeholder,
  children,
  handler,
  onClick,
  oldText,
  ...props
}) => {
  const [isEditing, setEditing] = useState(false);

  const handleKeyDown = (event, type) => {
    const { key } = event;
    const keys = ["Escape", "Tab"];
    const enterKey = "Enter";
    const allKeys = [...keys, enterKey];
    if (
      (type === "textarea" && keys.indexOf(key) > -1) ||
      (type !== "textarea" && allKeys.indexOf(key) > -1)
    ) {
      setEditing(false);
      if (text !== oldText) handler(id, text);
    }
  };

  return (
    <section {...props}>
      {isEditing ? (
        <div
          onBlur={() => {
            setEditing(false);
            handler(id, text);
          }}
          onKeyDown={e => handleKeyDown(e, type)}
        >
          {children}
        </div>
      ) : (
        <div onClick={onClick} onDoubleClick={() => setEditing(true)}>
          <span>{text || placeholder || "Editable content"}</span>
        </div>
      )}
    </section>
  );
};

export default Editable;
