import React from 'react';
import cssIf from '../../../../scripts/helpers/class.add.if';
import "./grid.scss";

export const Grid = ({ mode, cols, children, className = "" }) => {

  return (

    <div
    
      className = {`panel__grid panel__grid--${ mode } ${ className }`} 
      style = {{ gridTemplateColumns: cols }}
      
    >

      { children }

    </div>

  );

}

export const Col = ({ children, visible = true, className = "" }) => (

  <div className={`panel__grid__column flex flex-col ${ className } ${ cssIf( visible, `panel__grid__column--show`)}`}>

    { children}

  </div>

);