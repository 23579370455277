import React, { useState, useEffect } from "react";

import useCalendar, { Column } from "./useCalendar";
import Modal from "./modal";
import CalendarTable from "./CalendarTable";
import DateChoice from "./DateChoice";
import DateChoiceMobile from "./DateChoiceMobile";
import PanelButton from "./PanelButton";
import TimeBtn from "./TimeBtn";

import "./datepicker.scss";
import twoDigitFormat from "../../../scripts/helpers/two.digit.format";
import date_to_days from "../../../scripts/helpers/date_to_days";
import timeFieldShortAutocomplete from "./timefieldautocompl";

const DatePicker = (props) => {

  const {

    calendarRows,
    selectedDate,
    todayFormatted,
    daysShort,
    monthNames,
    getNextMonth,
    getPrevMonth,
    getPrevYear,
    getNextYear,

  } = useCalendar();

  const [selectDay, setSelectDay] = useState("не выбрана");
  const [choiceType, setchoiceType] = useState("start");

  const {

    startDate,
    endDate,
    startTime,
    endTime,
    setStartDate,
    setEndDate,
    setStartTime,
    setEndTime
  
  } = props;
  const [ timeStartAutocomplCount, setTimeStartAutocomplCount ] = useState( 0 );
  const [ timeEndAutocomplCount, setTimeEndAutocomplCount ] = useState( 0 );

  const [hover, setHover] = React.useState(1);
  const [showCalendar, setshowCalendar] = React.useState( props?.calendarOpened );

  useEffect(() => {

    if (props.start || props.end) {

      setStartDate(props.start);
      setEndDate(props.end);
    }

  }, [props.start, props.end]);

  useEffect(() => {

    if (endDate) {
      !props?.calendarOpened && setshowCalendar(false);
      // props?.setShowMobileCalendar( false )
    }

  }, [endDate]);

  // useEffect(() => {

  //   setshowCalendar( props.refShowCalendar.current )

  // }, [props.refShowCalendar.current]);

  // useEffect(() => {

  //   props.refShowCalendar.current = showCalendar;

  // }, [showCalendar]);

  const dateClickHandler = ( dates ) => {

    const date = `${ twoDigitFormat(dates.split(".")[0]) }.${ twoDigitFormat(dates.split(".")[1]) }.${ dates.split(".")[2] }`;
    
    // const date = dates;
    if (startDate && endDate) {

      setStartDate(null);
      setEndDate(null);
      setStartDate(date);
      return setchoiceType("end");

    }

    setSelectDay(date);

    if (startDate && date < Number(startDate)) {
      setStartDate(date);
      return setchoiceType("end");

    }

    if (endDate && date > endDate) {
      setEndDate(date);
      return setchoiceType("end");

    }

    if (choiceType === "start") {
      setStartDate(date);
      return setchoiceType("end");

    } else {
      if (date_to_days(date) < date_to_days(startDate)){
        setEndDate(startDate);
      }
      else{
        console.log(date_to_days(startDate), endDate, date_to_days(date), date_to_days(date) < date_to_days(startDate))
        setEndDate(date);
      }
      // props?.setShowMobileCalendar( false )

    }

  }

  function checkBetween( date ) {

    let _date;
    let _start;

    if ( date.indexOf("-") !== -1 ) {

      _date = {
        d: parseInt( date.split("-")[0] ),
        m: parseInt( date.split("-")[1] ),
        y: parseInt( date.split("-")[2] )
      }

    } else {

      _date = {
        d: parseInt( date.split(".")[0] ),
        m: parseInt( date.split(".")[1] ),
        y: parseInt( date.split(".")[2] )
      }

    }

    if ( startDate.indexOf("-") !== -1 ) {

      _start = {
        d: parseInt( startDate.split("-")[0] ),
        m: parseInt( startDate.split("-")[1] ),
        y: parseInt( startDate.split("-")[2] )
      }

    } else {

      _start = {
        d: parseInt( startDate.split(".")[0] ),
        m: parseInt( startDate.split(".")[1] ),
        y: parseInt( startDate.split(".")[2] )
      }

    }

    let result = false;

    if ( startDate && !endDate ) {

      if (

        (
          ( 
            _date.d >= _start.d
            && 
            _date.m === _start.m
            && 
            _date.y === _start.y
          )
          || 
          ( 
            _date.m > _start.m
            &&
            _date.y === _start.y
          )
          || 
          ( 
            _date.y > _start.y
          )

        ) && _date.d <= hover

      ) { result = true; }
      
    } else {

      let _end;

      if ( endDate.indexOf("-") !== -1 ) {

        _end = {
          d: parseInt( endDate.split("-")[0] ),
          m: parseInt( endDate.split("-")[1] ),
          y: parseInt( endDate.split("-")[2] )
        }
  
      } else {
  
        _end = {
          d: parseInt( endDate.split(".")[0] ),
          m: parseInt( endDate.split(".")[1] ),
          y: parseInt( endDate.split(".")[2] )
        }
  
      }
      
      if (

        ( 
          ( 
            ( _date.d >= _start.d ) 
            && 
            _date.m === _start.m 
            && 
            _date.y === _start.y 
          ) 
          && 
          ( 
            _date.d <= _end.d 
            && 
            _date.m === _end.m 
            && 
            _date.y === _end.y 
            
          ) 
        )
        || 
        ( 
          ( 
            _date.y === _start.y 
            && 
            ( 
              _date.m > _start.m 
              || 
              ( 
                _date.m === _start.m 
                && 
                _date.d >= _start.d 
              ) 
            ) 
          ) 
          && 
          (
            ( 
              _date.m === _end.m 
              && 
              _date.d <= _end.d 
            ) 
            ||
            ( 
              _date.m < _end.m 
              && 
              _date.y === _end.y 
            ) 
          ) 
        )
        || 
        ( 
          ( _date.y > _start.y ) 
          && 
          ( 
            ( 
              _date.y === _end.y 
              && 
              ( 
                ( _date.m < _end.m ) 
                || 
                ( 
                  _date.m === _end.m 
                  &&
                  _date.d <= _end.d 
                ) 
              ) 
            ) 
            || 
            ( _date.y < _end.y ) 
          ) 
        )

      ) { 
        
        result = true; 
      
      }

    }

    return result;

  }

  const [modalActive, setmodalActive] = useState(false);

  function showCalendarCb() {

    !props?.calendarOpened && setshowCalendar(true);

  }

  function checkDate() {

    if (startDate && endDate) {

      setEndDate(null);

    }

  }
  useEffect( () => {
    timeFieldShortAutocomplete(
        startTime,
        timeStartAutocomplCount,
        setStartTime,
        setTimeStartAutocomplCount,
    )
  }, [ startTime ])
  useEffect( () => {
    timeFieldShortAutocomplete(
        endTime,
        timeEndAutocomplCount,
        setEndTime,
        setTimeEndAutocomplCount,
    )
  }, [ endTime ])

  return (
    <>
      <Modal active={modalActive} setactive={setmodalActive}>
        <div className="modal-body">
          <div className="container-panel-btn">

            <h3 style={{ color: "#fff" }}>

              startDate { startDate }<br/>
              endDate { endDate }

            </h3>

            <div className="container-panel-btn-m">
              <PanelButton
                showCalendarCb={showCalendarCb}
                startDate={startDate}
                selectedDate={selectedDate}
                endDate={endDate}
                showCalendar={showCalendar}
                checkDate={checkDate}
              />
            </div>
          </div>

          <div>
            <TimeBtn time1={props.time1} time2={props.time2} />
          </div>
          <div className="date-choice">
            <DateChoiceMobile
              monthNames={monthNames}
              selectedDate={selectedDate}
              getPrevMonth={getPrevMonth}
              getNextMonth={getNextMonth}
              getPrevYear={getPrevYear}
              getNextYear={getNextYear}
            />
          </div>
          <div className="calendar-table">
            <CalendarTable
              daysShort={daysShort}
              calendarRows={calendarRows}
              startDate={startDate}
              endDate={endDate}
              dateClickHandler={dateClickHandler}
              setHover={setHover}
              checkBetween={checkBetween}
            />
          </div>
        </div>
      </Modal>

      <div
        className="panel-button-select"
        onClick={() => !props?.calendarOpened && setshowCalendar(!showCalendar)}
      >

        <div onClick={() => setchoiceType("start")}>
          { startDate }
        </div>

        {( endDate && ( startDate !== endDate )) && 

          <React.Fragment>

            <div>{"-"}</div>

            <div onClick={() => setchoiceType("end")}>
              {endDate}
            </div>

          </React.Fragment>

        }
          
        <div className={"panel-button-select-icon"}>

          { ( !showCalendar || props?.showMobileCalendar ) ? (

            <svg
              onClick={() => !props?.calendarOpened && setshowCalendar(false)}
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >

              <path
                d="M4 9H6V11H4V9ZM4 13H6V15H4V13ZM8 9H10V11H8V9ZM8 13H10V15H8V13ZM12 9H14V11H12V9ZM12 13H14V15H12V13Z"
                fill="#68E4FF"
              />

              <path
                d="M2 20H16C17.103 20 18 19.103 18 18V4C18 2.897 17.103 2 16 2H14V0H12V2H6V0H4V2H2C0.897 2 0 2.897 0 4V18C0 19.103 0.897 20 2 20ZM16 6L16.001 18H2V6H16Z"
                fill="#68E4FF"
              />

            </svg>

          ) : (

            <svg
              onClick={checkDate}
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >

              <g filter="url(#filter0_d_2:1062)">

                <path
                  d="M4.52429 11.946C5.44193 13.0563 7.56061 15.3447 9.08163 18.3802C9.55662 19.3281 11.5233 19.3389 11.9195 18.3554C13.6636 14.0258 16.7705 8.13743 21.024 4.44603"
                  stroke="#68E4FF"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />

              </g>

              <defs>

                <filter
                  id="filter0_d_2:1062"
                  x="0.524292"
                  y="0.445984"
                  width="24.4998"
                  height="22.6461"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >

                  <feFlood floodOpacity="0" result="BackgroundImageFix" />

                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />

                  <feOffset />

                  <feGaussianBlur stdDeviation="1" />

                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />

                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2:1062"
                  />

                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2:1062"
                    result="shape"
                  />

                </filter>

              </defs>

            </svg>

          )}
          
        </div>

      </div>

        { !props.onlyDate &&

          <TimeBtn 
            time1={startTime} 
            time2={endTime} 
            setTime1={setStartTime} 
            setTime2={setEndTime} 
          />
          
        }

        {(showCalendar || props.showMobileCalendar ) && (
          <div className="calendar">
            <DateChoice
              monthNames={monthNames}
              selectedDate={selectedDate}
              getPrevMonth={getPrevMonth}
              getNextMonth={getNextMonth}
              getPrevYear={getPrevYear}
              getNextYear={getNextYear}
            />
            <CalendarTable
              daysShort={daysShort}
              calendarRows={calendarRows}
              startDate={startDate}
              endDate={endDate}
              dateClickHandler={dateClickHandler}
              setHover={setHover}
              checkBetween={checkBetween}
            />
          </div>
        )}

        {/* {!showCalendar &&

          <svg
            onClick={() => {
              // setmodalActive(true);
              setshowCalendar(true);
            }}
            className={"mobile-calendar"}
            width="43"
            height="43"
            viewBox="0 0 43 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="21.5"
              cy="21.5"
              r="20.9487"
              fill="#333333"
              stroke="#6A6A6A"
              strokeWidth="1.10256"
            />
            <path
              d="M16.7775 19.5H18.6664V21.3889H16.7775V19.5ZM16.7775 23.2778H18.6664V25.1667H16.7775V23.2778ZM20.5552 19.5H22.4441V21.3889H20.5552V19.5ZM20.5552 23.2778H22.4441V25.1667H20.5552V23.2778ZM24.333 19.5H26.2219V21.3889H24.333V19.5ZM24.333 23.2778H26.2219V25.1667H24.333V23.2778Z"
              fill="#68E4FF"
            />
            <path
              d="M14.8889 29.8889H28.1111C29.1528 29.8889 30 29.0417 30 28V14.7778C30 13.7361 29.1528 12.8889 28.1111 12.8889H26.2222V11H24.3333V12.8889H18.6667V11H16.7778V12.8889H14.8889C13.8472 12.8889 13 13.7361 13 14.7778V28C13 29.0417 13.8472 29.8889 14.8889 29.8889ZM28.1111 16.6667L28.1121 28H14.8889V16.6667H28.1111Z"
              fill="#68E4FF"
            />
          </svg>

        } */}
    </>
  );
};

export default DatePicker;
