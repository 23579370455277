function timeFieldShortAutocomplete(value, counter, setValue, setCounter, jump = false) {
  if (value.length === 2 && counter < 1) {
    setValue(value + ":");
    setCounter(1);
  } else if (value.length < 3) {
    setCounter(0);
  } else if (value.length === 3 && value.substr(-1, 1) !== ":") {
    setValue(`${value.substr(0, value.length - 1)}:${value.substr(-1, 1)}`);
  }
}

export default timeFieldShortAutocomplete;
