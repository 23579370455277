import { Wrapper, NativeCheckbox, Box } from "./styled";
import { Component } from "react";

class CheckboxInTree extends Component {
  render() {
    const {
      checked,
      value,
      children,
      onChange = () => {},
      extended = false,
    } = this.props;
    return (
      <Wrapper>
        <NativeCheckbox checked={checked} onChange={onChange} value={value} />

        {extended ? (
          <Box hasNoChildren={!children} width={"20px"} height={"20px"} />
        ) : (
          <Box hasNoChildren={!children} />
        )}
      </Wrapper>
    );
  }
}

export default CheckboxInTree;
