import React, { useEffect, useState, useRef } from 'react'
import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';
import useGlobal from '../../../store';
import CheckboxInTree from '../checkbox.in.tree';

import { ReactComponent as FolderNew } from './img/folder-new.svg';
import { ReactComponent as Trash } from './img/trash.svg';

import {

  getIcon,
  transformRawJSONToAddUniqueIds,
  renameNodeInTreeByUniqueId,
  moveNode,
  sortTree,
  generateUniqueId,
  insertGivenNodeIntoNodeWithGivenUniqueId

} from './helpers';

import {

  Wrapper, Inner, TreeWrapper, Node, NodeRow, CheckboxWrapper,
  Arrow, ArrowFiller, ArrowFillerRealtime, NodeName, NoData, IconWrapper,

} from './styled';

import { v4 as uuidv4 } from 'uuid';

import "./fs.scss";

const FileStructure = ( props ) => {

  const {

    zeroID,
    is_stats,
    add, 
    move, 
    rename, 
    remove,
    realtime_camera,
    setLocation,
    setCamera = () => {},
    setRealtime 
  
  } = props;
  const [ globalState, globalActions ] = useGlobal();
  const { isLoadingTree, selected_locations } = globalState;

  const [ Dummy, reloadDummy ] = useReloadDummy();

  const [ tree, setTree ] = useState([]);
  const [ expandedNodes, setExpandedNodes ] = useState([]);

  const handleExpandCollapse = (e) => {
    !expandedNodes.includes(+e.currentTarget.dataset.uniqueId)
      ? setExpandedNodes([ ...expandedNodes, +e.currentTarget.dataset.uniqueId ])
      : setExpandedNodes(expandedNodes.filter((x) => x !== +e.currentTarget.dataset.uniqueId))
  }

  const getAndSetTree = () => globalActions.folders.getTree(( fs ) => { setTree( transformRawJSONToAddUniqueIds( fs ) ) } );

  useEffect(() => { getAndSetTree() }, []);
  useEffect(() => { reloadDummy() }, [ selected_locations ]);
 
  const [ renamingNowUniqueId, setRenamingNowUniqueId ] = useState()
  const [ renamingNowValue, setRenamingNowValue ] = useState('')
  const treeWrapperRef = useRef(null)

  const handleStartRenameNode = (e) => {

    setRenamingNowUniqueId(+e.currentTarget.dataset.uniqueId)
    setRenamingNowValue(e.currentTarget.dataset.value)

    if ( treeWrapperRef.current ) {

      const input = treeWrapperRef.current.querySelector(`input[data-unique-id="${e.currentTarget.dataset.uniqueId}"]`);
      if ( input ) setTimeout( () => input.focus(), 100 );

    }

  }

  const handleChangeNodeName = ( e ) => setRenamingNowValue( e.target.value );

  const catchControlKeysWhenEditNodeName = (e) => {

    if ( e.key === 'Enter' ) {

      let newTree;

      newTree = renameNodeInTreeByUniqueId( 
        
        tree, 
        +e.currentTarget.dataset.uniqueId, 
        renamingNowValue
        
      );

      newTree = sortTree( newTree );

      setTree( newTree );

      rename(

        e.currentTarget.dataset.type, 
        e.currentTarget.dataset.id, 
        renamingNowValue 
      
      );
      
    }

    if ( e.key === 'Enter' || e.key === 'Escape' ) undoRenaming();

  }

  const undoRenaming = () => {

    setRenamingNowUniqueId(undefined)
    setRenamingNowValue('')

  }

  const [ nowDraggingUniqueId, setNowDraggingUniqueId ] = useState()
  const [ nowDraggingId, setNowDraggingId ] = useState()
  const [ nowDraggingType, setNowDraggingType ] = useState()
  const [ nowDragOverUniqueId, setNowDragOverUniqueId ] = useState()
  const [ nowDragOverType, setNowDragOverType ] = useState()
  const [ nowDragOverId, setNowDragOverId] = useState()

  const handleDragStartNodeRow = (e) => {

    setNowDraggingId(e.currentTarget.dataset.id)
    setNowDraggingType(e.currentTarget.dataset.type)

    setNowDraggingUniqueId(+e.currentTarget.dataset.uniqueId)
    setNowDragOverUniqueId(+e.currentTarget.dataset.uniqueId)

  }

  const handleDragEnterNodeRow = (e) => {

    console.log('Поймал', e);
    console.log('Поймал unicID', +e.currentTarget.dataset.uniqueId);

    if (nowDraggingUniqueId !== +e.currentTarget.dataset.uniqueId) {

      setNowDragOverUniqueId(+e.currentTarget.dataset.uniqueId)
      setNowDragOverType(e.currentTarget.dataset.type)
      setNowDragOverId(e.currentTarget.dataset.id)

    } else {

      setNowDragOverUniqueId(nowDraggingUniqueId)

    }

  }

  const handleDragEndNodeRow = () => {

    if ( nowDraggingUniqueId !== nowDragOverUniqueId && nowDragOverType === 'folder' ) {
      
      let newTree;

      // console.log( `new parent id`, nowDragOverId );
      // console.log( `dragging id`, nowDraggingId );
      // console.log( `dragging type`, nowDraggingType );

      newTree = moveNode( tree, nowDraggingUniqueId, nowDragOverUniqueId );
      newTree = sortTree( newTree );

      setTree(newTree)
      
      move(

        nowDraggingType, 
        nowDraggingId, 
        nowDragOverId 
      
      );

    }

    setNowDraggingUniqueId(undefined);
    setNowDraggingId(undefined);
    setNowDraggingType(undefined);
    setNowDragOverUniqueId(undefined);
    setNowDragOverType(undefined);
    setNowDragOverId(undefined);

  }

  const handleAddFolder = () => {

    const new_folder = {

      id: uuidv4(),
      uniqueId: generateUniqueId(),
      type: "folder",
      name: "Новая папка",
      contains: []

    }
    
    add( new_folder.id );

    let newTree = insertGivenNodeIntoNodeWithGivenUniqueId( tree, new_folder, 0 );
    
    setTree( newTree );

  }

  const handleRemoveEmptyFolder = ( id, uniq ) => {

    remove( id );
    let newTree = moveNode( tree, uniq, -1 );
    setTree( newTree );
    reloadDummy();

  }

  const buildTree = ( tree, allIsOpened = false, hideRealtime = false ) => {

    const mappedNodes = tree.map((node) => {

      const isCollapsed = allIsOpened ? true : expandedNodes.includes( node.uniqueId );
      const isFolderOrCamera = node.type === 'folder' || node.type === 'camera';
      const isEvent = node.type === 'events_group';
      const isRealtime = node.type === 'realtime';
      const isPlayingRealtime = node.type === 'camera' && node.id === realtime_camera;
      const isInputDisabled = renamingNowUniqueId !== node.uniqueId;
      const isCanRemoved = node.type === 'folder' && node.contains.length === 0;

      return ( ( hideRealtime && isRealtime ) ? null :

        <Node

          key={node.uniqueId}
          isCollapsed={ isCollapsed}
          hasVerticalLine={isFolderOrCamera}

        >
          <NodeRow

            data-unique-id={node.uniqueId}
            data-id={node.id}
            data-type={node.type}
            className={node.type}
            hasHorizontalLine={isRealtime}
            draggable={isFolderOrCamera}
            isReadyToDrop={nowDragOverUniqueId === node.uniqueId && node.type === 'folder' && nowDragOverUniqueId !== node.parentUniqueId}
            onDragStart={handleDragStartNodeRow}
            onDragEnter={handleDragEnterNodeRow}
            onDragEnd={handleDragEndNodeRow}

          >

            { isFolderOrCamera 
            
              ? (

                  <Arrow

                    onClick={handleExpandCollapse}
                    data-unique-id={node.uniqueId}

                  >

                    <IconWrapper>

                      { getIcon( isCollapsed ? 'arrow_opened' : 'arrow_closed' ) }

                    </IconWrapper>

                  </Arrow>

                ) 
                
              : isEvent 
              
                ? (

                  <CheckboxWrapper onClick = { () => {

                    setLocation( node.id, node.is_counter, node.type_of_events, is_stats, node.have_cars );
                    setCamera( node.camera_id )

                  } }>
                    <CheckboxInTree checked = { selected_locations.indexOf( node.id ) !== -1 } />
                  </CheckboxWrapper>

                ) 
                
                : <ArrowFiller />
            
            }

            <IconWrapper>

              {!isCollapsed && node.type === 'folder' && getIcon('folder')}
              {isCollapsed && node.type === 'folder' && getIcon('folder_opened')}

              {!isPlayingRealtime && node.type === 'camera' && getIcon('camera')}
              {isPlayingRealtime && getIcon('camera_enabled')}

              {node.type !== 'folder' && node.type !== 'camera' && getIcon(node.type)}

            </IconWrapper>

            { isRealtime && <ArrowFillerRealtime /> }
            
            <NodeName

              onClick = { ( e ) =>  {

                if ( node.type === 'events_group' ) {

                  setLocation( node.id, node.is_counter, node.type_of_events, is_stats, node.have_cars );
                  setCamera( node.camera_id );

                } else if ( node.type === 'realtime') {

                  setRealtime( node.id, node.camera, node.url );

                } else if ( isFolderOrCamera ) {

                  handleExpandCollapse( e );

                }

              }}
              onDoubleClick = { handleStartRenameNode }
              data-unique-id = { node.uniqueId }
              data-value = { node.name }
              data-id = { node.id }
              data-type = { node.type }
              disabled = { isInputDisabled }

            >

              <input

                style = {{ 
                  color: (
                    selected_locations.indexOf( node.id ) !== -1
                    || ( node.type === "realtime" && node.id === realtime_camera )
                  ) 
                    ? "#68e4ff" 
                    : null 
                }}
                data-unique-id = { node.uniqueId }
                data-id = { node.id }
                data-type = { node.type }
                value = { (renamingNowUniqueId === node.uniqueId ? renamingNowValue : node.name) }
                onChange = { handleChangeNodeName }
                onKeyDown = { catchControlKeysWhenEditNodeName }
                onBlur = { undoRenaming }
                disabled = { isInputDisabled }

              />

            </NodeName>
              
            { isCanRemoved && 
              
              <Trash

                title = "Удалить папку"
                className = "folder-remove-btn" 
                onClick = { () => handleRemoveEmptyFolder( node.id, node.uniqueId ) }

              /> 
              
            }

          </NodeRow>

          { node.contains ? buildTree( node.contains, allIsOpened, props?.hideRealtime ) : null }
          {/* { node.contains ? buildTree( node.contains, !!node.realtime ) : null } */}

        </Node>

      )

    });

    // if ( node?.url_realtime !== "" ) {

    //   mappedNodes.unshift(

    //     <Node key = { Math.random() }>

    //       <NodeRow hasHorizontalLine>

    //         <ArrowFiller />
    //         <IconWrapper>{ getIcon('realtime') }</IconWrapper>
    //         <ArrowFillerRealtime />
    //         <span>Прямой эфир</span>

    //       </NodeRow>

    //     </Node>

    //   );

    // }

    return mappedNodes

  }

  const zeroUnicId = 0;

  const allIsOpened = props?.allIsOpened || window.innerWidth <= 970;

  return (

    <React.Fragment>

      <div 
        className = "folder_new_btn" 
        onClick = { handleAddFolder }
      >

        <FolderNew title = "Добавить папку" />
        
      </div>

      <Wrapper>

        <Inner className = { !tree.length ? "fs-nodata" : "" }>

          <Dummy />
          
          <TreeWrapper ref = { treeWrapperRef }>

            { tree.length

              ? <React.Fragment>

                  { buildTree( tree, allIsOpened, props?.hideRealtime ) }

                  <Node>

                    <NodeRow

                      data-unique-id={ zeroUnicId }
                      data-id={ zeroID }
                      data-type="folder"
                      draggable={ false }
                      isReadyToDrop={ nowDragOverUniqueId === zeroUnicId }
                      onDragStart={handleDragStartNodeRow}
                      onDragEnter={() => handleDragEnterNodeRow({
                        currentTarget: { 
                          dataset: {
                            uniqueId: zeroUnicId,
                            id: zeroID,
                            type: "folder",
                          }
                        }
                      })}
                      onDragEnd={handleDragEndNodeRow}

                    />
                    
                  </Node>                

                </React.Fragment>

              : <NoData>
                  <div className = "tip">Нет данных</div>
                </NoData>

            }

          </TreeWrapper>

        </Inner>

      </Wrapper>

    </React.Fragment>

  );

}

export default FileStructure;