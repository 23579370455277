import axios from "axios";
import API from "../../settings/api";
import cookie from "react-cookies";

export async function In(store, e, p, redirect, setObjects, showError) {
  try {
    const { status, data } = await axios.post(API.sign.in, {
      email: e,
      password: p,
    });

    console.log(`signIn data:`, data);

    if (status === 200) {
      if (data.success) {
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1); // Установка срока действия куки на один месяц

        cookie.save("token", data.token, { path: "/", expires: expirationDate });

        data?.multiobjects?.length > 0 ? setObjects(data.multiobjects) : redirect();
      } else {
        throw data.text;
      }
    } else {
      throw "Нет соединения с сервером";
    }
  } catch (err) {
    console.error(err);
    showError(err);
  }
}

export async function Check(store, token) {
  try {
    const { status, data } = await axios.post(API.sign.check, { token: token });

    console.log(`check data: ${status}`, data);

    if (status === 200 && data.success) {
      console.log(`data success data id`);

      store.setState({
        user_name: data.user.name,
        user_type: data.user.type,
        object_have_faces: data.user.have_faces,
        balance: data.tariff.balance,
        monthly: data.tariff.monthly,
        card: data.tariff.card,
        permissions: data.permissions,
      });

      return true;
    } else {
      throw "";
    }
  } catch (err) {
    console.error(`Попытка разлогина из Check:`, err);
    store.actions.sign.Out();
    return false;
  }
}

export async function toObject(store, object_id, redirect) {
  try {
    const token = cookie.load("token");
    if (!token) {
      throw `Попытка разлогина из sign.toObject`;
    }

    const { status, data } = await axios.post(API.sign.toObject, {
      token: token,
      object: object_id,
    });

    if (status !== 200) {
      throw (`Не вышло переключиться на объект ${object_id}:`, data?.text);
    }

    redirect();
  } catch (err) {
    console.error(err);
  }
}

export async function Out() {
  try {
    cookie.remove(`token`);
    window.location = "/signin";
  } catch (err) {}
}
