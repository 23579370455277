import ChartTimeLine from '../timeline';
import './month.days.events.time.scss';

const MonthDaysEventsTime = ({ timeline, data = [] }) => (

  <div className="mdet flex flex-col-reverse flex-1">

    <div className="mdet__scrollable flex flex-col-reverse flex-1">

      <ChartTimeLine content = { timeline } />

      <div className="mdet__content flex flex-1 relative">

        { data.length > 0 && data.map(( row, key ) => (

          <Day

            key = { key } 
            date = { row.date }
            data = { row.data }
            offset = { row.offset }
          
          />

        ))}

      </div>

    </div>

  </div>

);

const Day = ({ date, data, offset }) => (

  <div 
    className = "mdet__content__day flex absolute items-end"
    style = {{ left: `${ offset }%` }}
  >

  { data.length > 0 && data.map(( location, key ) => (

    <LocationTimeColumn

      key = { key } 
      tip = {`${ date } - ${ location.eventsTime }`}
      counter = { location.eventsCounter }
      { ...location }

    />

  ))}

  </div>
  
);

const LocationTimeColumn = ({ counter = 0, offset, width, height, color, tip = "" }) => (


  <div 

    className = "mdet__content__time absolute"
    title = { tip }
    style = {{

      width: `${ width }px`,
      height: `${ height}%`,
      left: `${ offset }%`,
      background: color

    }}

  >

    { counter > 0 && <div className = "mdet__content__time__counter">{ counter }</div> }

  </div>
  
);

export default MonthDaysEventsTime;