import HOST from "./host";

const EVENTS = `${HOST}/events`;
const EVENT_TYPES = `${HOST}/event_types`;
const FOLDERS = `${HOST}/folders`;
const CAMERAS = `${HOST}/cameras`;
const MODELS = `${HOST}/models`;
const WORKERS = `${HOST}/workers`;
const LOCATIONS = `${HOST}/locations`;
const WRAPPERS = `${HOST}/wrappers`;
const USERS = `${HOST}/users`;
const EXPORT = `${HOST}/export`;
const STATS = `${HOST}/stats`;
const VISITORS = `${HOST}/visitors`;
const PEOPLE = `${HOST}/people`;
const VIDEO = `${HOST}/video`;
const PAYMENTS = `${HOST}/payments`;
const USER_MARKUPS = `${HOST}/user_markups`;
const EVENT_CATEGORIES = `${HOST}/event_categories`;

const API = {
  sign: {
    in: `${HOST}/signin`,
    check: `${HOST}/userinfo`,
    toObject: `${HOST}/changeobject`,
  },

  folders: {
    tree: `${FOLDERS}/tree`,
    all: `${FOLDERS}/all`,
    add: `${FOLDERS}/add`,
    rename: `${FOLDERS}/rename`,
    move: `${FOLDERS}/move`,
    remove: `${FOLDERS}/remove`,
  },

  events: {
    all: `${EVENTS}/all`,
    modify: `${EVENTS}/modify`,
  },

  video: {
    full: `${VIDEO}/full`,
  },

  eventTypes: {
    all: `${EVENT_TYPES}/all`,
  },

  eventCategories: {
    all: `${EVENT_CATEGORIES}/all`,
  },

  cameras: {
    all: `${CAMERAS}/all`,
    get: `${CAMERAS}/get`,
    add: `${CAMERAS}/add`,
    edit: `${CAMERAS}/edit`,
    remove: `${CAMERAS}/remove`,
    getScreenshot: `${CAMERAS}/screenshot`,
  },

  models: {
    all: `${MODELS}/all`,
    addConfiguration: `${MODELS}/add_configuration`,
    editConfiguration: `${MODELS}/edit_configuration`,
    removeConfiguration: `${MODELS}/remove_configuration`,
    getConfiguration: `${MODELS}/get_configuration`,
    configurations: `${MODELS}/configurations`,
    getTemplate: `${MODELS}/get_template`,
    allTemplates: `${MODELS}/all_templates`,
  },

  workers: {
    all: `${WORKERS}/all`,
  },

  locations: {
    all: `${LOCATIONS}/all`,
    add: `${LOCATIONS}/add`,
    edit: `${LOCATIONS}/edit`,
    remove: `${LOCATIONS}/remove`,
  },

  wrappers: {
    add: `${WRAPPERS}/add`,
    edit: `${WRAPPERS}/edit`,
    remove: `${WRAPPERS}/remove`,
  },

  users: {
    all: `${USERS}/all`,
    get: `${USERS}/get`,
    add: `${USERS}/add`,
    edit: `${USERS}/edit`,
    remove: `${USERS}/remove`,
  },

  stats: {
    colors: `${STATS}/colors`,
    counter: `${STATS}/count`,
    chart1: `${STATS}/chart1`,
    chart2: `${STATS}/chart2`,
    chart3: `${STATS}/chart3`,
    chart4: `${STATS}/chart4`,
    locations_rating: `${LOCATIONS}/rating`,
  },

  export: {
    all: `${EXPORT}/all`,
    add: `${EXPORT}/add`,
    addPeople: `${EXPORT}/add_people`,
    remove: `${EXPORT}/remove`,
  },

  visitors: {
    all: `${VISITORS}/all`,
    add: `${VISITORS}/add`,
    edit: `${VISITORS}/edit`,
    get: `${VISITORS}/get`,
    remove: `${VISITORS}/remove`,
  },

  userMarkups: {
    all: `${USER_MARKUPS}/all`,
    get: `${USER_MARKUPS}/get`,
    add: `${USER_MARKUPS}/add`,
    edit: `${USER_MARKUPS}/edit`,
    child: `${USER_MARKUPS}/child`,
    remove: `${USER_MARKUPS}/remove`,
  },

  people: {
    all: `${PEOPLE}/all`,
    edit: `${PEOPLE}/edit`,
    delete: `${PEOPLE}/delete`,
    allEmployees: `${PEOPLE}/allEmployees`,
    rename: `${PEOPLE}/rename`,
    photos: `${PEOPLE}/photos`,
    categories: `${PEOPLE}/categories`,
    roles: `${PEOPLE}/roles`,
    BestPhoto: `${PEOPLE}/best-photo`,
    deletePhoto: `${PEOPLE}/delete-photo`,
    addPhoto: `${PEOPLE}/add-photo`,
  },

  wallet: {
    addCard: `https://backend-api.info-vision.ru/api/payments/init-subscription`,
    increaseMoney: `https://backend-api.info-vision.ru/api/payments/init`,
  },

  payments: {
    all: `${PAYMENTS}/all`,
    add: `${PAYMENTS}/add`,
    edit: `${PAYMENTS}/edit`,
    remove: `${PAYMENTS}/remove`,
    get: `${PAYMENTS}/get`,
  },
};

export default API;
