import "./popup.people.scss";

import React, { useState } from "react";
import { Popup, popupClose } from "../index";

import Button from "../../components/button";
import useGlobal from "../../../store";
import { addPhoto as Add } from "../../../store/actions/people";
import Dropzone from "react-dropzone";
import cssIf from "../../../scripts/helpers/class.add.if";
import fileConvertBase64 from "../../../scripts/helpers/file.convert.base64";

export async function getNameFromFile(file, set1, set2) {
  const b64 = [];
  for (const f of file) {
    b64.push(await fileConvertBase64(f));
  }
  let result = "";
  file.forEach(f => {
    if (f.size > 1000000) {
      return alert("Размер файла не должен превышать 1Мб");
    }
    result += f.name + ", ";
  });

  set1(result);
  set2(b64);
}

export const FileDropZone = ({
  type = "image/png, image/jpeg, image/jpg",
  action = e => console.log(e),
}) => (
  <Dropzone onDrop={acceptedFile => action(acceptedFile)} accept={type}>
    {({ getRootProps, getInputProps, isDragActive }) => (
      <div
        {...getRootProps()}
        className={`dropzone ${cssIf(
          isDragActive,
          `dropzone--active`
        )} flex flex-col justify-center items-center`}
      >
        {isDragActive ? (
          <span className="dropzone__title">Загрузить!</span>
        ) : (
          <React.Fragment>
            <span className="dropzone__title">Перетащите файл сюда,</span>

            <span className="dropzone__subtitle">или нажмите</span>

            <Button text={`Выбрать файл формата jpg, jpeg, png`} color="border-gray" />
          </React.Fragment>
        )}

        <input {...getInputProps()} />
      </div>
    )}
  </Dropzone>
);

export const FileDropZoneLoaded = ({
  filename = false,
  resetText = "Cбросить?",
  clear = () => {},
}) => (
  <div
    className="dropzone dropzone--loaded text-center"
    onClick={() => {
      const msg = "Вы точно хотите сбросить текущий файл?";
      window.confirm(msg) && clear();
    }}
  >
    <div className="dropzone__title">{cssIf(filename, filename, `Файл загружен!`)}</div>
    <div className="dropzone__subtitle">{resetText}</div>
  </div>
);

export const PeopleAddPhoto = person => {
  const [globalState, globalActions] = useGlobal();

  const { selected_face } = globalState;

  const reload = () => {
    globalActions.people.photos(selected_face);
    popupClose();
  };
  return (
    <PeoplePopupAddPhoto // Popup людей
      mode="add"
      func={form => {
        Add(form, reload);
      }}
      person={person.person}
    />
  );
};

export const PeoplePopupAddPhoto = props => {
  const [globalState, globalActions] = useGlobal();

  const { selected_face } = globalState;

  const [file, setFile] = useState("");

  const [fileObj, setFileObj] = useState([]);

  const TITLE = {
    add: "Добавление фотографии",
  };
  const FUNC_BTN_TEXT = {
    add: "Загрузить",
  };

  function sendForm() {
    let form = {
      id: selected_face.id,
      photos: fileObj,
    };

    console.log(`sendForm:`, form);
    props.func(form);
  }

  return (
    <Popup className="popup_people">
      <div className="popup__title">
        {TITLE[props.mode]} сотрудника {props?.name && `- ${props.name}`}
      </div>

      <div className="flex">
        <div className="popup_people__inputs">
          {file === "" ? (
            <FileDropZone action={e => getNameFromFile(e, setFile, setFileObj)} />
          ) : (
            <FileDropZoneLoaded filename={file} clear={() => setFile("")} />
          )}

          <div className="flex">
            <Button text={FUNC_BTN_TEXT[props.mode]} action={sendForm} />

            <Button text="Отмена" action={popupClose} color="gray" />
          </div>
        </div>
      </div>
    </Popup>
  );
};
