import React from "react";

const TimeBtn = props => (
  <div className={"time-btn"}>
    с
    <input value={props.time1} onChange={e => props.setTime1(e.target.value)} />
    по
    <input value={props.time2} onChange={e => props.setTime2(e.target.value)} />
  </div>
);

export default TimeBtn;
