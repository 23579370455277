import React from "react";

const DateChoiceMobile = ({
  selectedDate,
  monthNames,
  getPrevMonth,
  getNextMonth,
  getPrevYear,
  getNextYear,
}) => {
  return (
    <div className="calendar-panel-btn">
      <div>
        <div className="calendar-panel-btn__arrow" onClick={getPrevYear}>
          <svg
            width="10"
            height="14"
            viewBox="0 0 10 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.60597 6.82953L8.60597 12.4751L0.810652 6.82953L8.60597 1.18393L8.60597 6.82953Z"
              stroke="#68E4FF"
            />
          </svg>
        </div>

        {selectedDate.getFullYear()}

        <div className="calendar-panel-btn__arrow" onClick={getNextYear}>
          <svg
            width="11"
            height="14"
            viewBox="0 0 11 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.45883 6.5076L1.45883 1.46122L9.51516 7.0084L1.45864 12.5075L1.45883 6.50761L1.45883 6.5076Z"
              stroke="#68E4FF"
            />
          </svg>
        </div>
      </div>
      <div>
        <div className="calendar-panel-btn__arrow" onClick={getPrevMonth}>
          <svg
            width="10"
            height="14"
            viewBox="0 0 10 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.60597 6.82953L8.60597 12.4751L0.810652 6.82953L8.60597 1.18393L8.60597 6.82953Z"
              stroke="#68E4FF"
            />
          </svg>
        </div>

        {monthNames[selectedDate.getMonth()]}

        <div className="calendar-panel-btn__arrow" onClick={getNextMonth}>
          <svg
            width="11"
            height="14"
            viewBox="0 0 11 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.45883 6.5076L1.45883 1.46122L9.51516 7.0084L1.45864 12.5075L1.45883 6.50761L1.45883 6.5076Z"
              stroke="#68E4FF"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default DateChoiceMobile;
