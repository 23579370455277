import React from 'react';
import useGlobal from '../../../store';
import Section from '../../components/containers/section';
import DatePicker from '../../components/datepicker';

const DateTime = ( props ) => {

  const { onlyDate = false } = props;

  const [ globalState, globalActions ] = useGlobal();
  const { 

    startDate,
    endDate,
    startTime,
    endTime

  } = globalState;

  const { changeState } = globalActions;

  return (

    <Section title = "Период" className = "datetime" { ...props } >

      <DatePicker

        onlyDate = { onlyDate }
        calendarOpened = { props?.calendarOpened }
        startDate = { startDate }
        endDate   = { endDate }
        startTime = { startTime }
        endTime   = { endTime }
        setStartDate = { ( value ) => changeState( "startDate", value ) }
        setEndDate   = { ( value ) => changeState( "endDate",   value ) }
        setStartTime = { ( value ) => changeState( "startTime", value ) }
        setEndTime   = { ( value ) => changeState( "endTime",   value ) }
        showMobileCalendar = { props?.showMobileCalendar }
        setShowMobileCalendar = { props?.setShowMobileCalendar }
        refShowCalendar = { props.refShowCalendar }

      />
            
    </Section>

  )

} 

export default DateTime;