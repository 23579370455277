import React from 'react';
import useGlobal from '../../../store';

import Cameras from './cameras';
import Users from './users';

const Admin = () => {

  const [ globalState, ] = useGlobal();

  const { user_type } = globalState;

  return (

    <React.Fragment>

      { user_type === 3 && <Users /> }
      { user_type <= 2 && <Cameras /> }

    </React.Fragment>

  );

}

export default Admin;