import React, { useEffect, useState } from "react";
import "./popup.markup.confirm.scss";

import Textfield from "../../components/textfield";
import Button from "../../components/button";
import { Popup, popupClose } from "../index";
import Selectfield from "../../components/selectfield";
import { all as getCameras } from "../../../store/actions/cameras";

import { all as getModels } from "../../../store/actions/models";
import {
  addUserMarkup,
  editUserMarkup,
  getUserMarkup,
} from "../../../store/actions/user.markups";

export const MarkupModalAdd = ({ markup }) => {
  const reload = () => {
    document.location.href = "/markups";
    popupClose();
  };

  return (
    <MarkupConfirmModal
      mode="add"
      markup={markup}
      func={form => {
        addUserMarkup(form, reload);
      }}
    />
  );
};

export const MarkupModalEdit = ({ id, markup }) => {
  const reload = () => {
    document.location.href = '/markups';
    popupClose();
  };

  const [data, setData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (!isDataLoaded) {
      (async () => {
        getUserMarkup(false, id).then(data => {
          setData(data);
          setIsDataLoaded(true);
        });
      })();
    }
  }, []);

  return isDataLoaded ? (
    <MarkupConfirmModal
      mode="edit"
      data={data}
      func={form => {
        editUserMarkup(
          {
            ...form,
          },
          reload
        );
      }}
      id={id}
      markup={markup}
    />
  ) : null;
};

export const MarkupConfirmModal = props => {
  const TITLE = {
    add: "Сохранение новой",
    edit: "Редактирование",
  };
  const FUNC_BTN_TEXT = {
    add: "Добавить",
    edit: "Сохранить",
  };

  const [markupName, setMarkupName] = useState(props?.data?.name || "");

  const [camerasList, setCamerasList] = useState([]);

  const [selectedCamera, setSelectedCamera] = useState(props?.data?.camera_id || "");

  const [selectedModel, setSelectedModel] = useState(props?.data?.model_id || "");

  const [modelsList, setModelsList] = useState([]);

  useEffect(() => {
    getCameras().then(res => {
      setCamerasList(res);
    });

    getModels().then(res => {
      setModelsList(res);
    });
  }, []);

  useEffect(() => {
    if (props?.data?.camera_id) {
      const camera = camerasList.find(camera => camera.id === props.data.camera_id);
      if (camera) {
        setSelectedCamera(camera);
      }
    }

    if (props?.data?.model_id) {
      const model = modelsList.find(model => model.id === props.data.model_id);
      if (model) {
        setSelectedModel(model);
      }
    }
  }, [props?.data?.camera_id, props?.data?.model_id, camerasList, modelsList]);

  const formIsValidated = markupName !== "" && (selectedCamera || selectedModel);

  function sendForm() {
    let form = {
      id: props.id,
      name: markupName,
      camera_id: selectedCamera.id,
      model_id: selectedModel.id,
      markup: props.markup,
    };

    console.log(`sendForm:`, form);
    props.func(form);
  }

  return (
    <Popup className="popup_export">
      <div className="popup__title">
        {TITLE[props.mode]} разметки {props?.name && `- ${props.name}`}
      </div>

      <div className="flex">
        <div className="popup_export__inputs">
          <Textfield
            title="Название разметки"
            placeholder="Разметка для первой камеры"
            value={markupName}
            set={setMarkupName}
          />

          <div className="popup_export__inputs__row popup_export__inputs flex items-center">
            <Selectfield
              title="К какой камере будет относиться разметка?"
              list={camerasList}
              selected={selectedCamera}
              select={setSelectedCamera}
              displayItem={"comment"}
              selectDisplayItem={"comment"}
              extraSelectDisplayItem={"name"}
            />
          </div>
          <div className="popup_export__inputs__row popup_export__inputs flex">
            <Selectfield
              title="Для какой модели будет использоваться разметка?"
              list={modelsList}
              selected={selectedModel}
              select={setSelectedModel}
            />
          </div>

          <div className="flex">
            <Button
              text={FUNC_BTN_TEXT[props.mode]}
              action={sendForm}
              disabled={!formIsValidated}
            />

            <Button text="Отмена" action={popupClose} color="gray" />
          </div>
        </div>
      </div>
    </Popup>
  );
};
