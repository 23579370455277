import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Charty from 'react-charty'

import useGlobal from '../../../store';

import Cameras from '../panel/cameras';
import Section from '../../components/containers/section';
import { Grid, Col } from '../../components/containers/grid';
import IntraDay from '../../components/charts/intraday';
import MonthDaysEventsTime from '../../components/charts/month.days.events.time';
import StatsNumbers from '../../components/stats.numbers';

import numberSpaces from '../../../scripts/helpers/numbers.with.spaces';

import './stats.scss';
import DateTime from '../panel/datetime';
import LoadingSpinner from '../../components/loader';
import StatsColors from '../../components/stats.colors';
import MobileBottomBar from '../../components/mobile.bottom.bar';
import getAdaptiveVariant from '../../../scripts/helpers/get.adaptive';
import useDebouncedFunction from '../../../scripts/hooks/use.debounce';
import PeopleSelectSection from "../../components/people.select.section";



const Stats = () => {

  function clear_selected_locations(){

    const { changeStates } = globalActions;

    const random = Math.random(1,9999);

    let cleared = {

      popupRandom: random,
      is_cleared: true,
      selected_locations: [],
      selected_location_type_ref: {},
      realtime_camera: "",
      realtime_watch: ""

    }

    changeStates(cleared)
  }

  const navigate = useNavigate();
  // const redirect = () => history.push('/');  // Deprecated ?

  const [ globalState, globalActions ] = useGlobal();

  const { stats, people } = globalActions;

  const {

    selected_locations,
    selected_locations_type_ref,
    FacilityEmployeeListLoaded,

    is_cleared,
    endDate,
    popupRandom,

    locationsColorsIsLoaded,
    locationsColors,

    locationsRatingIsLoaded,

    countEventsIsLoaded,
    countTodayEvents,
    countAllEvents,

    chartOneIsLoaded,
    chartOne,
    chartOneTimeline,

    chartTwoIsLoaded,
    chartTwo,
    chartTwoTimeline,

    chartThreeIsLoaded,
    chartThree,
    chartThreeTime,

    chartFourIsLoaded,
    chartFour,
    chartFourTimeline,


  } = globalState;

  const Charts4Counters = selected_locations_type_ref[selected_locations[0]]


  useEffect(() => { people.allEmployees() }, []);

  useEffect(() => {
    if ( selected_locations.length > 0 && endDate !== null ) {

      stats.getLocationsColors();
      stats.getCountEvents();
      Charts4Counters ? stats.getChartFour() : stats.getChartOne() // Если нужны графики для счетчиков, вызываем 4 чарт
      stats.getChartTwo();
      stats.getChartThree();

    }

    if (!is_cleared || (Charts4Counters === undefined && selected_locations.length > 0)
      || Object.keys(selected_locations_type_ref).length < selected_locations.length){
      // FIXED (При переключении с событий на статистику, правила выбора локации отличны от правил выбора в статистике
      // В статистике нельзя выбирать локации с разными типами событий (Счетчик || Не счетчик), поэтому, чтобы миновать все
      // эти проблемы, при переходе в статистику выбранные локации очищаются, кроме сценария статистика-события-статистика
      clear_selected_locations()
    }

    if ( !locationsRatingIsLoaded ) {
      stats.getLocationsRating();
    }

  }, [ popupRandom, endDate ]);

  const [ section, setSection ] = useState("stats");
  const [ adaptive, setAdaptive ] = useState( getAdaptiveVariant() );
  const [ isNotMobile, setIsNotMobile ] = useState( false );

  useEffect(() => {

    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);

  }, []);

  const updateWidth = useDebouncedFunction(() => {

    const _adaptive = getAdaptiveVariant();

    setAdaptive( _adaptive );
    setIsNotMobile( _adaptive !== "mobile" );

  }, 500 );

  const isLocationsSelected = selected_locations.length > 0;


  const gridTemplateCols = "18% 1fr"

  return (

    <div className = "stats-container">

      <span style={{ display: 'none' }}>{ popupRandom }</span>

      <Grid cols = { gridTemplateCols } className = "stats">

        <Col>

          <Cameras

            allIsOpened
            hideRealtime
            maxHeight = "48.5%"
            disabled = {( !chartTwoIsLoaded && isLocationsSelected )}

          /> {/* onlyOneCameraMultiselect */}

          <DateTime onlyDate calendarOpened maxHeight = "88px" />

        </Col>

        <Col>

          <div className="flex flex-1 stats__content-top">

            <div className = "flex flex-col flex-1">

              <Section

                fullscreen
                title = "События в течении дня"
                className = "flex-1 intraday_container"

              >

                { isLocationsSelected ? (!chartOneIsLoaded && !chartFourIsLoaded)

                  ? <LoadingSpinner />

                  : ( Charts4Counters ? <MonthDaysEventsTime

                      data = { chartFour }
                      timeline = { chartFourTimeline }

                    /> : <IntraDay
                      data = { chartOne }
                      timeline = { chartOneTimeline }

                    /> )

                  : <div className="tip">Выберите одну или несколько локаций</div>

                }

              </Section>

              <Section

                fullscreen
                title = "События в течении месяца"
                className = "flex-1"

              >

                { isLocationsSelected ? !chartTwoIsLoaded

                  ? <LoadingSpinner />

                  : <MonthDaysEventsTime

                      data = { chartTwo }
                      timeline = { chartTwoTimeline }

                    />

                  : <div className="tip">Выберите одну или несколько локаций</div>

                }

              </Section>

            </div>

            <Section

              title = "Цвета локаций на графиках"
              className = "stats__content-top__col-1 flex-1"

            >

              { isLocationsSelected ? !locationsColorsIsLoaded

                ? <LoadingSpinner />
                : <StatsColors locations = { locationsColors } />
                : <div className="tip">Выберите одну или несколько локаций</div>

              }

            </Section>

          </div>

          <div className="flex flex-1 stats__content-bottom">

            <Section

              title = "В процентном соотношении"
              className = "stats__content-bottom__col-1 flex flex-col justify-center items-center"

            >

              { isLocationsSelected ? !chartThreeIsLoaded

                ? <LoadingSpinner />

                : <div style={{ zoom: window.devicePixelRatio }}>

                    <Charty

                      title = { '' }
                      {...chartThree}
                      stepX={1}
                      startX={2017}
                      endX={2018}
                      style={{ width: 280, height: 280 }}
                      showPreview = { false }
                      showButtons = { false }
                      showRangeText = { false }
                      showLegendTitle = { false }

                    />

                  </div>

                : <div className="tip">Выберите одну или несколько локаций</div>

              }


                    {/* <Charty

                      {...lengthPercent} 
                      stepX={1} 
                      startX={2017} 
                      endX={2018} 
                      style={{ width: 280, height: 280 }} 
                      showPreview = { false }
                      showButtons = { false }
                      showRangeText = { false }
                      showLegendTitle = { false }
                      
                    /> */}

            </Section>

            <div className="stats__content-bottom__col-2 flex flex-col flex-1">

              <div className = "flex flex-1 stats__content-bottom__col-2__top">

                <Section

                  title = "В часах и минутах"
                  className = "stats__content-bottom__col-2__hoursmins flex flex-col justify-center"

                >

                  { isLocationsSelected ? !chartThreeIsLoaded

                    ? <LoadingSpinner />

                    : <StatsNumbers

                        data = {[
                          {
                            title: (<span>Общее время<br/>событий:</span>),
                            value: chartThreeTime.events,
                            gradient: "linear-gradient(147.28deg, #44F4FF 33.76%, #0093D1 70.34%)"
                          },
                          {
                            title: (<span>Общее время<br/>без событий:</span>),
                            value: chartThreeTime.free,
                            gradient: "linear-gradient(147.28deg, #44F4FF 33.76%, #0093D1 70.34%)"
                          }
                        ]}

                      />

                    : <div className="tip">Выберите одну или несколько локаций</div>

                  }

                </Section>

                {/* <Section 

                  title = "Объём типов событий" 
                  maxHeight = "227px" 
                  className = "flex flex-col"

                >

                  <div style={{ zoom: window.devicePixelRatio }}>

                    <Charty

                      {...locationsPercent } 
                      stepX={1} 
                      startX={2017} 
                      endX={2019} 
                      style={{ width: 180, height: 180 }} 
                      showPreview = { false }
                      showButtons = { false }
                      showRangeText = { false }
                      showLegendTitle = { false }
                      
                    />

                  </div>

                </Section> */}

              </div>

              <Section

                title = "Счётчик событий"
                className = "flex flex-col justify-center"
                maxHeight = "150px"

              >

                { isLocationsSelected ? !countEventsIsLoaded

                  ? <LoadingSpinner />

                  : <StatsNumbers

                      data = {[
                        {
                          title: "Всего:",
                          value: numberSpaces( countAllEvents ),
                          gradient: "linear-gradient(141.76deg, #FFC700 11.33%, #EE7200 97.68%)"
                        },
                        {
                          title: "Сегодня:",
                          value: numberSpaces( countTodayEvents ),
                          gradient: "linear-gradient(147.28deg, #44F4FF 33.76%, #0093D1 70.34%)"
                        }
                      ]}

                    />

                  : <div className="tip">Выберите одну или несколько локаций</div>

                }

              </Section>

            </div>

            <div className="stats__content-bottom__col-3 flex flex-col">

                { !FacilityEmployeeListLoaded

                  ? <LoadingSpinner />
                  : <PeopleSelectSection disabled = {( !chartTwoIsLoaded && isLocationsSelected )} />

                }

            </div>

          </div>

        </Col>

      </Grid>

      { adaptive === "mobile" &&

        <MobileBottomBar

          current = { section }
          navigate = { ( to ) => to !== "stats" && navigate( `/?goto=${ to }` ) }

        />

      }

    </div>

  );

}

export default Stats;