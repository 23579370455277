import React, { useEffect } from "react";
import useReloadDummy from "../../../scripts/hooks/use.reload.dummy";

const CalendarTable = ({
  daysShort,
  calendarRows,
  startDate,
  endDate,
  dateClickHandler,
  setHover,
  checkBetween,
}) => {
  const [Dummy, reloadDummy] = useReloadDummy();

  useEffect(() => {
    reloadDummy();
  }, [startDate, endDate]);

  return (
    <React.Fragment>
      <Dummy />

      <table className="table">
        <thead>
          <tr>
            {daysShort.map(day => (
              <th key={day} valign={"top"}>
                {day}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {Object.values(calendarRows).map((cols, y) => (
            <tr key={y}>
              {cols.map((col, x) => {
                let _date;
                let _start;
                let _end = false;

                if (col.date.indexOf("-") !== -1) {
                  _date = col.date.split("-")[0];
                } else {
                  _date = col.date.split(".")[0];
                }

                if (startDate.indexOf("-") !== -1) {
                  _start = startDate.split("-")[0];
                } else {
                  _start = startDate.split(".")[0];
                }

                if (endDate && typeof endDate === "string") {
                  if (endDate.indexOf("-") !== -1) {
                    _end = endDate.split("-")[0];
                  } else {
                    _end = endDate.split(".")[0];
                  }
                }

                const selected = () =>
                  parseInt(_date) == parseInt(_start) ||
                  parseInt(_date) == (endDate && parseInt(_end));

                let between = checkBetween(col.date);

                const className = [
                  col.classes,
                  selected ? "" : `select`,
                  between ? `selected` : "",
                ].join(" ");

                return (
                  <td
                    // key={col.date}
                    key={x}
                    className={className}
                    onMouseOver={() => setHover(Number(col.date.split("-")[0]))}
                    onClick={() => {
                      dateClickHandler(
                        `${col.date.split("-")[0]}.${col.date.split("-")[1]}.${
                          col.date.split("-")[2]
                        }`
                      );
                      reloadDummy();
                    }}
                    //// onClick={() => { console.log(`dateClickHandler(${ col.date })`) }}
                  >
                    {col.value}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default CalendarTable;
