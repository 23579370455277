import './stats.colors.scss';

const StatsColors = ({ locations = [] }) => (

  <div className="stats_colors">

    { locations.length > 0 && locations.map(( el, key ) => (

      <div

        key = { key }
        className="stats_colors__item flex items-center"
        style = {{
          backgroundImage: el.color
        }}

      >

        <div
          className="stats_colors__item__dot"
          style = {{
            background: el.color
          }}
        />
        
        { el.name }
        
      </div>

    ))}

  </div>

)

export default StatsColors;