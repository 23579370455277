import React, { useRef } from 'react';
import Section from '../../components/containers/section';
import SimillarFace from "../../components/face.simillar";

const SimillarFaces = () => {

  const SIMILLAR_FACES_DATA = [
    {
      name: "Пирогова М.C.",
      category: "сотрудник",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },{
      name: "Пирогова М.C.",
      category: "сотрудник",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },{
      name: "Пирогова М.C.",
      category: "сотрудник",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },{
      name: "Пирогова М.C.",
      category: "сотрудник",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },{
      name: "Пирогова М.C.",
      category: "сотрудник",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },{
      name: "Пирогова М.C.",
      category: "сотрудник",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },{
      name: "Пирогова М.C.",
      category: "сотрудник",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },{
      name: "Пирогова М.C.",
      category: "сотрудник",
      photo: "https://proprikol.ru/wp-content/uploads/2020/03/art-kartinki-8.jpg"
    },
    
  ];

  const scrollable = useRef();

  function enableHorizontalScrolling() {
    scrollable.current.dispatchEvent( new Event('keydown', { key: 'shift' }) )
  }
  function disableHorizontalScrolling() {
    // const canceled = !scrollable.current.dispatchEvent('keypress');
  }

  return (

    <Section title = "Похожие лица" maxHeight = "243px">

      <div 
        ref = { scrollable }
        className = "flex overflow-x py-10" 
        onMouseEnter = { enableHorizontalScrolling }
        onMouseLeave = { disableHorizontalScrolling }
      >

        { SIMILLAR_FACES_DATA.length > 0 && SIMILLAR_FACES_DATA.map(( item, i ) => (
          
          <SimillarFace

            name = { item.name }
            category = { item.category }
            photo = { item.photo }

          />

        ))}

      </div>
            
    </Section>

  )

} 

export default SimillarFaces;