import './people.select.section.scss';
import useGlobal from "../../../store";
import CheckboxInTree from "../checkbox.in.tree";
import {CheckboxWrapper, IconWrapper, WorkerName} from "../filestructure/styled";
import {getIcon} from "../filestructure/helpers";
import cssIf from "../../../scripts/helpers/class.add.if";
import SectionWithSearch from "../containers/section.with.search";

const PeopleSelectSection = ( props ) => {

  const [ globalState, globalActions ] = useGlobal();

  const { changeStates } = globalActions;

  const {FacilityEmployeeList, selected_people, FacilityEmployeeListSearched, object_have_faces } = globalState;

  const ActualList = FacilityEmployeeListSearched ? FacilityEmployeeListSearched : FacilityEmployeeList

  function setPeople( id ) {

    const temp = selected_people

    const pos = selected_people.indexOf( id );

    if (pos === -1){
      temp.push( id )
    }

    else{
      temp.splice( pos, 1 );
    }

    const random = Math.random(1,9999);

    let updated = {

      popupRandom: random,
      selected_people: temp,

    }

    changeStates( updated );

  }

  return (
    <SectionWithSearch

      scrollable
      title = "Персонал"
      className = "stats__content__locations_rating flex flex-1 flex-col"
      search_disabled={ !object_have_faces }

    >
  <div className={ `people_select ${ cssIf( props.disabled && FacilityEmployeeList.length > 0, "disabled" ) }`}>

    { ActualList.length > 0 ? ActualList.map(( el, key ) => (

      <PSSItem { ...el } key = { key } setPeople={ setPeople } selected_people={ selected_people } />  // Не знаю насколько плохо так делать
        // Но в текущей перспективе я сделал так

    )) : <div className = "empty_people_list">{ 'Рабочий персонал ещё не добавлен' }</div>}

  </div>
    </SectionWithSearch>

);
}

const PSSItem = ({ id, name, setPeople = () => {}, selected_people = [] }) => (

  <div className="people_select__item flex justify-between items-center">

    <IconWrapper padding_bottom={ '5px' }>

      { getIcon('worker') }

    </IconWrapper>

    <WorkerName

      disabled={ -1 !== !selected_people.indexOf(id) }

      onClick = { ( ) =>  {
          setPeople( id );
      }}
    >
      <input

        style = {{
          color: (
            selected_people.indexOf( id ) !== -1
          )
            ? "#68e4ff"
            : null,
          width: '100%'
        }}

        data-id = { id }

        value = { name }

        disabled = { true }

      />
    </WorkerName>

    <CheckboxWrapper onClick = { () => { setPeople( id ) } }>
      <CheckboxInTree checked = { selected_people.indexOf( id ) !== -1 } extended={ true } />
    </CheckboxWrapper>

  </div>

)

export default PeopleSelectSection;