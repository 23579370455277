import React, {useRef, useState} from "react";
import "./face.scss";
import cssIf from "../../../scripts/helpers/class.add.if";
import useGlobal from "../../../store";
import Tooltip from "../tooltip/tooltip";


const Face = ({  // Расширенное лицо для страницы по сотрудникам

  index = "0",
  photo = "" ,

}) => {

  const DeletePhotoRef = useRef();
  const ImportantPhotoRef = useRef();
  const [ globalState, globalActions ] = useGlobal();

  const { people } = globalActions;

  const { selected_face } = globalState;
  const [ important, setImportant ] = useState( selected_face.photo === photo );

  if (important && photo !== selected_face.photo && (selected_face?._dbp !== index  && selected_face?._dbp)){  // Я чет не понимал долго как корректно отслеживать состояния у всех карточек, useEffect не помогал, помог костыль, впрочем ничего нового :(
    setImportant(false) // Сбрасываем важность
  }

  function onStarClick (id, photo) { // При клике на звездочку

    if ( !important ){
      people.BestPhoto(id, photo, index)
    }
  }


  function onDeleteClick(id, photo) { // При клике на удаление
    if ( !important ) {
      people.deletePhoto(id, photo)
    }
  }

  return (

  <div className = {`faceWP`}>
    <Tooltip text = "Удалить фотографию" TooltipPosition = "top" ChildrenRef={ DeletePhotoRef } Offset={{
      top: -170,
      left: 10
    }} onCriticalPositionOffset={ 185 }/>
    <Tooltip text = "Использовать на карточках событий" TooltipPosition = "top" ChildrenRef={ ImportantPhotoRef } Offset={{
      top: -170,
      left: 20
    }} onCriticalPositionOffset={ 320 } CriticalPosition={ 80 }/>
      <div className = "faceWP__image" style = {{ backgroundImage: `url(${ photo })` }}>

        <div className = {`faceWP_buttons left_sided`}>

        <button
          ref = { ImportantPhotoRef }
          className = {`faceWP_buttons__item event__btn_icon event__btn_icon__starred left_sided ${ cssIf( important, "checked" )}`}
          onClick = { () => { !important && setImportant( !important ); onStarClick(selected_face.id, photo); } }

        />
        </div>
          <div className = {`faceWP_buttons`}>
            <button
              ref = { DeletePhotoRef }
              className = {`faceWP_buttons__item event__btn_icon event__btn_icon__cancel`}
              onClick = { () => { if ( window.confirm(`Удалить фотографию?`) ) { onDeleteClick(selected_face.id, photo); }} }

            />

          </div>
        <div className = "faceWP__image__number">
          { index }
        </div>

      </div>

    </div>
)};

export default Face;