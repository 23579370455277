import React from 'react';
import useGlobal from '../../../store';
import Section from '../../components/containers/section';
import Video from '../../components/player';

const Play = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { realtime_watch } = globalState;

  return (

    <Section
    
      scrollable 
      title = "Просмотр"
      className = "videoblock"
      
    >

      { realtime_watch === "" && <Video /> }

      { realtime_watch !== "" &&
        
        <iframe

          title = 'Просмотр'
          src = { realtime_watch }

        />

      }
            
    </Section>

  )

} 

export default Play;