import React, { useEffect } from 'react';
import useGlobal from '../../../store';
import TableHead from '../../components/table.head';
import './admin.scss';

import Table from '../../components/table';
import LoadingSpinner from '../../components/loader';
import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';

const Cameras = ({}) => {

  const [ globalState, globalActions ] = useGlobal();
  const { 

    camerasTable,
    camerasTableLoaded 

  } = globalState;

  const { popup, cameras } = globalActions;

  const [ Dummy, reloadDummy ] = useReloadDummy();

  useEffect(() => { cameras.all( reloadDummy ) }, []);

  const TABLE_HEAD = [
    {
      name: "Название",
      sort: true,
      // action: () => handleSort("alphabetical")
    },
    {
      name: "Комментарий",
      sort: true,
      // action: () => handleSort("checkinsCount")
    },
    {
      name: "Начало",
      sort: true,
      // action: () => handleSort("createdAt")
    },
    {
      name: "Завершение",
      sort: false
    },
    {
      name: "Название сервера",
      sort: true
    },
    {
      name: "Действия",
      sort: false
    }

  ]

  return (

    <div className="admin-container">

      <div className="admin admin-cameras">

        <TableHead

          title = "Камеры"
          action = { () => popup.showPopup('cameraAdd') }

        />

        <Dummy />

        { !camerasTableLoaded
          
          ? <LoadingSpinner />
          
          : <Table

              className = "cameras"
              template = "158px 1fr 130px 130px 207px 155px"
              columns = { TABLE_HEAD }
              rows = { camerasTable } 
              total = { camerasTable.length } 
              edit = { ( id ) => popup.showPopup( "cameraEdit", { id } ) }
              remove = { cameras.remove }
              loadMore = { () => {} } 
              openModal = { () => {} }
              
            />

        }

      </div>

    </div>

  );

}

export default Cameras;

export const cameras = [

  {
    name: "camera 1",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "1",
  },{
    name: "camera 2",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "2",
  },{
    name: "camera 3",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "3",
  },{
    name: "camera 1",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "1",
  },{
    name: "camera 2",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "2",
  },{
    name: "camera 3",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "3",
  },{
    name: "camera 1",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "1",
  },{
    name: "camera 2",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "2",
  },{
    name: "camera 3",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "3",
  },{
    name: "camera 1",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "1",
  },{
    name: "camera 2",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "2",
  },{
    name: "camera 3",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "3",
  },{
    name: "camera 1",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "1",
  },{
    name: "camera 2",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "2",
  },{
    name: "camera 3",
    comment: "comment",
    start: "12:00:00",
    end: "16:00:00",
    server: "Worker",
    actions: "e,r",
    id: "3",
  },

]