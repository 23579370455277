import React, { useState, useEffect, useRef } from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import { RandomHash } from '../../../scripts/hooks/use.reload.dummy';
import Checkbox from '../checkbox';
import "./multiselectfield.scss";

const Multiselectfield = ( props ) => {

  const {

    selected = false, 
    select, 
    list = [], 
    isLoading = false, 
    title = "", 
    placeholder = "Нажмите, чтобы выбрать", 
    object = "", 
    tip = "",
    item_to_show = 'name'

  } = props;

  const LIST_MAX_HEIGHT = 170;

  const listContainer = useRef();
  const [ isOpened, setIsOpened ] = useState( false );
  const [ fieldText, setFieldText ] = useState("");

  const [ dummy, setDummy ] = useState("");

  useEffect(() => {

    console.log('selected.length uef watcher', selected.length);

    if ( isLoading ) {

      setFieldText( "Загрузка..." );

    } else if ( !selected || selected?.length === 0 ) {

      setFieldText( placeholder );

    } else if ( selected.length > 0 ) {

      const temp = [];
      selected.forEach( el => temp.push( el[item_to_show] ? el[item_to_show] : el ) );
      setFieldText( temp.toString().replaceAll(',', ', ') );

    }

  }, [ selected, dummy ]);

  function Toggle() {

    if ( !isOpened ) { listContainer.current.style.overflow = "hidden"; }
    
    setIsOpened( !isOpened );

    let listContainerHeight = listContainer.current.scrollHeight + 20;

    if ( listContainerHeight > LIST_MAX_HEIGHT ) { 
      
      listContainerHeight = LIST_MAX_HEIGHT;
      listContainer.current.style.overflow = "auto";
    
    }
    
    listContainer.current.style.maxHeight = listContainer.current.style.maxHeight ? null : listContainerHeight + "px";

  }

  function handleSelect( item ) {

    let result = [];

    if ( item?.id ) {

      const finded = selected?.find( t => t.id === item.id );

      if ( !finded ) {

        result = selected;
        result.push( item );

      } else {

        selected.forEach( el => el.id !== item.id && result.push( el ));

      }

    } else {

      if ( selected.indexOf( item ) === -1 ) {

        result = selected;
        result.push( item );

      } else {

        selected.forEach( el => el !== item && result.push( el ));

      }

    }

    select( result );
    const random = RandomHash();    
    setDummy( random );

  }

  return (

    <div className = {`multiselectfield ${ cssIf( isOpened, "multiselectfield--opened" ) }`}>

      { title !== "" && <div className = "multiselectfield__title">{ title }</div> }

      <div 
        className = {`multiselectfield__field ${ cssIf( !isLoading && !selected, "multiselectfield__field--placeholder" )} flex items-center`} 
        onClick = { Toggle }
        title = { tip?.length > 0 ? tip : undefined }
      >
        
        <div className="multiselectfield__field__text">{ fieldText }</div>
        <div className="multiselectfield__field__arrow" />
        
      </div>

      <div className = "multiselectfield__list" ref = { listContainer }>

        { list.length > 0 && list.map(( item, key ) => {

          let isSelected = false;

          if ( item?.id && selected.length > 0 && typeof selected === "object" ) {

            const finded = selected.find( t => t.id === item.id );
            if ( finded ) isSelected = true;

          } else {

            if ( selected.indexOf( item ) !== -1 ) isSelected = true;

          }

          return (

            <div
              key = { key }
              className = {`multiselectfield__list__item flex items-center`}
              onClick = { () => { handleSelect( item ) }}
            >

              <Checkbox value = { isSelected } />
              
              { item[item_to_show] ? item[item_to_show] : item }

            </div>

          )
        
        })}

      </div>

    </div>

  );

}

export default Multiselectfield;