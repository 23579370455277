import React, { useEffect, useRef, useState } from "react";
import Section from "../../components/containers/section";
import { CirclePicker } from "react-color";
import "./Markup-Canvas.scss";
import Textarea from "../../components/textarea";
import LoadingSpinner from "../../components/loader";
import { toJSON, toXML } from "./utils/data.managers";
import { getScreenshot } from "../../../store/actions/cameras";
import useGlobal from "../../../store";

function parseQueryString(queryString) {
  const params = new URLSearchParams(queryString);
  return {
    mode: params.get("mode") || "add",
    id: params.get("id") || null,
    rtsp: params.get("rtsp") || null,
  };
}

/**
 * Компонент для генерации разметки на основе изображения
 * TODO: Надо отрефакторить это чудо инженерной мысли
 */
const MarkupDrawTool = () => {
  const { rtsp: RTSPUrl, id: markupId, mode } = parseQueryString(window.location.search);

  const [globalState, globalActions] = useGlobal();
  const { userMarkup, userMarkupLoaded } = globalState;

  const { userMarkups, popup } = globalActions;

  useEffect(() => {
    mode === "edit" &&
      markupId &&
      !userMarkupLoaded &&
      userMarkups.getUserMarkup(markupId);
    if (userMarkupLoaded && userMarkup?.markup) {
      setPhotoUrl(userMarkup.markup.image.src);
      setMarkups(userMarkup.markup?.markups);
      setClassList(userMarkup.markup.classList);
      userMarkup.markup.classList.length > 0 &&
        setSelectedClass(userMarkup.markup.classList[0]);
      userMarkup.markup.classList[0]?.type === "line" &&
        setSelectedSublocation(`${userMarkup.markup.classList[0].name} (Вход)`);
    }
  }, [userMarkup, userMarkupLoaded]);

  const [photoUrl, setPhotoUrl] = useState(userMarkup?.markup?.image.src || "https://i.ytimg.com/vi/GtFmWTGiRHg/maxresdefault.jpg");
  const canvasRef = useRef(null);
  const offscreenCanvasRef = useRef(document.createElement("canvas"));

  const [classList, setClassList] = useState([]);
  const [selectedClass, setSelectedClass] = useState({
    name: "Объект 1",
    color: "#000000",
    type: "all",
  });
  const [selectedSublocation, setSelectedSublocation] = useState(null);
  const [newClassName, setNewClassName] = useState("");
  const [newClassColor, setNewClassColor] = useState("#000000");
  const [contentMode, setContentMode] = useState("classes");

  // Состояния для отслеживания действий с мышью и выбранных элементов
  const [isDrawing, setIsDrawing] = useState(false); // флаг для отслеживания рисования линий
  const [isMoving, setIsMoving] = useState(false); // флаг для отслеживания перемещения изображения
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 }); // начальная точка для рисования линии
  const [selectedMarkupIndex, setselectedMarkupIndex] = useState(null); // индекс выбранной линии
  const [selectedPoint, setSelectedPoint] = useState(null); // выбранная точка на линии
  const [selectedTool, setSelectedTool] = useState("line"); // выбранный тип рисования
  const [currentPolygon, setCurrentPolygon] = useState([]); // выбранный многоугольник
  const [isPolygonDrawing, setIsPolygonDrawing] = useState(false); // флаг для отслеживания рисования многоугольника

  // Состояния для цвета и размера линий, изображения и его позиции и масштаба
  const [image, setImage] = useState({
    src: photoUrl,
    width: 0,
    height: 0,
  });
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 }); // позиция изображения
  const [lastImagePosition, setLastImagePosition] = useState({ x: 0, y: 0 }); // последняя позиция изображения
  const [scale, setScale] = useState(1); // масштаб изображения
  const [markups, setMarkups] = useState([]); // массив линий на изображении
  const [lineWidth, setLineWidth] = useState(5); // текущая толщина линий

  const [history, setHistory] = useState([]);

  const [markupsData, setMarkupsData] = useState(`{
  "image": "${image.src}",
  "size": {
    "width": ${image.width},
    "height": ${image.height}
  },
  "markups": []
}`); // Данные для отображения слева
  const [markupsDataFormat, setMarkupsDataFormat] = useState("json");

  function isColorDark(hexColor) {
    const rgb = parseInt(hexColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness < 128;
  }

  const classListItems = classList.map((item, index) => {
    const handleEditClick = () => {
      console.log("Редактирование:", item.name);
      // Реализуйте логику редактирования здесь
    };

    const handleSublocationClick = (classItem, sublocation) => {
      setSelectedClass(classItem);
      setSelectedSublocation(sublocation);
    };

    const handleLocationClick = (classItem, sublocation) => {
      setSelectedClass(classItem);
      classItem.type === "line" && selectedTool !== "line" && setSelectedTool("line");
      classItem.type === "polygon" &&
        (selectedTool !== "polygon" || selectedTool !== "rect") &&
        setSelectedTool("rect");
      if (!sublocation?.includes(classItem.name)) {
        setSelectedSublocation(`${classItem.name} (Вход)`);
      }
    };

    const isDark = isColorDark(item.color);
    const textColor = isDark ? "var(--color-textgray)" : "var(--color-black)";

    return (
      <Section title={item.type === "line" ? "Линия" : "Полигон"} key={index}>
        <div key={index} className="class-list">
          <button
            className={`class-item ${
              selectedClass && selectedClass.name === item.name ? "selected" : ""
            }`}
            onClick={() => handleLocationClick(item, selectedSublocation)}
            onContextMenu={e => e.preventDefault()}
            style={{ backgroundColor: item.color, color: textColor }}
          >
            {item.name}
          </button>
          {item.type === "line" && (
            <div className="sublocations">
              <div className={`sublocation-wrapper`}>
                <div
                  className={`sublocation ${
                    selectedSublocation === `${item.name} (Вход)` ? "selected" : ""
                  } ${!item.enterEnabled ? "inactive" : ""}`}
                  onContextMenu={e => e.preventDefault()}
                  onClick={() =>
                    item.enterEnabled &&
                    handleSublocationClick(item, `${item.name} (Вход)`)
                  }
                >
                  {item.name} (Вход)
                </div>
                <input
                  className={"exit-checkbox"}
                  type="checkbox"
                  checked={item.enterEnabled}
                  onChange={() => {
                    if (!item.exitEnabled) return;
                    handleSublocationClick(item, `${item.name} (Выход)`);
                    const updatedClassList = classList.map((classItem, idx) =>
                      idx === index
                        ? {
                            ...classItem,
                            enterEnabled: !classItem.enterEnabled,
                          }
                        : classItem
                    );
                    setClassList(updatedClassList);
                  }}
                />
              </div>
              <div className={`sublocation-wrapper`}>
                <div
                  className={`sublocation ${
                    selectedSublocation === `${item.name} (Выход)` ? "selected" : ""
                  } ${!item.exitEnabled ? "inactive" : ""}`}
                  onContextMenu={e => e.preventDefault()}
                  onClick={() =>
                    item.exitEnabled &&
                    handleSublocationClick(item, `${item.name} (Выход)`)
                  }
                >
                  {item.name} (Выход)
                </div>
                <input
                  className={"exit-checkbox"}
                  type="checkbox"
                  checked={item.exitEnabled}
                  onChange={() => {
                    if (!item.enterEnabled) return;
                    handleSublocationClick(item, `${item.name} (Вход)`);
                    const updatedClassList = classList.map((classItem, idx) =>
                      idx === index
                        ? { ...classItem, exitEnabled: !classItem.exitEnabled }
                        : classItem
                    );
                    setClassList(updatedClassList);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Section>
    );
  });

  useEffect(() => {
    !photoUrl && mode === "add" && getScreenshot(RTSPUrl, setPhotoUrl);
  }, [photoUrl]);

  const handleAddClass = classType => {
    // e.g -> true | false ('line' | ('polygon' | 'rect'))

    const tool = classType ? "line" : "polygon";
    if (newClassName.trim() !== "") {
      const isDuplicate = classList.some(
        item => item.name.trim() === newClassName.trim() || item.color === newClassColor
      );

      if (newClassName && selectedTool && !isDuplicate) {
        selectedTool !== tool && setSelectedTool(tool === "line" ? "line" : "rect");
        setClassList([
          ...classList,
          {
            name: newClassName,
            type: tool,
            color: newClassColor,
            exitEnabled: true,
            enterEnabled: true,
          },
        ]);
        setNewClassName("");
        setNewClassColor("#000000");
        setSelectedClass({
          name: newClassName,
          type: tool,
          color: newClassColor,
          exitEnabled: true,
          enterEnabled: true,
        });
      } else {
        alert(
          "Класс с таким именем или цветом уже существует. Пожалуйста, выберите другое имя или цвет."
        );
      }
    }
  };

  /**
   * Обработчик изменения ширины линии
   * @param { Object } event - объект события
   */
  const handleLineWidthChange = event => {
    setLineWidth(event.target.value);
  };

  /**
   * Обработчик завершения движения мыши
   * @param { Object } event
   */
  const stopMoving = event => {
    setIsMoving(false);
    if (event.button === 2) {
      setHistory([...history, { markups, scale, imagePosition, actionType: "move" }]);
    }
  };

  /**
   * Обработчик события нажатия на кнопку клавиатуры
   * @param { Object } event - объект события
   */
  const handleKeyDown = event => {
    if (event.ctrlKey && ["z", "я"].includes(event.key.toLowerCase())) {
      undoLastAction();
    }
  };

  /**
   * Обновление курсора при перемещении мыши
   * @param { Object } event - объект события
   */
  const updateCursor = event => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const currentPoint = {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };

    const toleranceForLine = 12;
    const toleranceForEndpoints = 18;

    const polygonHovered = markups.some(markup => {
      if (markup.type !== "polygon") return false;

      const polygonPointsOnScreen = markup.points.map(point => ({
        x: point.x * scale + imagePosition.x,
        y: point.y * scale + imagePosition.y,
      }));

      const pointNearVertex = polygonPointsOnScreen.some(vertex => {
        return distance(currentPoint, vertex) <= toleranceForEndpoints;
      });

      const pointNearEdge = polygonPointsOnScreen.some((_, index) => {
        const startPoint = polygonPointsOnScreen[index];
        const endPoint =
          polygonPointsOnScreen[(index + 1) % polygonPointsOnScreen.length];
        return isPointNearLine(currentPoint, { startPoint, endPoint }, toleranceForLine);
      });

      return pointNearVertex || pointNearEdge;
    });
    // FIXME: Склеить два прохода в один ;(
    const selectedMarkupIndex = markups.findIndex(line => {
      if (line.type === "polygon") return;
      const startPointOnScreen = {
        x: line.startPoint.x * scale + imagePosition.x,
        y: line.startPoint.y * scale + imagePosition.y,
      };
      const endPointOnScreen = {
        x: line.endPoint.x * scale + imagePosition.x,
        y: line.endPoint.y * scale + imagePosition.y,
      };
      const rectOnScreen = {
        startPoint: startPointOnScreen,
        endPoint: endPointOnScreen,
      };
      if (line.type === "rect") {
        return isPointOnRect(currentPoint, rectOnScreen);
      }
      const pointNearLine = isPointNearLine(currentPoint, rectOnScreen, toleranceForLine);
      const pointNearEndpoint = isPointNearEndpoint(
        currentPoint,
        rectOnScreen,
        toleranceForEndpoints
      );

      return pointNearEndpoint || pointNearLine;
    });

    if (selectedMarkupIndex !== -1 || polygonHovered) {
      canvas.style.cursor = "pointer";
    } else {
      canvas.style.cursor = "crosshair";
    }
  };

  // Добавление слушателя событий клавиатуры при монтировании компонента
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  /**
   * Отрисовка точек линии (начало и конец)
   * @param { CanvasRenderingContext2D } ctx - полотно для отрисовки
   * @param { array } points - массив точек линии
   * @param { string } color - цвет линии
   * @param { number } radius - радиус линии
   */
  const drawPoints = (ctx, points, color, radius = lineWidth) => {
    ctx.fillStyle = color;
    for (const point of points) {
      ctx.beginPath();
      ctx.arc(point.x, point.y, radius * 0.9, 0, 2 * Math.PI);
      ctx.fill();
    }
  };
  /**
   * Отображает нарисованную разметку на контексте холста.
   * @param { CanvasRenderingContext2D } ctx - Контекст холста для отрисовки.
   */
  const drawMarkups = ctx => {
    for (const markup of markups) {
      ctx.strokeStyle = markup.color;
      ctx.lineWidth = markup.width;
      ctx.beginPath();
      if (markup.type === "line") {
        ctx.moveTo(markup.startPoint.x, markup.startPoint.y);
        ctx.lineTo(markup.endPoint.x, markup.endPoint.y);
        ctx.stroke();

        // Рисуем точки начала и конца линии
        drawPoints(ctx, [markup.startPoint, markup.endPoint], markup.color, markup.width);

        // Рисуем перпендикулярную стрелку от центра линии
        drawPerpendicularArrow(ctx, markup);
      } else if (markup.type === "rect") {
        ctx.setLineDash(markup.lineDash);
        ctx.rect(
          markup.startPoint.x,
          markup.startPoint.y,
          markup.endPoint.x - markup.startPoint.x,
          markup.endPoint.y - markup.startPoint.y
        );
        ctx.stroke();
        ctx.setLineDash([]);
      } else if (markup.type === "polygon") {
        if (markup.points.length > 0) {
          ctx.moveTo(markup.points[0].x, markup.points[0].y);
          for (let i = 1; i < markup.points.length; i++) {
            const point = markup.points[i];
            ctx.lineTo(point.x, point.y);
          }
          ctx.stroke();

          // Рисуем точки для каждой вершины полигона
          drawPoints(ctx, markup.points, markup.color, markup.width);
        }
      }
    }
  };

  /**
   * Вычисляет расстояние между двумя точками.
   * @param { Object } p1 - Координаты первой точки.
   * @param { number } p1.x - Координата x первой точки.
   * @param { number } p1.y - Координата y первой точки.
   * @param { Object } p2 - Координаты второй точки.
   * @param { number } p2.x - Координата x второй точки.
   * @param { number } p2.y - Координата y второй точки.
   * @returns { number } - Расстояние между двумя точками.
   */
  const distance = (p1, p2) => {
    return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
  };

  const isPointOnRect = (point, rect, tolerance = 20) => {
    const { startPoint, endPoint } = rect;

    const corners = [
      { x: startPoint.x, y: startPoint.y },
      { x: endPoint.x, y: startPoint.y },
      { x: startPoint.x, y: endPoint.y },
      { x: endPoint.x, y: endPoint.y },
    ];

    for (let i = 0; i < corners.length; i++) {
      const corner = corners[i];
      const isCursorOnCorner =
        Math.abs(point.x - corner.x) <= tolerance &&
        Math.abs(point.y - corner.y) <= tolerance;

      if (isCursorOnCorner) {
        switch (i) {
          case 0:
            return "left-upper-point";
          case 1:
            return "right-upper-point";
          case 2:
            return "left-lower-point";
          case 3:
            return "right-lower-point";
        }
      }
    }

    const isCursorOnHorizontalEdge =
      ((point.y >= startPoint.y - tolerance && point.y <= startPoint.y + tolerance) ||
        (point.y >= endPoint.y - tolerance && point.y <= endPoint.y + tolerance)) &&
      point.x >= Math.min(startPoint.x, endPoint.x) - tolerance &&
      point.x <= Math.max(startPoint.x, endPoint.x) + tolerance;

    const isCursorOnVerticalEdge =
      ((point.x >= startPoint.x - tolerance && point.x <= startPoint.x + tolerance) ||
        (point.x >= endPoint.x - tolerance && point.x <= endPoint.x + tolerance)) &&
      point.y >= Math.min(startPoint.y, endPoint.y) - tolerance &&
      point.y <= Math.max(startPoint.y, endPoint.y) + tolerance;

    if (isCursorOnHorizontalEdge || isCursorOnVerticalEdge) {
      return "edge";
    }

    return false;
  };

  /**
   * Проверяет, находится ли точка рядом с линией.
   * @param { Object } point - Координаты проверяемой точки.
   * @param { number } point.x - Координата x проверяемой точки.
   * @param { number } point.y - Координата y проверяемой точки.
   * @param { Object } line - Объект линии.
   * @param { Object } line.startPoint - Координаты начальной точки линии.
   * @param { number } line.startPoint.x - Координата x начальной точки линии.
   * @param { number } line.startPoint.y - Координата y начальной точки линии.
   * @param { Object } line.endPoint - Координаты конечной точки линии.
   * @param { number } line.endPoint.x - Координата x конечной точки линии.
   * @param { number } line.endPoint.y - Координата y конечной точки линии.
   * @param { number } [tolerance=5] - Погрешность при проверке расстояния.
   * @returns { boolean } - Логическое значение, указывающее, находится ли точка рядом с линией.
   */
  const isPointNearLine = (point, line, tolerance = 12) => {
    const { startPoint, endPoint } = line;
    const dx = endPoint.x - startPoint.x;
    const dy = endPoint.y - startPoint.y;
    const l2 = dx * dx + dy * dy; // Длина линии в квадрате
    if (l2 === 0) {
      return false; // Линия является точкой, поэтому не может быть рядом с точкой
    }
    const t = Math.max(
      0,
      Math.min(1, ((point.x - startPoint.x) * dx + (point.y - startPoint.y) * dy) / l2)
    );
    const projectionX = startPoint.x + t * dx;
    const projectionY = startPoint.y + t * dy;
    const distanceFromLine = distance(point, {
      x: projectionX,
      y: projectionY,
    });
    return distanceFromLine <= tolerance;
  };

  /**
   * Проверяет, находится ли точка рядом с одним из концов линии.
   * @param { Object } point - Координаты проверяемой точки.
   * @param { number } point.x - Координата x проверяемой точки.
   * @param { number } point.y - Координата y проверяемой точки.
   * @param { Object } line - Объект линии.
   * @param { Object } line.startPoint - Координаты начальной точки линии.
   * @param { number } line.startPoint.x - Координата x начальной точки линии.
   * @param { number } line.startPoint.y - Координата y начальной точки линии.
   * @param { Object } line.endPoint - Координаты конечной точки линии.
   * @param { number } line.endPoint.x - Координата x конечной точки линии.
   * @param { number } line.endPoint.y - Координата y конечной точки линии.
   * @param { number } [tolerance=8] - Погрешность при проверке расстояния.
   * @returns { (string | boolean) } - Строка "start" или "end", если точка находится рядом с начальной или конечной точкой соответственно, в противном случае возвращает false.
   */
  const isPointNearEndpoint = (point, line, tolerance = 18) => {
    const startPointDist = distance(point, line.startPoint);
    const endPointDist = distance(point, line.endPoint);

    if (startPointDist <= tolerance || endPointDist <= tolerance) {
      if (startPointDist <= tolerance) {
        return "start";
      } else {
        return "end";
      }
    }

    return false;
  };

  /**
   * Рисует перпендикулярную стрелку от центра линии.
   * @param { CanvasRenderingContext2D } ctx - Контекст холста для отрисовки.
   * @param { Object } line - Объект линии.
   * @param draw
   */
  const drawPerpendicularArrow = (ctx, line, draw = true) => {
    const drawArrowhead = (from, to, headSize) => {
      const angle = Math.atan2(from.y - to.y, from.x - to.x) + Math.PI;
      const x1 = to.x - headSize * Math.cos(angle - Math.PI / 6);
      const y1 = to.y - headSize * Math.sin(angle - Math.PI / 6);
      const x2 = to.x - headSize * Math.cos(angle + Math.PI / 6);
      const y2 = to.y - headSize * Math.sin(angle + Math.PI / 6);

      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(to.x, to.y);
      ctx.lineTo(x2, y2);
      ctx.stroke();
    };

    const { startPoint, endPoint, arrow } = line;
    if (!arrow) {
      return;
    }
    const { arrowStartPoint, arrowEndPoint, arrowWidth, arrowColor, arrowHeadSize } =
      arrow;

    if (arrowStartPoint && arrowEndPoint && arrowWidth && arrowColor && arrowHeadSize) {
      ctx.strokeStyle = arrowColor;
      ctx.lineWidth = arrowWidth;
      ctx.beginPath();
      ctx.lineTo(arrowStartPoint.x, arrowStartPoint.y);
      ctx.lineTo(arrowEndPoint.x, arrowEndPoint.y);
      ctx.stroke();
      drawArrowhead(arrowStartPoint, arrowEndPoint, arrowHeadSize);
      return;
    }
    const { arrowLength, color, arrowheadSize } = arrow;
    const lineCenter = {
      x: (startPoint.x + endPoint.x) / 2,
      y: (startPoint.y + endPoint.y) / 2,
    };
    const dx = endPoint.x - startPoint.x;
    const dy = endPoint.y - startPoint.y;
    const lineLength = distance(startPoint, endPoint);
    const normalizedDx = dx / lineLength;
    const normalizedDy = dy / lineLength;

    // Вычисляем новую начальную точку стрелки
    const arrowStart = {
      x: +(lineCenter.x - (arrowLength / 2) * normalizedDy).toFixed(2),
      y: +(lineCenter.y + (arrowLength / 2) * normalizedDx).toFixed(2),
    };

    const arrowEnd = {
      x: +(lineCenter.x + (arrowLength / 2) * normalizedDy).toFixed(2),
      y: +(lineCenter.y - (arrowLength / 2) * normalizedDx).toFixed(2),
    };

    ctx.strokeStyle = color;
    ctx.lineWidth = 2;
    if (draw) {
      ctx.beginPath();
      ctx.lineTo(arrowStart.x, arrowStart.y);
      ctx.lineTo(arrowEnd.x, arrowEnd.y);
      ctx.stroke();

      // Рисуем наконечник стрелки
      drawArrowhead(arrowStart, arrowEnd, arrowheadSize);
    }
    return {
      startPointFinal: arrowStart,
      endPointFinal: arrowEnd,
      color: color,
      width: ctx.lineWidth,
    };
  };

  /**
   * Отслеживает изменения параметров изображения и рисует его на канвасе.
   * @param { string } photoUrl - Ссылка на изображение для создания разметки.
   * @param { Object } imagePosition - Позиция изображения.
   * @param { number } imagePosition.x - Координата x позиции изображения.
   * @param { number } imagePosition.y - Координата y позиции изображения.
   * @param { number } scale - Масштаб изображения.
   * @param { Array } markups - Массив линий на изображении.
   */
  useEffect(() => {
    if (!photoUrl) return;
    const img = new Image();
    img.src = photoUrl;
    img.onload = () => {
      !imageLoaded && setImageLoaded(true);

      const screenWidth = window.innerWidth * 0.9;
      const screenHeight = window.innerHeight * 0.9;

      const aspectRatio = img.width / img.height;
      let newWidth = img.width;
      let newHeight = img.height;

      // Ресайз больших изображений
      if (img.width > screenWidth || img.height > screenHeight) {
        if (img.width > img.height) {
          newWidth = screenWidth * 0.7;
          newHeight = newWidth / aspectRatio;
        } else {
          newHeight = screenHeight * 0.85;
          newWidth = newHeight * aspectRatio;
        }
      }
      // Ресайз маленьких изображений
      else if (img.width < screenWidth && img.height < screenHeight) {
        const scale = Math.min(screenWidth / img.width, screenHeight / img.height);
        newWidth = img.width * scale * 0.8;
        newHeight = img.height * scale * 0.8;
      }

      setImage({
        src: img.src,
        width: newWidth,
        height: newHeight,
        originWidth: img.width,
        originHeight: img.height,
      });

      if (!imageLoaded) return;

      offscreenCanvasRef.current.width = image.width;
      offscreenCanvasRef.current.height = image.height;
      const offscreenCtx = offscreenCanvasRef.current.getContext("2d");

      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      markupsDataFormat === "json"
        ? setMarkupsData(toJSON(markups, image))
        : setMarkupsData(toXML(markups, image));
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      offscreenCtx.drawImage(img, 0, 0, image.width, image.height);
      drawMarkups(offscreenCtx);
      ctx.drawImage(
        offscreenCanvasRef.current,
        imagePosition.x,
        imagePosition.y,
        canvas.width * scale,
        canvas.height * scale
      );
    };
  }, [photoUrl, imagePosition, scale, markups, imageLoaded]);

  /**
   * @param {React.MouseEvent<HTMLCanvasElement>} event - событие клика
   */
  const startDrawing = event => {
    if (event.buttons !== 1) return; // Только левая кнопка мыши

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const currentPoint = {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };

    const selectedMarkupIndex = markups.findIndex(markup => {
      const toleranceForLine = 12;
      const toleranceForEndpoint = 18;
      if (markup.type === "polygon") {
        const polygonPointsOnScreen = markup.points.map(point => ({
          x: point.x * scale + imagePosition.x,
          y: point.y * scale + imagePosition.y,
        }));

        const pointNearVertex = polygonPointsOnScreen.some(vertex => {
          return distance(currentPoint, vertex) <= toleranceForEndpoint;
        });

        const pointNearEdge = polygonPointsOnScreen.some((_, index) => {
          const startPoint = polygonPointsOnScreen[index];
          const endPoint =
            polygonPointsOnScreen[(index + 1) % polygonPointsOnScreen.length];
          return isPointNearLine(
            currentPoint,
            { startPoint, endPoint },
            toleranceForLine
          );
        });

        return pointNearVertex || pointNearEdge;
      } else {
        const startPointOnScreen = {
          x: markup.startPoint.x * scale + imagePosition.x,
          y: markup.startPoint.y * scale + imagePosition.y,
        };
        const endPointOnScreen = {
          x: markup.endPoint.x * scale + imagePosition.x,
          y: markup.endPoint.y * scale + imagePosition.y,
        };
        const rectOnScreen = {
          startPoint: startPointOnScreen,
          endPoint: endPointOnScreen,
        };
        if (markup.type === "rect") {
          return isPointOnRect(currentPoint, rectOnScreen);
        }
        const pointNearLine = isPointNearLine(currentPoint, rectOnScreen);
        const pointNearEndpoint = isPointNearEndpoint(currentPoint, rectOnScreen);

        return pointNearEndpoint || pointNearLine;
      }
    });

    if (selectedMarkupIndex !== -1 && markups[selectedMarkupIndex].type === "line") {
      setselectedMarkupIndex(selectedMarkupIndex);
      const selectedLine = markups[selectedMarkupIndex];
      const startPointOnScreen = {
        x: selectedLine.startPoint.x * scale + imagePosition.x,
        y: selectedLine.startPoint.y * scale + imagePosition.y,
      };
      const endPointOnScreen = {
        x: selectedLine.endPoint.x * scale + imagePosition.x,
        y: selectedLine.endPoint.y * scale + imagePosition.y,
      };

      const arrowStartPointOnScreen = {
        x: selectedLine.arrow.arrowStartPoint.x * scale + imagePosition.x,
        y: selectedLine.arrow.arrowStartPoint.y * scale + imagePosition.y,
      };
      const arrowEndPointOnScreen = {
        x: selectedLine.arrow.arrowEndPoint.x * scale + imagePosition.x,
        y: selectedLine.arrow.arrowEndPoint.y * scale + imagePosition.y,
      };

      if (
        distance(currentPoint, arrowStartPointOnScreen) <= 12 ||
        distance(currentPoint, arrowEndPointOnScreen) <= 12
      ) {
        const newMarkups = markups.slice();
        const newLine = newMarkups[selectedMarkupIndex];

        // Получаем середину линии
        const midPoint = {
          x: (newLine.startPoint.x + newLine.endPoint.x) / 2,
          y: (newLine.startPoint.y + newLine.endPoint.y) / 2,
        };

        // Вычисляем направление стрелки
        const arrowDirection = {
          x: newLine.arrow.arrowEndPoint.x - newLine.arrow.arrowStartPoint.x,
          y: newLine.arrow.arrowEndPoint.y - newLine.arrow.arrowStartPoint.y,
        };

        // Нормализуем направление стрелки
        const arrowLength = Math.sqrt(
          arrowDirection.x * arrowDirection.x + arrowDirection.y * arrowDirection.y
        );
        const normalizedArrowDirection = {
          x: arrowDirection.x / arrowLength,
          y: arrowDirection.y / arrowLength,
        };

        // Инвертируем направление стрелки
        const invertedArrowDirection = {
          x: -normalizedArrowDirection.x,
          y: -normalizedArrowDirection.y,
        };

        // Обновляем начальную и конечную точки стрелки с учётом нового направления
        newLine.arrow.arrowStartPoint.x =
          midPoint.x - (arrowLength / 2) * invertedArrowDirection.x;
        newLine.arrow.arrowStartPoint.y =
          midPoint.y - (arrowLength / 2) * invertedArrowDirection.y;
        newLine.arrow.arrowEndPoint.x =
          midPoint.x + (arrowLength / 2) * invertedArrowDirection.x;
        newLine.arrow.arrowEndPoint.y =
          midPoint.y + (arrowLength / 2) * invertedArrowDirection.y;

        setMarkups(newMarkups);
      } else if (distance(currentPoint, startPointOnScreen) <= 20) {
        setSelectedPoint("start");
      } else if (distance(currentPoint, endPointOnScreen) <= 20) {
        setSelectedPoint("end");
      } else if (
        isPointNearLine(currentPoint, selectedLine, (scale / (scale / 100)) * 50)
      ) {
        // Не спрашивайте почему такой расчет
        setSelectedPoint("line");
      }
    } else if (markups[selectedMarkupIndex]?.type === "rect") {
      const rectCollisionPoint = isPointOnRect(
        currentPoint,
        markups[selectedMarkupIndex]
      );
      setSelectedPoint(rectCollisionPoint);
      setselectedMarkupIndex(selectedMarkupIndex);
    } else if (markups[selectedMarkupIndex]?.type === "polygon" && !isPolygonDrawing) {
      setSelectedPoint("poly-point");
      setselectedMarkupIndex(selectedMarkupIndex);
    } else if (selectedTool === "polygon") {
      const Point = {
        x: +((currentPoint.x - imagePosition.x) / scale).toFixed(2),
        y: +((currentPoint.y - imagePosition.y) / scale).toFixed(2),
      };
      if (!isPolygonDrawing) {
        setIsPolygonDrawing(true);
        setCurrentPolygon([...currentPolygon, Point]);
        setMarkups([
          ...markups,
          {
            class: selectedClass.name,
            points: currentPolygon,
            type: "polygon",
            color: selectedClass.color,
            width: lineWidth,
          },
        ]);
      } else {
        const firstPoint = currentPolygon[0];
        const isClickOnFirstPoint = distance(Point, firstPoint) <= 20;

        if (isClickOnFirstPoint) {
          const newMarkups = [...markups];
          newMarkups[newMarkups.length - 1].points = [...currentPolygon, firstPoint];
          setHistory([...history, { markups, scale, imagePosition, actionType: "draw" }]);
          setMarkups(newMarkups);
          setIsPolygonDrawing(false);
          setCurrentPolygon([]);
        } else {
          const newMarkups = [...markups];
          newMarkups[newMarkups.length - 1].points = [...currentPolygon, Point];
          setMarkups(newMarkups);
          setCurrentPolygon([...currentPolygon, Point]);
        }
      }
    } else {
      setIsDrawing(true);
      setStartPoint(currentPoint);
      setselectedMarkupIndex(null);
      setSelectedPoint(null);
    }
  };

  /**
   * @param {React.MouseEvent<HTMLCanvasElement>} event - событие клика
   */
  const startMoving = event => {
    if (event.buttons !== 2) return; // Только правая кнопка мыши
    setIsMoving(true);
    setLastImagePosition({ x: event.clientX, y: event.clientY });
  };

  /**
   * @param {React.MouseEvent<HTMLCanvasElement>} event - событие клика
   */
  const continueDrawing = event => {
    const updateArrow = line => {
      const { startPoint, endPoint, arrow } = line;
      const lineVector = {
        x: endPoint.x - startPoint.x,
        y: endPoint.y - startPoint.y,
      };

      const isVertical = Math.abs(lineVector.x) < Math.abs(lineVector.y);
      let direction;

      if (isVertical) {
        direction = lineVector.y >= 0 ? 1 : -1;
      } else {
        direction = lineVector.x >= 0 ? 1 : -1;
      }

      const lineLength = Math.sqrt(
        lineVector.x * lineVector.x + lineVector.y * lineVector.y
      );
      const normalizedLineVector = {
        x: lineVector.x / lineLength,
        y: lineVector.y / lineLength,
      };

      const perpendicularVector = {
        x: -normalizedLineVector.y * direction,
        y: normalizedLineVector.x * direction,
      };

      const arrowMidPoint = {
        x: (startPoint.x + endPoint.x) / 2,
        y: (startPoint.y + endPoint.y) / 2,
      };

      const arrowLength = Math.sqrt(
        Math.pow(arrow.arrowEndPoint.x - arrow.arrowStartPoint.x, 2) +
          Math.pow(arrow.arrowEndPoint.y - arrow.arrowStartPoint.y, 2)
      );

      const arrowStartPoint = {
        x: arrowMidPoint.x + (arrowLength / 2) * perpendicularVector.x,
        y: arrowMidPoint.y + (arrowLength / 2) * perpendicularVector.y,
      };
      const arrowEndPoint = {
        x: arrowMidPoint.x - (arrowLength / 2) * perpendicularVector.x,
        y: arrowMidPoint.y - (arrowLength / 2) * perpendicularVector.y,
      };

      if (isVertical) {
        if (direction === 1) {
          arrow.arrowStartPoint = arrowStartPoint;
          arrow.arrowEndPoint = arrowEndPoint;
        } else {
          arrow.arrowStartPoint = arrowEndPoint;
          arrow.arrowEndPoint = arrowStartPoint;
        }
      } else {
        if (direction === 1) {
          arrow.arrowStartPoint = arrowStartPoint;
          arrow.arrowEndPoint = arrowEndPoint;
        } else {
          arrow.arrowStartPoint = arrowEndPoint;
          arrow.arrowEndPoint = arrowStartPoint;
        }
      }
    };

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const endPoint = {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };

    if (isDrawing || isPolygonDrawing) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(
        offscreenCanvasRef.current,
        imagePosition.x,
        imagePosition.y,
        canvas.width * scale,
        canvas.height * scale
      );

      ctx.strokeStyle = selectedClass.color;
      ctx.lineWidth = lineWidth;

      ctx.beginPath();
      if (selectedTool === "rect") {
        ctx.setLineDash([15, 15]); // Добавить эту строку для прерывистой линии ???
        ctx.rect(
          startPoint.x,
          startPoint.y,
          endPoint.x - startPoint.x,
          endPoint.y - startPoint.y
        );
        ctx.stroke();
        ctx.setLineDash([]);
      } else if (selectedTool === "polygon" && isPolygonDrawing) {
        if (currentPolygon.length > 0) {
          ctx.moveTo(
            currentPolygon[currentPolygon.length - 1].x * scale + imagePosition.x,
            currentPolygon[currentPolygon.length - 1].y * scale + imagePosition.y
          );
          ctx.lineTo(endPoint.x, endPoint.y);
          ctx.stroke();
          drawPoints(
            ctx,
            currentPolygon.map(point => ({
              x: point.x * scale + imagePosition.x,
              y: point.y * scale + imagePosition.y,
            })),
            selectedClass.color
          );
        }
      } else {
        ctx.moveTo(startPoint.x, startPoint.y);
        ctx.lineTo(endPoint.x, endPoint.y);
        ctx.stroke();
        drawPoints(ctx, [startPoint, endPoint], selectedClass.color);
        drawPerpendicularArrow(ctx, {
          startPoint: startPoint,
          endPoint: endPoint,
          color: selectedClass.color,
          width: lineWidth,
          arrow: {
            arrowLength: 25,
            color: "red",
            arrowheadSize: 6,
          },
        });
      }
    } else if (selectedMarkupIndex !== null && selectedPoint) {
      const newMarkups = markups.slice();
      const selectedLine = newMarkups[selectedMarkupIndex];
      const newPoint = {
        x: +((endPoint.x - imagePosition.x) / scale).toFixed(2),
        y: +((endPoint.y - imagePosition.y) / scale).toFixed(2),
      };

      if (selectedPoint === "start") {
        selectedLine.startPoint = newPoint;
        updateArrow(selectedLine);
      } else if (selectedPoint === "end") {
        selectedLine.endPoint = newPoint;
        updateArrow(selectedLine);
      } else if (selectedPoint === "line") {
        const delta = {
          x: +(
            newPoint.x -
            (selectedLine.startPoint.x + selectedLine.endPoint.x) / 2
          ).toFixed(2),
          y: +(
            newPoint.y -
            (selectedLine.startPoint.y + selectedLine.endPoint.y) / 2
          ).toFixed(2),
        };
        selectedLine.startPoint.x += delta.x;
        selectedLine.startPoint.y += delta.y;
        selectedLine.endPoint.x += delta.x;
        selectedLine.endPoint.y += delta.y;
        selectedLine.arrow.arrowStartPoint.x += delta.x;
        selectedLine.arrow.arrowStartPoint.y += delta.y;
        selectedLine.arrow.arrowEndPoint.x += delta.x;
        selectedLine.arrow.arrowEndPoint.y += delta.y;
      } else if (selectedPoint === "poly-point") {
        const polygon = newMarkups[selectedMarkupIndex].points;
        const pointIndex = polygon.findIndex(point => {
          const pointOnScreen = {
            x: point.x * scale + imagePosition.x,
            y: point.y * scale + imagePosition.y,
          };
          return distance(endPoint, pointOnScreen) <= 20;
        });

        if (pointIndex === 0 || pointIndex === polygon.length - 1) {
          // Если мы перемещаем первую или последнюю точку полигона, убедимся, что они "приклеены" друг к другу
          polygon[0] = newPoint;
          polygon[polygon.length - 1] = newPoint;
        } else {
          polygon[pointIndex] = newPoint;
        }
      } else {
        const rectMoveResult = selectedPoint;

        if (rectMoveResult) {
          const delta = {
            x: +(
              newPoint.x -
              (selectedLine.startPoint.x + selectedLine.endPoint.x) / 2
            ).toFixed(2),
            y: +(
              newPoint.y -
              (selectedLine.startPoint.y + selectedLine.endPoint.y) / 2
            ).toFixed(2),
          };

          switch (rectMoveResult) {
            case "left-upper-point":
              selectedLine.startPoint.x = newPoint.x;
              selectedLine.startPoint.y = newPoint.y;
              break;
            case "left-lower-point":
              selectedLine.startPoint.x = newPoint.x;
              selectedLine.endPoint.y = newPoint.y;
              break;
            case "right-upper-point":
              selectedLine.endPoint.x = newPoint.x;
              selectedLine.startPoint.y = newPoint.y;
              break;
            case "right-lower-point":
              selectedLine.endPoint.x = newPoint.x;
              selectedLine.endPoint.y = newPoint.y;
              break;
            case "edge":
              selectedLine.startPoint.x += delta.x;
              selectedLine.startPoint.y += delta.y;
              selectedLine.endPoint.x += delta.x;
              selectedLine.endPoint.y += delta.y;
              break;
          }
        }
      }
      setMarkups(newMarkups);
    }
  };

  /**
   * @param { React.MouseEvent<HTMLCanvasElement> } event - Событие клика
   */
  const continueMoving = event => {
    if (!isMoving) return;
    const deltaX = event.clientX - lastImagePosition.x;
    const deltaY = event.clientY - lastImagePosition.y;
    setImagePosition({
      x: imagePosition.x + deltaX,
      y: imagePosition.y + deltaY,
    });
    setLastImagePosition({ x: event.clientX, y: event.clientY });
  };

  const stopDrawing = event => {
    if (isDrawing) {
      const canvas = canvasRef.current.getContext("2d");
      const offscreenCtx = offscreenCanvasRef.current.getContext("2d");
      const rect = canvasRef.current.getBoundingClientRect();
      const endPoint = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };

      const startPointOffscreen = {
        x: +((startPoint.x - imagePosition.x) / scale).toFixed(2),
        y: +((startPoint.y - imagePosition.y) / scale).toFixed(2),
      };
      const endPointOffscreen = {
        x: +((endPoint.x - imagePosition.x) / scale).toFixed(2),
        y: +((endPoint.y - imagePosition.y) / scale).toFixed(2),
      };

      if (
        startPointOffscreen.x > offscreenCtx.canvas.width ||
        startPointOffscreen.y > offscreenCtx.canvas.height
      ) {
        setIsDrawing(false);
        setMarkups(markups.slice(0, markups.length));
        return alert("За границей полотна нельзя наносить разметку");
      } else if (
        endPointOffscreen.x > offscreenCtx.canvas.width ||
        endPointOffscreen.y > offscreenCtx.canvas.height
      ) {
        setIsDrawing(false);
        setMarkups(markups.slice(0, markups.length));
        return alert("За границей полотна нельзя наносить разметку");
      } else if (
        startPointOffscreen.x < 0 ||
        startPointOffscreen.y < 0 ||
        endPointOffscreen.x < 0 ||
        endPointOffscreen.y < 0
      ) {
        setIsDrawing(false);
        setMarkups(markups.slice(0, markups.length));
        return alert("За границей полотна нельзя наносить разметку");
      }

      offscreenCtx.strokeStyle = selectedClass.color;
      offscreenCtx.lineWidth = lineWidth;

      offscreenCtx.beginPath();

      if (selectedTool === "rect") {
        offscreenCtx.setLineDash([15, 15]);
        offscreenCtx.rect(
          startPointOffscreen.x,
          startPointOffscreen.y,
          endPointOffscreen.x - startPointOffscreen.x,
          endPointOffscreen.y - startPointOffscreen.y
        );
        offscreenCtx.stroke();
        offscreenCtx.setLineDash([]);

        setMarkups([
          ...markups,
          {
            class: selectedClass.name,
            startPoint: startPointOffscreen,
            endPoint: endPointOffscreen,
            color: selectedClass.color,
            width: lineWidth,
            type: "rect",
            lineDash: [15, 15],
          },
        ]);
      } else {
        offscreenCtx.moveTo(startPointOffscreen.x, startPointOffscreen.y);
        offscreenCtx.lineTo(endPointOffscreen.x, endPointOffscreen.y);
        offscreenCtx.stroke();

        canvas.strokeStyle = selectedClass.color;
        canvas.lineWidth = lineWidth;

        const {
          startPointFinal: arrowStartPoint,
          width: arrowWidth,
          color: arrowColor,
          endPointFinal: arrowEndPoint,
        } = drawPerpendicularArrow(
          canvas,
          {
            startPoint: startPointOffscreen,
            endPoint: endPointOffscreen,
            color: selectedClass.color,
            width: lineWidth,
            arrow: {
              arrowLength: 25,
              color: "red",
              arrowheadSize: 6,
            },
          },
          false
        );

        setMarkups([
          ...markups,
          {
            class: selectedClass.name,
            startPoint: startPointOffscreen,
            endPoint: endPointOffscreen,
            color: selectedClass.color,
            width: lineWidth,
            arrow: {
              arrowStartPoint,
              arrowEndPoint,
              arrowWidth,
              arrowColor,
              arrowHeadSize: 6,
            },
            type: "line",
          },
        ]);
      }

      setHistory([...history, { markups, scale, imagePosition, actionType: "draw" }]);

      setIsDrawing(false);
    } else if (selectedMarkupIndex !== null) {
      setselectedMarkupIndex(null);
      setSelectedPoint(null);
    }
  };

  const undoLastAction = () => {
    if (history.length === 0) return;

    const lastAction = history[history.length - 1];
    const newHistory = history.slice(0, -1);
    setHistory(newHistory);

    switch (lastAction.actionType) {
      case "draw":
        removeLastLine();
        break;
      case "update":
        setMarkups(newHistory[newHistory.length - 1]?.markups || []);
        break;
      case "scale":
        setScale(newHistory[newHistory.length - 1]?.scale || 1);
        break;
      case "move":
        setImagePosition(
          newHistory[newHistory.length - 1]?.imagePosition || { x: 0, y: 0 }
        );
        break;
      default:
        break;
    }
  };

  const handleWheel = event => {
    const newScale = scale + event.deltaY * -0.001;
    const clampedScale = Math.max(0.1, newScale);
    setScale(clampedScale);
    setHistory([...history, { markups, scale, imagePosition, actionType: "scale" }]);
  };

  const removeLastLine = () => setMarkups(markups.slice(0, -1));

  const clearMarkups = () => setMarkups([]);

  return (
    <Section title={`${mode === "edit" ? "Редактирование" : "Создание"} разметки`}>
      <div className={"markup-container"}>
        <div
          className="control-panel"
          style={{
            left: "20px",
            height: "600px",
            width: "310px",
          }}
        >
          <div className={"panel-controls"}>
            <button
              className={`control-button ${
                contentMode === "classes" ? "selected" : ""
              } switch-button`}
              onClick={() => setContentMode("classes")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="4" width="16" height="2" fill="currentColor" />
                <rect x="4" y="11" width="16" height="2" fill="currentColor" />
                <rect x="4" y="18" width="16" height="2" fill="currentColor" />
              </svg>
              Локации
            </button>
            <button
              className={`control-button ${
                contentMode === "code" ? "selected" : ""
              } switch-button`}
              onClick={() => setContentMode("code")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 8L3 12L8 16"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 8L21 12L16 16"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Данные
            </button>
          </div>
          {contentMode === "code" && (
            <>
              <div className={"panel-controls"}>
                <button
                  className={`control-button ${
                    markupsDataFormat === "json" ? "selected" : ""
                  }`}
                  onClick={() => {
                    markupsDataFormat === "xml" && setMarkupsData(toJSON(markups, image));
                    setMarkupsDataFormat("json");
                  }}
                >
                  JSON
                </button>
                <button
                  className={`control-button ${
                    markupsDataFormat === "xml" ? "selected" : ""
                  }`}
                  onClick={() => {
                    markupsDataFormat === "json" && setMarkupsData(toXML(markups, image));
                    setMarkupsDataFormat("xml");
                  }}
                >
                  XML
                </button>
              </div>
              <Textarea value={markupsData} disabled={true} copy={true} resize={false} />
            </>
          )}
          {contentMode === "classes" && (
            <>
              <div className="class-list"> {classListItems}</div>
              <div className="new-class-form">
                <input
                  type="text"
                  placeholder="Название локации"
                  value={newClassName}
                  onChange={e => setNewClassName(e.target.value)}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <button onClick={() => handleAddClass(true)}>Добавить счетчик</button>
                  <button onClick={() => handleAddClass(false)}>Добавить зону</button>
                </div>
                <div className={"circle-picker-wrapper"}>
                  <CirclePicker
                    colors={[
                      "#000000",
                      "#ff0000",
                      "#ffa500",
                      "#ffff00",
                      "#008000",
                      "#55ff00",
                      "#0000ff",
                      "#008cff",
                      "#4b0082",
                      "#00ffff",
                      "#ff69b4",
                      "#ffffff",
                    ]}
                    color={newClassColor}
                    onChangeComplete={color => setNewClassColor(color.hex)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {imageLoaded && image?.src ? (
          <canvas
            className={"markup-canvas"}
            ref={canvasRef}
            width={image.width}
            height={image.height}
            onMouseDown={startDrawing}
            onMouseMove={continueDrawing}
            onMouseUp={stopDrawing}
            onMouseLeave={stopDrawing}
            onWheel={handleWheel}
            onMouseDownCapture={startMoving}
            onMouseMoveCapture={continueMoving}
            onMouseUpCapture={stopMoving}
            onContextMenu={e => e.preventDefault()}
            onPointerMove={e => updateCursor(e)}
          />
        ) : (
          <LoadingSpinner />
        )}
        <div className={"control-panel"}>
          <div className={"panel-title"}>Инструменты</div>
          <div className={"panel-instruments"}>
            <button
              className={`tool_button ${selectedTool === "line" ? "active" : ""} ${
                selectedClass.type !== "line" && selectedClass.type !== "all"
                  ? "inactive"
                  : ""
              }`}
              onClick={() => {
                (selectedClass.type === "line" || selectedClass.type === "all") &&
                  setSelectedTool("line");
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="2"
                  y1="22"
                  x2="22"
                  y2="2"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div>Линия счётчика</div>
            </button>
            <button
              className={`tool_button ${selectedTool === "rect" ? "active" : ""} ${
                selectedClass.type === "line" ? "inactive" : ""
              }`}
              onClick={() => {
                (selectedClass.type === "polygon" || selectedClass.type === "all") &&
                  setSelectedTool("rect");
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="2"
                  y="2"
                  width="20"
                  height="20"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeDasharray="4 4"
                />
              </svg>
              <div>Зона</div>
            </button>
            <button
              className={`tool_button ${selectedTool === "polygon" ? "active" : ""} ${
                selectedClass.type === "line" ? "inactive" : ""
              }`}
              onClick={() => {
                (selectedClass.type === "polygon" || selectedClass.type === "all") &&
                  setSelectedTool("polygon");
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2L22 8.5L18 21H6L2 8.5L12 2Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div>Полигон</div>
            </button>
          </div>
          <div className={"panel-title"}>Ширина линии</div>
          <input
            className={"line-width-slider"}
            type={"range"}
            min={"1"}
            max={"50"}
            value={lineWidth}
            onChange={handleLineWidthChange}
          />
          <button className={"cpanel-button warn"} onClick={clearMarkups}>
            Очистить поле
          </button>
          <button
            className={"cpanel-button"}
            onClick={() => {
              const markup = {
                image,
                markups,
                classList,
              };

              mode === "add"
                ? popup.showPopup("markupAdd", { markup })
                : popup.showPopup("markupEdit", {
                    id: markupId,
                    markup,
                  });
            }}
          >
            Сохранить
          </button>
        </div>
      </div>
    </Section>
  );
};

export default MarkupDrawTool;
