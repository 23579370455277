import "./modelsManagerPage.scss";
import { useEffect, useState } from "react";
import cssIf from "../../../scripts/helpers/class.add.if";
import Selectfield from "../../components/selectfield";
import useGlobal from "../../../store";
import Textfield from "../../components/textfield";
import Section from "../../components/containers/section";
import { all as getModels } from "../../../store/actions/models";
import { ReactComponent as PlusSign } from "../../../images/icons/+.svg";
import cookie from "react-cookies";
import { all as AllCameras } from "../../../store/actions/cameras";
import { all as AllEventTypes } from "../../../store/actions/event.types";
import { all as AllModels } from  "../../../store/actions/models";
import { all as AllEventCategories } from "../../../store/actions/event.categories";
import { childMarkups as AllChildMarkups } from "../../../store/actions/user.markups";
// import { all as AllEventCategories } from "../../../store/actions/event.categories";

const FormGenerator = ({ form, config = false, id = false }) => {
  // form - JSON с структурой формы (поля, типы, названия)
  // config - если редактирование, то объект с конфигурацией (значениями полей)
  // id - если редактирование, то id конфигурации

  const icons = {
    add: <PlusSign />,
  };

  const DATA_LOADERS = {
    // TODO: Добавить загрузку данных из БД
    eventTypes: async () => {
      return await AllEventTypes()
    },
    eventCategories: async () => {
      return await AllEventCategories()
    },
    cameras: async () => {
      return await AllCameras({ token: cookie.load("token"), reload: null });
    },
    locations: () => {},
    markups: async () => {
      return await AllChildMarkups();
    },
    models: async () => {
      return await AllModels()
    },
  };

  const [globalState, globalActions] = useGlobal();

  const [fullyLoaded, setFullyLoaded] = useState(false);

  const [checkSum, setCheckSum] = useState(0);

  const { selectedModel, selectedTemplate, modelConfigurationName } = globalState;

  const { models } = globalActions;

  const [Form, setForm] = useState({});

  const [formValid, setFormValid] = useState(false);

  // Добавьте новое состояние для хранения данных, загруженных из data_loader
  const [dataLoaderResults, setDataLoaderResults] = useState({});

  // Функция для загрузки данных с помощью data_loader (если есть) и сохранения их в состоянии
  const loadData = async dataLoaderKey => {
    if (DATA_LOADERS[dataLoaderKey]) {
      const data = await DATA_LOADERS[dataLoaderKey]();
      setDataLoaderResults(prevState => ({
        ...prevState,
        [dataLoaderKey]: data,
      }));
      return true;
    }
  };

  // Вызовите loadData для каждого data_loader в форме
  useEffect(() => {
    const promises = [];

    for (let section in form) {
      for (let item of form[section].rows) {
        if (item?.data_loader && DATA_LOADERS[item.data_loader]) {
          promises.push(loadData(item.data_loader));
        }
      }
    }

    Promise.all(promises).then(() => {
      console.log("All data loaded:", dataLoaderResults); // Добавьте эту строку для вывода данных
      setFullyLoaded(true);
    });

  }, [form]);

  function GenerateResultForm() {
    // Заполнение результата из формы (значения по умолчанию
    const newForm = {};

    for (let section in form) {
      for (let item of form[section].rows) {
        if (config) {
          return setForm(config);
        } else {
          if (form[section].result === "plain") {
            newForm[item.id] = {
              value: item.default,
              required: item.required,
            };
          } else {
            if (!newForm[form[section].result]) {
              newForm[form[section].result] = [
                {
                  [item.id]: {
                    value: item.default,
                    required: item.required,
                  },
                },
              ];
            } else {
              newForm[form[section].result][0][item.id] = {
                value: item.default,
                required: item.required,
              };
            }
          }
        }
      }
    }
    setForm(newForm);
  }

  function addSectionToResult(result_method, index) {
    const newForm = { ...Form };
    newForm[result_method][index] = { ...newForm[result_method][index - 1] };
    setForm(newForm);
  }

  if (Object.keys(Form).length === 0) {
    GenerateResultForm();
  }

  const isFormValidated = () => {
    // Function that validate form
    // deep search for required fields in form
    for (let object in Form) {
      if (Array.isArray(Form[object])) {
        for (let item of Form[object]) {
          for (let key in item) {
            if (item[key].required && item[key].value === "") {
              return false;
            }
          }
        }
      } else {
        for (let key in Form[object]) {
          if (Form[object][key].required && Form[object][key].value === "") {
            return false;
          }
        }
      }
    }

    return true;
  };

  useEffect(() => {
    setFormValid(isFormValidated());
  }, [Form]);

  const sendForm = () => {
    const reload = () => {
      // window.location.href = "/models";
    };

    const data = {
      id: id,
      model_id: selectedModel.id,
      model_type: selectedTemplate.model_type_id,
      name: modelConfigurationName,
      config: Form,
      template_id: selectedTemplate.id,
    };

    if (formValid && data.name.length > 0) {
      // if (config) {
      //   return models.editConfiguration(data, reload);
      // }
      console.log(data.config);
      console.log(data.config.markups_info[0].markup.value.name);
      // models.addConfiguration(data, reload);
    } else {
      alert("Заполните все обязательные поля");
    }
  };
  // Проверка является ли объект скрытым или зависящим от другого поля
  const isHidden = (id, result_method, index) => {
    if (id) {
      if (result_method === "plain") {
        return !Form[id].value;
      } else {
        return !Form[result_method][index][id].value;
      }
    }
  };

  useEffect(() => {
    // Принудительная перерисовка компонента
  }, [checkSum]);

  function updateForm(e, form, key, item, index, action) {
    // Обновление JSON-результата в зависимости от категории результата
    // если result секции (form[key]) = 'plain', то просто обновляем значение поля
    // если result секции (form[key]) = '*' (любое значение), то идем по следующему сценарию:
    // 1) Если по пути Form[form[key].result] нет значения, то создаем его и присваиваем [{}] (массив с словарем)
    // 2) Если по пути Form[form[key].result] есть значение, то проверяем, есть ли в нем словарь с таким же id (item.id)
    // 3) Если есть, то обновляем значение, если нет, то добавляем новый словарь в массив
    // 4) Если по пути Form[form[key].result] есть значение, это массив и в нем есть словарь, устанавливаем значение в словарь
    // TODO Т.к Form[form[key].result] - массив, то также должна быть индексация в зависимости от группы секции (index)
    const selectedIndex = action === "checkbox" ? e.target?.checked : parseInt(e.target.value);
    const options = Array.isArray(item.options)
      ? item.options
      : dataLoaderResults[item?.data_loader];
    const selectedOption = action === "checkbox" || action === "input" ? selectedIndex : options[selectedIndex];
    if (form[key].result === "plain") {
      setForm({
        ...Form,
        [item.id]: {
          value: selectedOption,
          required: item.required,
        },
      });
    } else {
      if (Form[form[key].result] === undefined) {
        Form[form[key].result] = [{}];
        Form[form[key].result][index] = {
          ...Form[form[key].result][index],
          [item.id]: {
            value: selectedOption,
            required: item.required,
          },
        };
        setForm(Form);
      } else {
        if (Form[form[key].result][index] === undefined) {
          setForm({
            ...Form,
            [form[key].result[index]]: [],
          });
        }
        Form[form[key].result][index] = {
          ...Form[form[key].result][index],
          [item.id]: { value: selectedOption, required: item.required },
        };
        setForm(Form);
      }
    }
    setCheckSum(checkSum + 1);
  }

  return (
    <>
      <Section scrollable={true} className={"models-manager-form"}>
        {Object.keys(Form).length > 0 && fullyLoaded &&
          Object.keys(form).map(
            (
              key,
              i
            ) => (
              <Section
                key={i}
                title={key.split("__")[0]}
                customAction={() => {
                  if (form[key].repeat === undefined) {
                    // force rerender of component
                    setCheckSum(checkSum + 1);
                    addSectionToResult(form[key].result, 1);
                    setForm({ ...Form, ["repeat__" + key]: 2 });
                    return (form[key].repeat = 2);
                  }
                  setCheckSum(checkSum + 1);
                  addSectionToResult(form[key].result, form[key].repeat);
                  form[key].repeat += 1;
                  setForm({ ...Form, ["repeat__" + key]: form[key].repeat });
                }}
                customActionIcon={icons[key.split("__")[1]]}
                id={checkSum}
              >
                {Array.from(
                  Array(
                    form[key]?.repeat
                      ? form[key].repeat
                      : Form["repeat__" + key] !== undefined
                      ? Form["repeat__" + key]
                      : 1
                  ).keys()
                ).map((item, i) =>
                  form[key].rows.map((item, index) => (
                    <div
                      className="models-manager-form-item"
                      key={index}
                      style={{
                        display: isHidden(item.hidden, form[key].result, i)
                          ? "none"
                          : "grid",
                      }}
                    >
                      <div className="models-manager-form-item-title" id={item.id}>
                        {item.title}
                      </div>

                      {item.type === "input" && (
                        <input
                          type="text"
                          className="models-manager-form-item-input"
                          defaultValue={
                            form[key].result === "plain"
                              ? Form[item.id].value
                              : Form[form[key].result][i][item.id].value
                          }
                          onChange={e => {
                            updateForm(e, form, key, item, i, "input");
                          }}
                        />
                      )}

                      {item.type === "checkbox" && (
                        <input
                          type="checkbox"
                          className={`models-manager-form-item-checkbox`}
                          onChange={e => {
                            updateForm(e, form, key, item, i, "checkbox");
                          }}
                          checked={
                            form[key].result === "plain"
                              ? Form[item.id].value
                              : Form[form[key].result][i][item.id].value
                          }
                        />
                      )}
                      {item.type === "select" && (
                        <select
                          className="models-manager-form-item-select"
                          onChange={e => {
                            updateForm(e, form, key, item, i, "select");
                          }}
                        >
                          {(Array.isArray(item.options) || Array.isArray(dataLoaderResults[item?.data_loader])) &&
                            (() => {
                              const options = Array.isArray(item.options)
                                ? item.options
                                : dataLoaderResults[item?.data_loader];
                              const dependencyValue = item.filter &&
                                form[key].result === "plain"
                                ? Form[item.filter.dependency]?.value
                                : Form[form[key].result] && item.filter && Form[form[key].result][i][item.filter.dependency].value[item.filter.filter_value];

                              return options.map((option, index) => {
                                const optionValue = item.filter ? option[item.filter.filter_option] : item.options;
                                const isOptionObject = typeof option === "object";
                                const label = isOptionObject ? option[item.option_label_key || "comment"] : option;
                                return (
                                  <option key={index} value={index} disabled={item.filter && optionValue !== dependencyValue}>
                                    {label}
                                  </option>
                                );
                              });
                            })()}
                        </select>
                      )}

                      <div className="models-manager-form-item-description">
                        {item.description}
                      </div>

                      <div
                        className={`models-manager-form-item-required${cssIf(
                          item.required,
                          "-yes",
                          "-no"
                        )}`}
                      >
                        {item.required && (
                          <span className="models-manager-form-item-required-text">
                            Обязательное поле
                          </span>
                        )}
                        {!item.required && (
                          <span className="models-manager-form-item-required-text">
                            Необязательное поле
                          </span>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </Section>
            )
          )}
      </Section>
      <div className="models-manager-form-submit">
        <button className={`models-manager-form-submit-button`} onClick={sendForm}>
          Отправить
        </button>
      </div>
    </>
  );
};

const ModelsManagerPage = () => {
  // Component that generate forms from JSON

  const [globalState, globalActions] = useGlobal();

  const {
    modelsTemplates,
    modelsTemplatesLoaded,
    modelTemplate,
    modelTemplateLoaded,
    modelConfiguration,
    modelConfigurationLoaded,
  } = globalState;

  const { models, changeState } = globalActions;

  // get id param from url
  const ConfigurationId = window.location.href.includes("?id")
    ? window.location.href.split("?")[1].split("=")[1]
    : null;

  useEffect(() => {
    ConfigurationId && models.getConfiguration(ConfigurationId);
  }, [ConfigurationId]);

  const [selectedTemplate, setSelectedTemplate] = useState(
    modelConfiguration.modelTemplate || ""
  ); // State for selected template
  const [configurationName, setConfigurationName] = useState(
    modelConfiguration.ConfigurationName || ""
  ); // State for configuration name
  const [selectedModel, setSelectedModel] = useState(
    modelConfiguration.SelectedModel || ""
  ); // State for selected model
  const [modelsArray, setModelsArray] = useState([]); // State for models

  useEffect(() => {
    if (modelConfigurationLoaded) {
      setSelectedTemplate({
        id: modelConfiguration.template.id,
        name: modelConfiguration.template.name,
        model_type_id: modelConfiguration.template.model_type_id,
      });
      setConfigurationName(modelConfiguration.name);
      setSelectedModel(modelConfiguration.model);
    }
  }, [modelConfigurationLoaded]);

  useEffect(() => {
    changeState("selectedModel", selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    changeState("selectedTemplate", selectedTemplate);
  }, [selectedTemplate]);

  useEffect(() => {
    changeState("modelConfigurationName", configurationName);
  }, [configurationName]);

  useEffect(() => {
    // Шаблоны конфигураций
    !modelsTemplatesLoaded && models.allTemplates();
  }, [modelsTemplatesLoaded]);

  useEffect(() => {
    // Получение самого шаблона
    selectedTemplate && !modelTemplateLoaded && models.getTemplate(selectedTemplate.id);
  }, [selectedTemplate]);

  useEffect(() => {
    // Получение моделей
    getModels(setModelsArray);
  }, []);

  return (
    <div className="models-manager-container">
      <div className={"models-manager-form-header"}>
        <div className={"models-manager-form-header-item"}>
          <Textfield
            value={configurationName}
            title={"Название конфигурации"}
            set={setConfigurationName}
          />
        </div>

        <div className={"models-manager-form-header-item"}>
          <Selectfield
            title={"Выбор шаблона"}
            list={modelsTemplates}
            select={e => {
              setSelectedTemplate(e);
            }}
            selected={selectedTemplate}
            listContainerMarginTop={10}
          />
        </div>
        <div className={"models-manager-form-header-item"}>
          <Selectfield
            title={"Выбор модели"}
            list={modelsArray}
            select={e => {
              setSelectedModel(e);
            }}
            selected={selectedModel}
            listContainerMarginTop={10}
          />
        </div>
      </div>

      <div className={"horizontal-line-box"}>
        <div className={"horizontal-line"} />
      </div>
      {modelTemplateLoaded && (
        <FormGenerator
          form={modelTemplate}
          config={modelConfiguration.config}
          id={modelConfiguration.id}
        />
      )}
    </div>
  );
};

export default ModelsManagerPage;
