import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

export async function all(set, setLoaded) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из all EventType`);
    return false;
  }

  const { status, data } = await axios.post(API.eventTypes.all, {
    token: token,
  });

  console.log(`status: ${status}, event.types:`, data.eventTypes);

  if (status === 200 && data?.eventTypes) {
    set && set(data.eventTypes);
    setLoaded && setLoaded(true);
    return data.eventTypes;
  } else {
    setLoaded(false);
    console.error(data.text);
  }
}
