import React, { useRef, useEffect, useState } from 'react';
import './textarea.scss';

const Textarea = ({ value, set, placeholder = "", title = "", refDOM = false, disabled = false, copy = false, resize = true }) => {
  const textarea = useRef();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    autoGrow();
  }, [value]);

  function autoGrow() {
    const i = refDOM ? refDOM : textarea;

    i.current.style.height = "42px";
    i.current.style.height = i.current.scrollHeight + "px";
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    } catch (err) {
      console.error('Не удалось скопировать текст: ', err);
    }
  };

  return (
    <div className="textarea">
      {title !== "" && <div className="textarea__title">{title}</div>}
      <textarea
        style = {
          {
            resize: resize ? "" : "none",
          }
        }
        ref={ refDOM ? refDOM : textarea }
        value={ value }
        onChange={(e) => {
          set(e.target.value);
        }}
        placeholder={ placeholder }
        disabled={ disabled }
      />
      {copy && (
        <button className={`copy-button ${copied ? 'copied' : ''}`} onClick={ copyToClipboard }>
          {copied ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.00002 17.0001L4.50002 12.5001L5.91002 11.0901L9.00002 14.1701L18.0001 5.17014L19.4101 6.58014L9.00002 17.0001Z"
                fill="currentColor"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 1H4C2.89543 1 2 1.89543 2 3V17H4V3H15V1Z"
                fill="currentColor"
              />
              <path
                d="M20 5H9C7.89543 5 7 5.89543 7 7V21C7 22.1046 7.89543 23 9 23H20C21.1046 23 22 22.1046 22 21V7C22 5.89543 21.1046 5 20 5ZM20 21H9V7H20V21Z"
                fill="currentColor"
              />
            </svg>
          )}
        </button>
      )}
    </div>
  );
};

export default Textarea;
