import React, { useRef, useState } from "react";
import "./faces.scss";
import { Col, Grid } from "../../components/containers/grid";
import FacesArea from "../../components/FacesArea/facesArea";
import Search from "../../components/search/search";
import useGlobal from "../../../store";
import cssIf from "../../../scripts/helpers/class.add.if";
import Checkbox from "../../components/checkbox";
import Tooltip from "../../components/tooltip/tooltip";

const FilterPopup = ({ isOpened }) => {
  const [globalState, globalActions] = useGlobal();

  const { changeState } = globalActions;
  const {
    facesMode,
    faces_filter_employees,
    faces_filter_visitors,
    faces_filter_unknown,
  } = globalState;

  const { changeStates } = globalActions;

  return (
    <div className={`filter_modal_faces ${cssIf(isOpened, "opened")}`}>
      <p className="center">Сортировка</p>

      <Checkbox
        text="По имени"
        value={facesMode === "nameSort"}
        set={bool => bool && changeStates({ facesMode: "nameSort" })}
      />

      <Checkbox
        text="По количеству посещений"
        value={facesMode === "visitsSort"}
        set={bool => bool && changeStates({ facesMode: "visitsSort" })}
      />

      <hr />

      <p className="center">Категории</p>

      <Checkbox
        text="Сотрудники"
        value={faces_filter_employees}
        set={bool => changeState("faces_filter_employees", bool)}
      />

      <Checkbox
        text="Посетители"
        value={faces_filter_visitors}
        set={bool => changeState("faces_filter_visitors", bool)}
      />

      <Checkbox
        text="Неопределенные"
        value={faces_filter_unknown}
        set={bool => changeState("faces_filter_unknown", bool)}
      />
    </div>
  );
};

const FacesGridPage = () => {
  const [globalState, globalActions] = useGlobal();

  const { popup, changeState } = globalActions;

  const { selected_face } = globalState;

  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [showInnerFilterPopup, setShowInnerFilterPopup] = useState(false);

  function toggleFilterPopup() {
    if (!showFilterPopup) {
      setShowFilterPopup(true);
      setTimeout(() => {
        setShowInnerFilterPopup(true);
      }, 50);
    } else {
      setShowInnerFilterPopup(false);
      setTimeout(() => {
        setShowFilterPopup(false);
      }, 300);
    }
  }

  function goBack() {
    changeState("selected_face", false);
    changeState("selected_face_photos", []);
    changeState("selected_face_photosLoaded", false);
  }

  function addButtonAction() {
    popup.showPopup("peoplePhotoAdd");
  }

  const FilterRef = useRef();

  return (
    <React.Fragment>
      <Grid>
        <Col>
          {selected_face && (
            <div className={"arrow-go-back"} onClick={goBack}>
              <svg
                width="36px"
                height="36px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 5L3 10L8 15"
                  stroke="#FF820E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 10H11C16.5228 10 21 14.4772 21 20V21"
                  stroke="#FF820E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}

          {selected_face && (
            <button
              className="button_w_action"
              onClick={() => {
                addButtonAction();
              }}
            >
              <div className={`button_w_action__icon button_w_action__icon--add`} />

              <div className="button_w_action__text">{"Добавить фото"}</div>
            </button>
          )}
          <Search placeholder={"Поиск человека"} />

          <Tooltip
            ChildrenRef={FilterRef}
            text={"Фильтр"}
            TooltipPosition={"bottom"}
            animationDirection={"right"}
            Offset={{
              top: 0,
              left: 250,
            }}
          />
          <div className="filter_btn_faces" onClick={toggleFilterPopup} ref={FilterRef}>
            <svg
              width="24"
              height="25"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.62932 18.0625H6.97188V9.45404L1 2.34467V1H17.2094V2.33699L11.5219 9.44636V15.1699L8.62932 18.0625ZM8.10938 16.925H8.15818L10.3844 14.6988V9.04739L15.9123 2.1375H2.31168L8.10938 9.03971V16.925Z"
                fill="#FFA000"
                stroke="#FFA000"
                strokeWidth="0.5"
              />
            </svg>
          </div>

          {showFilterPopup && <FilterPopup isOpened={showInnerFilterPopup} />}
          <FacesArea />
        </Col>
      </Grid>
    </React.Fragment>
  );
};

export default FacesGridPage;
