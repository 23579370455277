import React from 'react';
import Dropzone from 'react-dropzone';
import cssIf from '../../../scripts/helpers/class.add.if';
import Button from '../button';
import './file.drop.zone.scss';

export const FileDropZone = ({ type = '*', action = (e) => console.log(e) }) => (

  <Dropzone

    onDrop = { acceptedFile => action( acceptedFile ) }
    accept = { type }

  >

    {({ getRootProps, getInputProps, isDragActive }) => (

      <div 

        {...getRootProps() } 
        className = {`dropzone ${ cssIf( isDragActive, `dropzone--active`) } flex flex-col justify-center items-center`}

      >

        { isDragActive

          ? <span className = "dropzone__title">Загрузить!</span>

          : <React.Fragment>

              <span className = "dropzone__title">Перетащите файл сюда,</span>

              <span className = "dropzone__subtitle">или нажмите</span>

              <Button

                text = { `Выбрать файл${ cssIf( type !== "*", ` ${ type }` )}` }
                color = "border-gray"

              />

            </React.Fragment> 
            
        }

        <input { ...getInputProps() } />

      </div>

    )}

  </Dropzone>

);

export const FileDropZoneLoaded = ({ filename = false, resetText = "Cбросить файл?",  clear = () => {} }) => (

  <div

    className="dropzone dropzone--loaded text-center"
    onClick = { () => { 

      const msg = 'Вы точно хотите сбросить текущий файл?';
      window.confirm( msg ) && clear() 

    }}

  >

    <div className = "dropzone__title">{ cssIf( filename, filename, `Файл загружен!` ) }</div>
    <div className = "dropzone__subtitle">{ resetText }</div>

  </div>


);