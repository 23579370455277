import React, { useState, useEffect, useRef } from "react";
import { Player, ControlBar } from "video-react";
import convertTimeToSeconds from "../../../scripts/helpers/time.to.seconds";
import "./player.scss"
import Controls from "./controls";
import useGlobal from "../../../store";

const Video = () => {

  const [ globalState, globalActions ] = useGlobal();

  const { 

    video, 
    video_start, 
    realtime_watch,
    se_location_name,

    playing_time,

    se_have_faces,
    se_have_numbers,
    se_have_simillar_faces
  
  } = globalState;

  const { changeState } = globalActions;

  const player = useRef();

  const playerProps = () => player.current.manager.video.getProperties();

  const [ isPlaying, setIsPlaying ] = useState( false );
  const [ speed, setSpeed ] = useState( "1X" );
  const [ _video, set_Video ] = useState( video !== "" && video ); //// : realtime_watch !== "" ? realtime_watch : false

  useEffect(() => {

    const videoURL = video !== "" ? video : realtime_watch !== "" ? realtime_watch : false;
    console.log('Смена видео на:', videoURL);
    set_Video( videoURL );
    player.current.load( videoURL );

  }, [ video, realtime_watch ]);
  
  useEffect(() => {

    //// console.log(`UEF player.current`, player.current)
    player.current && setIsPlaying( !playerProps().paused );
    player.current.subscribeToStateChange( playerStateChange );

  }, []);

  function playerStateChange( playerNow ) {
    ////console.log( playerNow );    
    if ( playerNow.paused && isPlaying ) setIsPlaying( false );
    else if ( !playerNow.paused && !isPlaying ) setIsPlaying( true );

    if ( playerNow.currentTime.toString().indexOf('.') !== -1 ) {

      playing_time.toString() !== playerNow.currentTime.toString().split('.')[ 0 ] 
        && changeState( "playing_time", playerNow.currentTime.toString().split('.')[ 0 ] );

    }

  }

  useEffect(() => { 

    if ( video_start && video_start !== "" ) {

      const converted = convertTimeToSeconds( video_start ) - 3;
      console.log(`time_to_seconds ${video_start} - ${converted}`);
      player.current.seek( converted ) 
    
    }
    
  }, [ video_start ]);

  function changeCurrentTime( seconds ) {

    const _player = playerProps();
    console.log(`changeCurrentTime _player: `, _player );
    player.current.seek( _player.currentTime + seconds );

  }

  function PrevAndNext( mode ) {

    const _player = playerProps();

    if ( mode === "prev" ) {

      if ( _player.currentTime > 3 ) {
        
        player.current.seek( 0 );
      
      } else {

        // alert(`go to previous video`); //TODO

      }

    } else if ( mode === "next" ) {

      if ( _player.currentTime < ( _player.duration - 3 )) {

        player.current.seek( _player.duration );

      } else {

        // alert(`go to next video`); //TODO

      }

    }
    
  }

  function changeSpeed() {

    let _speed;

    const steps = [ "0.25X", "0.5X", "0.75X", "1X", "1.25X", "1.5X", "1.75X", "2X" ];
    const speeds = [ "0.25", "0.5", "0.75", "1", "1.25", "1.5", "1.75", "2" ];

    if ( steps.indexOf( speed ) < ( steps.length - 1 ) ) {
      _speed = steps[ steps.indexOf( speed ) + 1 ]
    } else {
      _speed = steps[ 0 ];
    }

    player.current.playbackRate = speeds[ steps.indexOf( _speed ) ];
    //// player.current.playbackRate = player.current.playbackRate + steps;
    setSpeed( _speed );

  }

  const actions = {

    play: () => { player.current.play(); setIsPlaying( true ) },
    pause: () => { player.current.pause(); setIsPlaying( false ) },
    forward: () => changeCurrentTime( 10 ),
    back: () => changeCurrentTime( -10 ),
    begin: () => PrevAndNext( "prev" ),
    end: () => PrevAndNext( "next" ),
    changeSpeed: changeSpeed,
    load: ( url ) => player.current.load( url ),

  };

  return (

    _video === ""

      ? <div className="tip">Загрузка...</div>

      : <div

          style = {{ zoom: window.innerWidth > 970 ? devicePixelRatio === 2 ? '1.25' : devicePixelRatio : 1 }} 
          className = { 
            ( !se_have_faces && !se_have_numbers && !se_have_simillar_faces ) 
              ? "player-maxheight"
              : !se_have_simillar_faces
                ? "player-middleheight"
                : "player-minheight"
          }

        >

          <Player

            style = {{ height: "580px" }}
            ref={ p => { player.current = p; }}
            autoPlay
            
          >

            <source src={ _video } />

            <ControlBar
              autoHide={ false } 
            />

          </Player>

          <Controls

            name = { se_location_name }
            speed = { speed }
            action = { actions }
            isPlaying = { isPlaying }
            isRealTime = { realtime_watch !== "" }

          />
          
        </div>

  );

}

export default Video;