import React from "react";
import useGlobal from "../../../store";
import MarkupsConfigurationsView from "./MarkupsConfigurationsPage";

const Markups = () => {
  const [globalState] = useGlobal();

  const { user_type } = globalState;

  return (
    <React.Fragment>{user_type <= 3 && <MarkupsConfigurationsView />}</React.Fragment>
  );
};

export default Markups;
