import React from 'react';
import useGlobal from '../../../store';

import VisitorsView from "./visitors";

const Visitors = () => {

  const [ globalState] = useGlobal();

  const { user_type } = globalState;

  return (

    <React.Fragment>

      { user_type <= 4 && <VisitorsView /> }

    </React.Fragment>

  );

}

export default Visitors;