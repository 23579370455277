import React, { useEffect, useState, useRef } from 'react';

import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';
import useGlobal from '../../../store';

import { 
  
  get as getCameraInfo,
  add as addCamera,
  edit as editCamera,
  getWrapperHashFromFile 

} from '../../../store/actions/cameras';

import { all as getFolders } from '../../../store/actions/folders';
import { all as getModels } from '../../../store/actions/models';
import { all as getWorkers } from '../../../store/actions/workers';
import { all as getEventTypes } from '../../../store/actions/event.types';

import {

  add as addLocation, 
  edit as editLocation, 
  remove as removeLocation 

} from '../../../store/actions/locations';

import { 
  
  add as addWrapper,
  edit as editWrapper,
  remove as removeWrapper

} from '../../../store/actions/wrappers';

import Multiselectfield from '../../components/multiselectfield';
import Selectfield from '../../components/selectfield';
import Textfield from '../../components/textfield';
import Textarea from '../../components/textarea';
import Button from '../../components/button';
import Table from '../../components/table';

import { Popup, popupClose } from '../index';

import './popup.camera.scss';
import { FileDropZone, FileDropZoneLoaded } from '../../components/file.drop.zone';
import cssIf from '../../../scripts/helpers/class.add.if';
import timeZones from "../../data/timeZones";
import dateFieldAutocomplete from "../../components/datepicker/timefieldlongautocompl";

export const CameraModalAdd = () => {

  const [ globalState, globalActions ] = useGlobal();
  
  const reload = () => {
    
    globalActions.cameras.all();
    popupClose();

  }

  return (

    <CameraModal

      mode = "add" 
      func = { ( form ) => { 

        addCamera( form, reload );

      }} 
      
    />

  );

}

export const CameraModalEdit = ({ id }) => {

  const [ globalState, globalActions ] = useGlobal();
  
  const reload = () => {
    
    globalActions.cameras.all();
    popupClose();

  }

  const [ data, setData ] = useState({});
  const [ isDataLoaded, setIsDataLoaded ] = useState( false );

  useEffect(() => {

    if ( !isDataLoaded ) {

      ( async () => {

        await getCameraInfo({

          id: id,
          set: setData

        });

        setIsDataLoaded( true );

      })();
    
    }

  }, []);

  return (

    isDataLoaded

      ? <CameraModal

          mode = "edit" 
          func = {( form ) => {

            editCamera({

              id: id,
              ...form

            }, reload );

          }}
          id = { id }
          { ...data }

        />

      : null

  );

}

export const CameraModal = ( props ) => {

  const TITLE = {
    add: "Добавление новой",
    edit: "Редактирование",
  }
  const FUNC_BTN_TEXT = {
    add: "Добавить",
    edit: "Сохранить",
  }

  const timeEndField = useRef();
  const commentField = useRef();
  const locationNameField = useRef();

  const [ Dummy, reloadDummy ] = useReloadDummy();

  const updateServer = props.mode === "edit";

  const [ folder, setFolder ] = useState( false );
  const [ worker, setWorker ] = useState( false );
  const [ model,  setModel  ] = useState( props.model || [] );
  const [ name, setName ] = useState( props.name || "" );
  const [ fps, setFps ] = useState( props.fps || "" );
  const [ rtsp, setRtsp ] = useState( props.rtsp || "" );
  const [ timeBegin, setTimeBegin ] = useState( props.timeBegin || "" );
  const [ timeBeginAutocomplCount, setTimeBeginAutocomplCount ] = useState( 0 );
  const [ timeEnd, setTimeEnd ] = useState( props.timeEnd || "" );
  const [ timeEndAutocomplCount, setTimeEndAutocomplCount ] = useState( 0 );
  const [ duration, setDuration ] = useState( props.duration || "" );
  const [ comment, setComment ] = useState( props.comment || "" );

  const [ time_zone, setTimeZone ] = useState( props.time_zone || "" );
  
  const [ folders, setFolders ] = useState([]);
  const [ isFoldersLoaded, setIsFoldersLoaded ] = useState( false );

  const [ workers, setWorkers ] = useState([]);
  const [ isWorkersLoaded, setIsWorkersLoaded ] = useState( false );
  
  const [ models, setModels ] = useState([]);
  const [ isModelsLoaded, setIsModelsLoaded ] = useState( false );
  
  const [ locations, setLocations ] = useState( props.locations || []);
  
  const [ eventTypes, setEventTypes ] = useState([]);
  const [ isEventTypesLoaded, setIsEventTypesLoaded ] = useState( false );

  const [ wrappers, setWrappers ] = useState( props.markups || []);

  const [ showAddLocation, setShowAddLocation ] = useState( false );
  const [ locationEditMode, setLocationEditMode ] = useState( false );
  const [ locationEditID, setLocationEditID ] = useState( "" );
  const [ addLocationName, setAddLocationName ] = useState("");
  const [ addLocationComment, setAddLocationComment ] = useState("");
  const [ addLocationEventType, setAddLocationEventType ] = useState("");
  
  const [ showAddWrapper, setShowAddWrapper ] = useState( false );
  const [ wrapperEditMode, setWrapperEditMode ] = useState( false );
  const [ wrapperEditID, setWrapperEditID ] = useState("");
  const [ addWrapperModel, setAddWrapperModel ] = useState("");
  const [ addWrapperComment, setAddWrapperComment ] = useState("");
  const [ addWrapperFile, setAddWrapperFile ] = useState("");

  useEffect(() => {

    getFolders( setFolders );
    getModels( setModels );
    getWorkers( setWorkers );
    getEventTypes( setEventTypes, setIsEventTypesLoaded );

  }, []);

  useEffect(() => { // set folder from props

    ( !isFoldersLoaded && folders.length > 0 ) && setIsFoldersLoaded( true );

    if ( props?.folder ) {

      const f = folders.find( el => el.id === props.folder );
      setFolder( f );

    }
    
  }, [ folders, props.folder ]);


  useEffect(() => { // set worker from props

    ( !isWorkersLoaded && workers.length > 0 ) && setIsWorkersLoaded( true );

    if ( props?.worker && workers.length > 0 ) {

      const w = workers.find( el => el.id === props.worker );
      setWorker( w );

    }
    
  }, [ workers, props.worker ]);


  useEffect(() => { // set model from props

    ( !isModelsLoaded && models.length > 0 ) && setIsModelsLoaded( true );

    if ( props?.models && models.length > 0 ) {

      const m = [];
      models.forEach( el => props.models.indexOf( el.id ) !== -1 && m.push( el ) );
      setModel( m );

    }
    
  }, [ models, props.models ]);

  useEffect(() => { // autoComplete timeBegin 

    dateFieldAutocomplete(

      timeBegin, 
      timeBeginAutocomplCount,
      setTimeBegin,
      setTimeBeginAutocomplCount,
      () => timeEndField?.current?.focus()

    );
    
  }, [ timeBegin ]);

  useEffect(() => { // autoComplete timeEnd

    dateFieldAutocomplete(

      timeEnd, 
      timeEndAutocomplCount,
      setTimeEnd,
      setTimeEndAutocomplCount,
      () => commentField?.current?.focus()

    );
    
  }, [ timeEnd ]);


  const location = {

    add: async () => {

      let locationID = locations.length + 1;

      if ( updateServer ) {

        locationID = await addLocation({

          camera: props.id,
          name: addLocationName,
          comment: addLocationComment,
          eventType: addLocationEventType
  
        });

        if ( !locationID ) return;

      }

      const data = {
  
        name: addLocationName,
        btns: "e,r",
        id: locationID,
        comment: addLocationComment,
        eventType: addLocationEventType,
        new: true
  
      }
  
      const upd = locations;
      upd.push( data );
  
      setLocations( upd );

      handleLocationForm.close();
  
    },
    edit: () => {

      let locationToServer = {};

      let upd = [];

      locations.forEach( el => {

        if ( el.id === locationEditID ) {

          el = {
            
            name: addLocationName,
            btns: "e,r",
            id: el.id,
            comment: addLocationComment,
            eventType: addLocationEventType,
            new: el?.new ? el.new : false

          };

          locationToServer = el;

        }

        upd.push( el );
      
      });

      setLocations( upd );

      updateServer && editLocation({ 

        camera: props.id,
        id: locationToServer.id, 
        name: locationToServer.name, 
        comment: locationToServer.comment,
        eventType: locationToServer.eventType 

      });

      handleLocationForm.close();

    },
    remove: ( id ) => {
    
      if ( window.confirm('Вы действительно хотите удалить эту локацию? Отменить это действие невозможно') ) {
  
        const upd = [];

        locations.forEach( el => { 

          if ( el.id !== id ) { 

            upd.push( el ); 

          }

        });

        setLocations( upd );
        updateServer && removeLocation( id, props.id );
        handleLocationForm.close();
      
      }
  
    }

  }

  const handleLocationForm = {

    open: ( id = false ) =>  {
  
      if ( id ) {
  
        const finded = locations.find( el => el.id === id );
        setLocationEditMode( true );
        setLocationEditID( id )
        setAddLocationName( finded.name || "" );
        setAddLocationComment( finded.comment || "" );
        setAddLocationEventType( finded.eventType || "" );
  
      }
      
      setShowAddLocation( true );
      setTimeout(() => { locationNameField.current.focus(); }, 150 );
  
    },

    close: () => {

      setAddLocationName("");
      setAddLocationComment("");
      setAddLocationEventType("");
      setShowAddLocation( false );
      setLocationEditMode( false );
      setLocationEditID( "" );
  
    }
    
  }

  const wrapper = {

    add: async () => {

      let wrapperID = wrappers.length + 1;

      if ( updateServer ) { 
        
        wrapperID = await addWrapper({

          camera: props.id, 
          model: addWrapperModel.id,
          file: addWrapperFile,
          comment: addWrapperComment 

        });

      }

      const data = {
  
        name: addWrapperModel.name,
        btns: "e,r",
        id: wrapperID,
        model_id: addWrapperModel.id,
        comment: addWrapperComment,
        file: addWrapperFile,
        new: true
  
      }
  
      const upd = wrappers;
      upd.push( data );
  
      setWrappers( upd );
      handleWrapperForm.close();
  
    },

    edit: () => {

      let wrapperToServer = {};
      let upd = [];

      wrappers.forEach( el => {

        if ( el.id === wrapperEditID ) {

          el = {
            
            name: addWrapperModel.name,
            btns: "e,r",
            id: wrapperEditID,
            comment: addWrapperComment,
            model_id: addWrapperModel.id,
            file: addWrapperFile,
            new: el?.new ? el.new : false

          };

          wrapperToServer = el;
          
        }

        upd.push( el );
      
      });

      setWrappers( upd );

      updateServer && editWrapper({ 

        id: wrapperToServer.id, 
        camera: props.id, 
        model: wrapperToServer.model_id,
        file: wrapperToServer.file,
        comment: wrapperToServer.comment 

      });

      handleWrapperForm.close();

    },

    remove: ( id ) => {
    
      if ( window.confirm('Вы действительно хотите удалить эту разметку? Отменить это действие невозможно') ) {
  
        const upd = [];
        wrappers.forEach( el => el.id !== id && upd.push( el ) );
        setWrappers( upd );
        updateServer && removeWrapper( id, props.id );
        handleWrapperForm.close();
      
      }
  
    }

  }

  const handleWrapperForm = {

    open: ( id = false ) =>  {
  
      if ( id ) {
  
        const finded = wrappers.find( el => el.id === id );

        
        setWrapperEditMode( true );
        setWrapperEditID( id );

        setAddWrapperModel({

          name: finded.name || "",
          id: finded.model_id || "",

        });
        
        setAddWrapperComment( finded.comment || "" );
        setAddWrapperFile( finded.file )
  
      }
      
      setShowAddWrapper( true );
  
    },

    close: () => {
      
      setShowAddWrapper( false );
      setWrapperEditMode( false );
      setWrapperEditID("");
      setAddWrapperModel("");
      setAddWrapperComment("");
      setAddWrapperFile("");
  
    }
    
  }

  const formIsValidated = (

    folder && worker && name !== "" && fps !== "" && rtsp !== "" && time_zone !== ""
    && timeBegin !== "" && timeEnd !== "" && locations.length > 0
    && wrappers.length > 0 && model.length > 0

  );

  function sendForm() {

    if ( model.length === 0 ) { alert("Выберите хотя бы одну используемую модель"); return; }

    const _m = [];
    model.forEach( m => _m.push( m.id ));

    let form = {

      folder: folder.id,
      worker: worker.id,
      models: _m,
      name,
      fps,
      timeBegin,
      timeEnd,
      duration,
      time_zone,
      comment,
      rtsp,
      locations,
      wrappers

    }

    if ( !updateServer ) {

      form = {

        ...form,
        locations,
        wrappers

      };

    }

    console.log( `sendForm:`, form );
    props.func( form );

  }

  return (
    
    <Popup className = "popup_camera">

      <div className="popup__title">{ TITLE[ props.mode ]} камеры { props?.name && `- ${ props.name }` }</div>

      <div className="flex">

        <div className="popup_camera__inputs">

          <Selectfield

            title = "Выбор объекта"
            object = "папка"
            list = { folders }
            selected = { folder }
            select = { setFolder }
            isLoading = { !isFoldersLoaded }

          />

          <Selectfield

            title = "Выбор воркера"
            object = "воркер"
            list = { workers }
            selected = { worker }
            select = { setWorker }

          />

          <div className="popup_camera__inputs__row popup_camera__inputs__row-1 flex items-center">

            <Textfield

              title = "Имя"
              value = { name }
              set = { setName }
              placeholder = "CAMERA_01"

            />

            <Textfield

              type = "number"
              title = "FPS"
              placeholder = "30"
              value = { fps }
              set = { setFps }

            />

            <Textfield

              title = "Начало"
              placeholder = "00:00:00"
              value = { timeBegin }
              set = { setTimeBegin }

            />

            <Textfield

              title = "Завершение"
              placeholder = "00:00:00"
              value = { timeEnd }
              set = { setTimeEnd }
              refDOM = { timeEndField }

            />

          </div>

          <div className="popup_camera__inputs__row popup_camera__inputs__row-len flex items-center">

          <Textfield

            type = "number"
            title = "Длительность записи"
            value = { duration }
            set = { setDuration }

          />

            <Selectfield

                title = "Выбор часового пояса"
                object = "Часовой пояс"
                list = { timeZones }
                selected = {time_zone}
                select = { setTimeZone }

            />


          </div>
          <div className="popup_camera__inputs__row popup_camera__inputs__row-2 flex items-center">

            <Textarea

              title = "Комментарий"
              placeholder = "Вкратце о камере..."
              value = { comment }
              set = { setComment }
              refDOM = { commentField }

            />

          </div>

          <div className="popup_camera__inputs__row popup_camera__inputs__row-3 flex items-center">

            <Textfield

              title = "RTSP url"
              placeholder = "rtsp://admin:@HOST:PORT"
              value = { rtsp }
              set = { setRtsp }

            />

          </div>

          <Multiselectfield

            list = { models }
            title = "Используемые модели"
            object = "модель"
            selected = { model }
            select = { ( m ) => { setModel( m ); reloadDummy(); }}

          />

          <div className = "flex">

            <Button

              text = { FUNC_BTN_TEXT[ props.mode ]}
              action = { sendForm }
              disabled = { !formIsValidated }

            />

            <Button

              text = "Отмена"
              action = { popupClose }
              color = "gray"

            />

          </div>

        </div>

        <div className = "popup_camera__column">
          
          <div className = "popup__area">

            <div className="popup__area__title">Локации</div>

            <div className="popup__area__list">

              <Table

                rows = { locations }
                selected = {[ locationEditID ]}
                total = { locations.length } 
                maxCols = { 2 }
                loadMore = { () => {} } 
                edit = { ( id ) => { handleLocationForm.open( id ) } } 
                remove = { ( id ) => { location.remove( id ) } } 

              />

            </div>

            { !showAddLocation

              ? <Button

                  icon = "+"
                  text = "Добавить"
                  color = "border-orange"
                  action = { handleLocationForm.open }

                />

              : <React.Fragment>

                  <Textfield

                    placeholder = "Имя"
                    value = { addLocationName }
                    set = { setAddLocationName }
                    refDOM = { locationNameField }

                  />

                  <Textarea

                    placeholder = "Комментарий"
                    value = { addLocationComment }
                    set = { setAddLocationComment }

                  />

                  <Selectfield

                    title = "Тип событий"
                    list = { eventTypes }
                    selected = { eventTypes.find( et => et.id === addLocationEventType ) }
                    select = { et => setAddLocationEventType( et.id ) }
                    isLoading = { !isEventTypesLoaded }

                  />

                  <div className="flex">

                    <Button

                      text = { cssIf( !locationEditMode, "Добавить", "Сохранить" ) }
                      color = "border-orange"
                      action = { locationEditMode ? location.edit : location.add }

                    />

                    <Button

                      text = "Отмена"
                      color = "border-gray"
                      action = { handleLocationForm.close }

                    />

                  </div>

                </React.Fragment>
            
            }

          </div>

        </div>

        <div className = "popup_camera__column">

          <div className = "popup__area">

            <div className="popup__area__title">Разметка</div>

            <div className="popup__area__list" style = {{ marginBottom: wrappers.length !== 0 ? 'unset' : undefined }}>

              <Table

                rows = { wrappers } 
                total = { wrappers.length } 
                maxCols = { 2 }
                loadMore = { () => {} } 
                edit = { ( id ) => { handleWrapperForm.open( id ) } } 
                remove = { ( id ) => { wrapper.remove( id ) } } 

              />

            </div>

            {( wrappers.length >= 0 || showAddWrapper ) && //wrappers.length === 0 || TODO uncomment after testing
            
              <React.Fragment>

                <Dummy />
            
                { !showAddWrapper

                  ? <React.Fragment>

                      { model.length === 0 && <p className="error text-center">чтобы добавить разметку<br/>выберите используемые модели</p> }

                      <Button

                        icon = "+"
                        text = "Добавить"
                        color = "border-orange"
                        action = { handleWrapperForm.open }
                        // disabled = { model.length === 0 }
                        
                      />

                    </React.Fragment>

                  : <React.Fragment>

                      <Selectfield

                        list = { model }
                        placeholder = "Выбор модели"
                        object = "модель"
                        selected = { addWrapperModel }
                        select = { setAddWrapperModel }

                      />                  

                      <Textarea

                        placeholder = "Комментарий"
                        value = { addWrapperComment }
                        set = { setAddWrapperComment }

                      />

                      { addWrapperFile === ""

                        ? <FileDropZone

                            type = {`.pb`}
                            action = { ( file ) => getWrapperHashFromFile( file, setAddWrapperFile ) }

                          />

                        : <FileDropZoneLoaded

                            filename = {(

                              <div style = {{ color: "var(--color-lightblue)"}}>

                                <div style = {{ 

                                  marginBottom: "8px", 
                                  color: "var(--color-popup-textgray)"

                                }}>

                                  Разметка сгенерирована:

                                </div>

                                <div>{ addWrapperFile }</div>

                              </div>

                            )}
                            resetText = "Нажмите, чтобы сбросить"
                            clear = { () => setAddWrapperFile("") } 
                          />
                      
                      }
                      

                      <br />

                    <div className="flex">
                      <Button
                        text = { 'Создать свою разметку' }
                        color = "border-green"
                        action = { () => {
                          if (rtsp.length > 12){
                            window.open(`/draw?mode=add&rtsp=${encodeURIComponent(rtsp.toString())}`, '_blank')
                          }
                          else {
                            alert('Не указан RTSP адрес')
                          }
                        } }
                      />
                    </div>

                    <br />

                      <div className="flex">

                        <Button

                          text = { cssIf( !wrapperEditMode, "Добавить", "Сохранить" ) }
                          color = "border-orange"
                          action = { !wrapperEditMode ? wrapper.add : wrapper.edit }
                          disabled = { addWrapperFile === "" || !addWrapperModel.id }

                        />

                        <Button

                          text = "Отмена"
                          color = "border-gray"
                          action = { handleWrapperForm.close }

                        />

                      </div>

                    </React.Fragment>
              
                }

              </React.Fragment>
                
            }

          </div>
          
        </div>

      </div>
      
    </Popup>

  );

}