import React, { useEffect, useState } from 'react';

import useGlobal from '../../../store';

import {

  add as addVisitor,
  edit as editVisitor,

} from '../../../store/actions/visitors';


import Textfield from '../../components/textfield';
import Button from '../../components/button';

import { Popup, popupClose } from '../index';

import './popup.visitors.scss';
import Checkbox from "../../components/checkbox";
import { get as getVisitorInfo } from "../../../store/actions/visitors";

function isGosnumberCorrect(Gosnumber)
{
  return /[ABEKMHOPCTYX]\d{3}[ABEKMHOPCTYX]{2}\d{2,3}$/.test(Gosnumber);
}

function GosNumberToEng(Gosnumber){

  function is_numeric(str){
     return /\d$/.test(str);
  }

  // noinspection NonAsciiCharacters
  const ALLOWED_LETTERS = {
    А: 'A',
    В: 'B',
    Е: 'E',
    К: 'K',
    М: 'M',
    Н: 'H',
    О: 'O',
    Р: 'P',
    С: 'C',
    Т: 'T',
    У: 'Y',
    Х: 'X',
  }

  let result = ''

  for (const i of Gosnumber){
    if (!is_numeric(i)){
      result = result + (ALLOWED_LETTERS[i] !== undefined ? ALLOWED_LETTERS[i]: i)
    }
    else{
      result =  result +  i
    }
  }
  return result

}

export const VisitorsModalAdd = () => {

  const [ globalState, globalActions ] = useGlobal();

  const reload = () => {

    globalActions.visitors.all();
    popupClose();

  }

  return (

    <ExportModal  // Popup экспорта

      mode = "add"
      func = { ( form ) => {

        addVisitor( form, reload );  // POST запрос добавления записи на бэк

      }}

    />

  );

}

export const VisitorsModalEdit = ({ id }) => {

  const [ globalState, globalActions ] = useGlobal();

  const reload = () => {

    globalActions.visitors.all();
    popupClose();

  }

  const [ data, setData ] = useState({});
  const [ isDataLoaded, setIsDataLoaded ] = useState( false );

  useEffect(() => {

    if ( !isDataLoaded ) {

      ( async () => {

        await getVisitorInfo({

          id: id,
          set: setData

        });

        setIsDataLoaded( true );

      })();

    }

  }, []);

  return (

    isDataLoaded

      ? <ExportModal

        mode = "edit"
        func = {( form ) => {

          editVisitor({

            id: id,
            ...form

          }, reload );

        }}
        id = { id }
        { ...data }

      />

      : null

  );

}


export const ExportModal = ( props ) => {

  const TITLE = {
    add: "Добавление нового",
    edit: "Редактирование информации о"
  }
  const FUNC_BTN_TEXT = {
    add: "Добавить",
    edit: "Сохранить"
  }

  const [ GosNumber, setGosNumber ] = useState( props.name || "" );

  const [ BlackList, setBlackList ] = useState( props.black_list  || false );

  const [ WhiteList, setWhiteList ] = useState( props.white_list || false );



  const formIsValidated = (

    GosNumber !== "" && (BlackList || WhiteList)

  );

  function sendForm() {

    const Validated_GosNumber = GosNumberToEng(GosNumber)  // Validated - Приведён к латинице

    if (!isGosnumberCorrect(Validated_GosNumber)) return alert('Государственный номер ТС указан некорректно!')

    let form = {

      GosNumber: Validated_GosNumber,
      WhiteList,
      BlackList

    }

    console.log( `sendForm:`, form );
    props.func( form );

  }

  return (

    <Popup className = "popup_export">

      <div className="popup__title">{ TITLE[ props.mode ]} ТС { props?.name && `- ${ props.name }` }</div>

      <div className="flex">

        <div className="popup_export__inputs">

            <Textfield
              title = "Государственный номер"
              placeholder = "A777ОA777"
              value = { GosNumber }
              set = { setGosNumber }

            />

          <div className="popup_export__inputs__row popup_export__inputs__row-len flex items-center">



          </div>
          <div className="popup_export__inputs__row popup_export__inputs__checkbox flex">

            <Checkbox
              text = "Черный список"
              value = { BlackList }
              set = { setBlackList }
              disabled = {WhiteList}
            />
          </div>

          <div className="popup_export__inputs__row popup_export__inputs__checkbox flex">
            <Checkbox
              text = "Белый список"
              value = { WhiteList }
              set = { setWhiteList }
              disabled = {BlackList}
            />

          </div>


          <div className = "flex">

            <Button

              text = { FUNC_BTN_TEXT[ props.mode ]}
              action = { sendForm }
              disabled = { !formIsValidated }

            />

            <Button

              text = "Отмена"
              action = { popupClose }
              color = "gray"

            />

          </div>

        </div>

      </div>

    </Popup>

  );

}