import React from 'react';
import useGlobal from '../../../store';
import "./header.scss";

import NavBar from "../navbar";
import UserMenu from "../user.menu";
import {Link} from "react-router-dom";

const Header = ({ clean = false }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { user_name, user_type } = globalState;

  return (

    <div className="header flex justify-between items-center">
    <Link to="/">
      <div className="logo relative" />
    </Link>
      { !clean && 
      
        <React.Fragment>
          
          <NavBar type = { user_type } />

          <UserMenu name = { user_name } />

        </React.Fragment>

      }

    </div>

  );

}

export default Header;