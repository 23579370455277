const getAdaptiveVariant = () =>
  window.innerWidth > 2100
    ? "desktopExtra"
    : window.innerWidth > 1900
    ? "desktopBig"
    : window.innerWidth > 1625
    ? "desktopMedium"
    : window.innerWidth > 1475
    ? "desktopSmall"
    : window.innerWidth > 970
    ? "laptop"
    : "mobile";
export default getAdaptiveVariant;
