import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Dropdown.scss";
import { serviceDropdown2, serviceDropdown3 } from "./NavItems";
import useGlobal from "../../../store";

function Dropdown({ active }) {
  const [dropdown, setDropdown] = useState(false);

  const [globalState] = useGlobal();

  const { user_type } = globalState;

  const serviceDropdown = user_type === 3 ? serviceDropdown3 : serviceDropdown2;
  return (
    <ul
      className={dropdown ? "services-submenu clicked" : "services-submenu"}
      onClick={() => setDropdown(!dropdown)}
    >
      {serviceDropdown.map(item => {
        return (
          <li
            key={item.id}
            className={active === item.path.replace("/", "") ? "active" : ""}
          >
            <Link
              to={item.path}
              className={item.cName}
              onClick={() => setDropdown(false)}
            >
              {item.title}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export default Dropdown;
