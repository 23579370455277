import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

export async function all(set) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из get models`);
    return false;
  }

  const { status, data } = await axios.post(API.workers.all, { token: token });

  console.log(`status: ${status}, tree:`, data.workers);

  if (status === 200 && data?.workers) {
    set(data.workers);
  } else {
    console.error(data.text);
  }
}
