import { Fragment } from 'react';
import { ReactComponent as Settings } from './icons/settings.svg';
import { ReactComponent as Stats } from './icons/stats.svg';
import { ReactComponent as Player } from './icons/player.svg';
import { ReactComponent as Events } from './icons/eye.svg';
import { ReactComponent as Tree } from './icons/tree.svg';
import './mobile.bottom.bar.scss';
import cssIf from '../../../scripts/helpers/class.add.if';

const MobileBottomBar = ({ current, navigate }) => {

  const MENU = [

    {
      icon: <Settings />,
      url: "settings"
    },
    {
      icon: <Stats />,
      url: "stats"
    },
    {
      icon: <Player />,
      url: "player"
    },
    {
      icon: <Events />,
      url: "events"
    },
    {
      icon: <Tree />,
      url: "tree"
    }

  ];

  return (

    <div className = "mbb flex justify-between items-center">

      { MENU.map(( el, key ) => (

        <Fragment>

          <MenuItem

            key = { key }
            url = { el.url }
            icon = { el.icon }
            active = { el.url === current }
            action = { navigate }

          />

          { key !== MENU.length - 1 && <div className = "mbb__line" /> }

        </Fragment>

      ))}

    </div>

  );

}

const MenuItem = ({ url, active, action, icon }) => (

  <div className={`mbb__item mbb__item_${ url } ${ cssIf( active, 'mbb__item--active' ) }`} onClick = { () => action( url ) }>{ icon }</div>

);

export default MobileBottomBar;