import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

export async function all(store, reload = false) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из all cameras`);
    return false;
  }

  const { status, data } = await axios.post(API.users.all, { token: token });

  console.log(`status: ${status}, tree:`, data.tree);

  if (status === 200 && data?.users) {
    store.setState({
      usersTable: data.users,
      usersTableLoaded: true,
    });

    reload && reload();

    return data.users;
  } else {
    console.error(data.text);
  }
}

export async function get({ id, set }) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из get users`);
    return false;
  }

  const { status, data } = await axios.post(API.users.get, {
    token: token,
    id: id,
  });

  if (status === 200 && data?.data) {
    set(data.data);
  } else {
    console.error(data.text);
  }
}

export async function add(form, reload) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из add user`);
    return false;
  }

  const params = {
    token,
    ...form,
  };

  console.log(`User.add form:`, params);

  reload();

  const { status, data } = await axios.post(API.users.add, params);

  if (status === 200) {
    console.log(`User.add:`, status);
  } else {
    console.error(data.text);
  }
}

export async function edit(form, reload) {
  const token = cookie.load("token");
  if (!token) {
    console.error(`Попытка разлогина из edit user`);
    return false;
  }

  const params = {
    token,
    ...form,
  };

  console.log(`User.edit form:`, params);

  reload();

  const { status, data } = await axios.post(API.users.edit, params);

  if (status === 200) {
    console.log(`User.edit:`, status);
  } else {
    console.error(data.text);
  }
}

export async function remove(store, id) {
  if (
    window.confirm(
      "Вы действительно хотите удалить пользователя и все связанные с ним права и его настройки доступа к камерам? Отменить это действие невозможно!"
    )
  ) {
    const token = cookie.load("token");
    if (!token) {
      console.error(`Попытка разлогина из remove user`);
      return false;
    }

    const { status, data } = await axios.post(API.users.remove, {
      token: token,
      id: id,
    });

    if (status === 200) {
      store.actions.users.all();
    } else {
      console.error(data.text);
    }
  }
}
