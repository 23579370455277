import React, { useState, useEffect, useRef } from 'react';
import Face from '../face';
import useGlobal from '../../../store';
import cssIf from '../../../scripts/helpers/class.add.if'
import convertTimeToSeconds from '../../../scripts/helpers/time.to.seconds';
import twoDigitFormat from "../../../scripts/helpers/two.digit.format";
import './event.scss';

const Event = ({

  i,
  id,
  isActive,
  date,
  timeStart,
  timeEnd,
  timeDiff,
  timeline,
  text,
  label,
  path,
  vehicle = false,
  person = false,
  black_list,
  white_list,
  isConfirmed,
  isImportant,
  isRemoved,
  showRemoved,
  controls,
  isOneLocationEvent,
  onPlayFromStart,
  onPlayFromEnd,
  onInfoClick, // Deprecated ?
  onCheckClick,
  onWarningClick,
  onCancelClick

}) => {

  const thisEvent = useRef();
  const labelRef = useRef();
  const onCounterClick = () => (console.log('клик'))
  const [ globalState, globalActions ] = useGlobal();
  const { playing_time } = globalState;
  const { changeStates } = globalActions;
  const timeline_seconds = convertTimeToSeconds( timeline );

  const [ isNowInPlayer, setIsNowInPlayer ] = useState( false );
  const [ confirmed, setConfirmed ] = useState( isConfirmed );
  const [ important, setImportant ] = useState( isImportant );
  const [ removed, setRemoved ] = useState( isRemoved );

  useEffect(() => {

    important !== isImportant && setImportant( isImportant );
    confirmed !== isConfirmed && setConfirmed( isConfirmed );
    removed   !== isRemoved   && setRemoved( isRemoved );

    console.log(`labelRef:`, labelRef);

  }, [ isImportant, isConfirmed, isRemoved ]);

  useEffect(() => {

    if ( parseInt( timeline_seconds ) === parseInt( playing_time ) ) {

      !isNowInPlayer && setIsNowInPlayer( true );
      thisEvent.current.scrollIntoViewIfNeeded();

      changeStates({

        se_id: id,
        se_location_name: path[ path.length - 1 ]

      });

    } else {

      isNowInPlayer && setIsNowInPlayer( false );

    }

  }, [ playing_time ]);

  function TimePreparation ( _time ) {

    // Добавляем часовой пояс и вычитаем странную фантомную минуту которая куда-то пропадает

    if ( !_time || _time === "" ) { return ""; }

    const _date_ = {

      h: _time.split(':')[ 0 ],
      m: parseInt(_time.split(':')[ 1 ]) - 1,
      s: _time.split(':')[ 2 ],

    }

    if (_date_.m < 0){

      _date_.h -= 1
      _date_.m = 59

    }

    return `${ twoDigitFormat( parseInt( _date_.h) ) }:${ twoDigitFormat( _date_.m ) }:${ twoDigitFormat( parseInt(_date_.s) ) }`;

  }

  timeStart = TimePreparation( timeStart )
  timeEnd = TimePreparation( timeEnd)

  function declOfNum( n, text_forms ) {

    n = Math.abs(n) % 100;
    var n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 === 1) { return text_forms[0]; }
    return text_forms[2];

  }
  
  const text_forms = ['посещение', 'посещения', 'посещений']

  const EventDefault = (

    <div className = {`event ${ cssIf( isActive, 'event-active' ) }`} ref = { thisEvent }>

      <div className = "event_info">

        { controls &&

          <div className = "event_buttons">

            {/* <button

                className="event_buttons__item event__btn_icon event__btn_icon__info"
                onClick = { onInfoClick }

              /> */}

            <button

              className={`event_buttons__item event__btn_icon event__btn_icon__check ${ cssIf( confirmed, "checked" )}`}
              onClick = { () => { onCheckClick(); setConfirmed( !confirmed ); } }

            />

            <button

              className = {`event_buttons__item event__btn_icon event__btn_icon__warning ${ cssIf( important, "checked" )}`}
              onClick = { () => { onWarningClick(); setImportant( !important ); } }

            />

            <button

              className = {`event_buttons__item event__btn_icon event__btn_icon__cancel ${ cssIf( removed, "checked" )}`}
              onClick = { () => { if ( removed || window.confirm(`Удалить событие?`) ) { onCancelClick(); setRemoved( !removed ); }} }

            />

          </div>

        }

        <div className = "event_info__top" onClick = { onPlayFromStart }>

          <div className = "event__icon event__icon__calendar event_p" />
          <div className = "event__date event_p">{ date }</div>

        </div>

        <div
          className = "event_info__middle"
          onClick = { onPlayFromStart }
        >

          <div className = "event__icon event__icon__time event_p" />

          <div className = "event__time event_p">

            <div className = "event__time_val">{ timeStart }</div>
            {/* DEBUG <div className = "event__time_val">{ timeline_seconds } - { playing_time } [{parseInt(timeline_seconds) === parseInt(playing_time) ? "true" : "false"}]</div> */}

            { timeDiff &&

              <React.Fragment>

                { timeEnd?.length > 0 &&

                  <React.Fragment>

                    <div className = "event__arrow_icon" />

                    <div
                      title = "Перемотать на завершение события"
                      className = "event__time_val event__time_end"
                      onClick = {( e ) => {

                        e.stopPropagation();
                        onPlayFromEnd();

                      }}
                    >

                      { timeEnd }

                    </div>

                  </React.Fragment>

                }

                <div className = "event__time_diff">({ timeDiff })</div>

              </React.Fragment>

            }

          </div>

        </div>

        <div

          className = "event_info__bottom event_p"
          data-i = { i }
          onClick = { onPlayFromStart }

        >

          { label }

        </div>

        { vehicle?.visits_counter &&

          <div

            className = "event_label_counter"
            data-i = { i }
            onClick = { onCounterClick }

          >
            { vehicle.visits_counter + ' ' + declOfNum( vehicle.visits_counter, text_forms ) }

          </div>
        
        }

        <div className="event_label" ref={ labelRef }>{ text }</div>

        { vehicle?.gosnumber &&

          <div
          
            className="event_label event_label--2`"
            style={{ left: `${ labelRef?.current?.clientWidth + 22 }px`, backgroundColor: black_list ? 'var(--color-darkred)' : white_list ? 'var(--color-green)' : 'none'}}
            title="Госномер"
            
          >

            { vehicle.gosnumber }

          </div>

        }

      </div>

    </div>

  );

  return ( 
    
    !( ( removed && showRemoved ) || !removed ) 
  
      ? null : person?.name

      ? <Face

          id = { person.id }
          index = { i }
          name = { person.name }
          old = { person?.old || "?" }
          sex = { person?.sex || "-" }
          role = { person?.role || "" }
          category = { person?.category || "" }
          time = {{ start: timeStart, end: timeEnd }}
          date = { date }
          photo = { person.photo }
          isOneLocationEvent = { isOneLocationEvent }
          
          timeDiff = { timeDiff }
          isActive = { isActive }
          thisEvent = { thisEvent }

          onPlayFromStart = { onPlayFromStart }
          onPlayFromEnd = { onPlayFromEnd }

          onCheckClick = { onCheckClick }
          onWarningClick = { onWarningClick }
          onCancelClick = { onCancelClick }

          isConfirmed = { isConfirmed }
          isImportant = { isImportant }
          isRemoved = { isRemoved}

          controls = { controls }

        />

      : EventDefault     

  );

}

export default Event;