import axios from "axios";
import cookie from "react-cookies";
import API from "../../settings/api";

export async function allUserMarkups(store, reload = false) {
  const token = cookie.load("token");
  if (!token) {
    console.error("Попытка разлогина из all userMarkups");
    return false;
  }

  reload && reload();

  const { status, data } = await axios.post(API.userMarkups.all, {
    token: token,
  });

  console.log(`UserMarkups status: ${status}`);

  if (status === 200 && data?.markups) {
    store && store.setState({
      userMarkupTable: data.markups,
      userMarkupTableLoaded: true,
    });

    return data.markups;
  } else {
    console.error(data.text);
  }
}

export async function childMarkups() {
  const token = cookie.load("token");
  if (!token) {
    console.error("Попытка разлогина из all userMarkups");
    return false;
  }

  const { status, data } = await axios.post(API.userMarkups.child, {
    token: token,
  });

  console.log(`UserMarkups status: ${status}`);

  if (status === 200 && data?.markups) return data.markups;
  else console.error(data.text);

}

export async function getUserMarkup(store, id) {
  const token = cookie.load("token");
  if (!token) {
    console.error("Попытка разлогина из get userMarkup");
    return false;
  }

  const params = { token, id };
  const { status, data } = await axios.post(API.userMarkups.get, params);

  console.log(`UserMarkups status: ${status}`);

  if (status === 200 && data?.markup) {
    store &&
      store.setState({
        userMarkup: data.markup,
        userMarkupLoaded: true,
      });

    return data.markup;
  }
}

export async function addUserMarkup(form, reload) {
  const token = cookie.load("token");
  if (!token) {
    console.error("Попытка разлогина из add userMarkups");
    return false;
  }

  const params = {
    token,
    ...form,
  };

  console.log(`userMarkups.add form:`, params);

  const { status, data } = await axios.post(API.userMarkups.add, params);

  if (status === 200) {
    reload();
  } else {
    console.error(data.text);
  }
}

export async function editUserMarkup(form, reload) {
  const token = cookie.load("token");
  if (!token) {
    console.error("Попытка разлогина из edit userMarkups");
    return false;
  }

  const params = {
    token,
    ...form,
  };

  console.log(`userMarkups.edit form:`, params);

  const { status, data } = await axios.post(API.userMarkups.edit, params);

  if (status === 200) {
    reload();
  } else {
    console.error(data.text);
  }
}

export async function removeUserMarkup(store, id, reload) {
  if (
    window.confirm(
      "Вы действительно хотите удалить данную разметку? Отменить это действие невозможно!"
    )
  ) {
    const token = cookie.load("token");
    if (!token) {
      console.error("Попытка разлогина из remove userMarkups");
      return false;
    }

    const { status, data } = await axios.post(API.userMarkups.remove, {
      token: token,
      id: id,
    });

    if (status === 200) {
      reload && reload();
    } else {
      console.error(data.text);
    }
  }
}
