import './popup.people.scss';

import React, {useEffect, useState} from 'react';
import { Popup, popupClose }                  from '../index';
import PopupTab                               from "../../components/popup.tab";
import Selectfield                            from '../../components/selectfield';
import Textfield                              from '../../components/textfield';
import Button                                 from '../../components/button';
import {Roles as getRoles, Categories as getCategories} from "../../../store/actions/people";
import { edit as Edit }        from '../../../store/actions/people';
import useGlobal from "../../../store";


export const PeopleEdit = (person) => {

  const [ globalState, globalActions ]  = useGlobal()

  const reload = () => {

    globalActions.people.all();
    popupClose();

  }
  return (

    <PeoplePopup  // Popup людей

      mode = "edit"
      func = { ( form ) => {

        Edit(form, reload)

      }}
      person = { person.person }

    />

  );

}


export const PeoplePopup = ( props ) => {

  const TITLE = {
    edit: "Редактирование записи",
  }
  const FUNC_BTN_TEXT = {
    edit: "Сохранить",
  }

  const sex_categories = [
    'Мужской',
    '-',
    'Женский',
  ]

  const { id, category, name, old, sex, role } = props.person

  const [ Name, setName ] = useState( name );

  const [ Category, setCategory ] = useState(category);

  const [ Sex, setSex ] = useState(sex === 1 ? "Мужской" : sex === 2 ? "Женский" : '-' )

  const [ Role, setRole ] = useState(role)

  const [ Age, setAge ] = useState( old !== '?' ? old : '');

  // const [ BlackList, setBlackList ] = useState( props.black_list  || false );
  //
  // const [ WhiteList, setWhiteList ] = useState( props.white_list || false );

  const [ categories, setCategories ] = useState(false)  // Список локаций которые можно будет выбрать в Popup-е
  const [ roles, setRoles ] = useState(false)  // Список сотрудников которых можно будет выбрать в Popup-е

  useEffect(() => {
    if (!categories && !roles) {
      getCategories(setCategories)
      getRoles(setRoles)

    }

  }, [ roles, categories ]);

  useEffect(() => {
    if ( !Category?.id && categories ){

      for ( const item of categories ){

        if ( item.name === Category){

          setCategory(item);
          break;
        }
      }
    }
  }, [categories.length])

  useEffect(() => {
    if ( !Role?.id && roles ){

      for ( const item of roles ){

        if ( item.name === Role){

          setRole(item);
          break;
        }
      }
    }
  }, [ roles.length ])

  function sendForm() {

    let form = {

      id,
      category_id: Category.id,
      role_id: Role.id,
      name: Name,
      sex: Sex,
      old: Age,
      // white_list: WhiteList,
      // black_list: BlackList

    }

    console.log( `sendForm:`, form );
    props.func( form );

  }


  return (

    <Popup className = "popup_people">

      <div className={'popup_people__tab flex items-center'}>
        <PopupTab

          color= { 'orange'}

          text = "Сотрудник"

        />

      </div>

      <div className="popup__title">{ TITLE[ props.mode ]} сотрудника { props?.name && `- ${ props.name }` }</div>

      <div className="flex">

        <div className="popup_people__inputs">

          <div className="popup_people__inputs__row popup_people__inputs__row-1 flex items-center">

              < Textfield
              title = "Имя"
              value = { Name }
              set = { setName }

              />

              <Textfield

              title = "Возраст"
              placeholder = "30"
              value = { Age }
              set = { setAge }

              />

            <Selectfield

              title = "Пол"
              object = "Пол"
              list = { sex_categories }
              selected = { Sex }
              select = { setSex }

            />
          </div>

          <div className="popup_people__inputs__row popup_people__inputs__row-3 flex items-center">


            <Selectfield

              title = "Категория"
              object = "Категория"
              list = { categories }
              selected = { Category }
              select = { setCategory }

            />

            <Selectfield

              title = "Должность"
              object = "Должность"
              list = { roles }
              selected = { Role }
              select = { setRole }

            />

          </div>

          {/*<div className="popup_export__inputs__row popup_export__inputs__checkbox flex">*/}

          {/*  <Checkbox*/}
          {/*    text = "Черный список"*/}
          {/*    value = { BlackList }*/}
          {/*    set = { setBlackList }*/}
          {/*    disabled = {WhiteList}*/}
          {/*  />*/}
          {/*</div>*/}

          {/*<div className="popup_export__inputs__row popup_export__inputs__checkbox flex">*/}
          {/*  <Checkbox*/}
          {/*    text = "Важный"*/}
          {/*    value = { WhiteList }*/}
          {/*    set = { setWhiteList }*/}
          {/*    disabled = {BlackList}*/}
          {/*  />*/}

          {/*</div>*/}


          <div className = "flex">

            <Button

              text = { FUNC_BTN_TEXT[ props.mode ]}
              action = { sendForm }

            />

            <Button

              text = "Отмена"
              action = { popupClose }
              color = "gray"

            />

          </div>

        </div>

      </div>

    </Popup>

  );

}