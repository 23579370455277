import React from "react";
import useGlobal from "../../../store";
import FacesTable from "./facesGridPage";

const Faces = () => {
  const [globalState] = useGlobal();

  const { user_type } = globalState;

  return <React.Fragment>{user_type <= 3 && <FacesTable />}</React.Fragment>;
};

export default Faces;
