import React, { useEffect } from 'react';
import useGlobal from '../../../store';
import TableHead from '../../components/table.head';
import './admin.scss';

import Table from '../../components/table';
import LoadingSpinner from '../../components/loader';
import useReloadDummy from '../../../scripts/hooks/use.reload.dummy';

const Users = ({}) => {

  const [ globalState, globalActions ] = useGlobal();
  const { 

    usersTable,
    usersTableLoaded 

  } = globalState;

  const { popup, users } = globalActions;

  const [ Dummy, reloadDummy ] = useReloadDummy();

  useEffect(() => { 
    
    users.all( reloadDummy );
  
  }, []);

  const TABLE_HEAD = [
    {
      name: "ФИО",
      sort: false,
      // action: () => handleSort("checkinsCount")
    },
    {
      name: "Электронная почта",
      sort: false,
      // action: () => handleSort("createdAt")
    },
    {
      name: "Телефон",
      sort: false
    },
    {
      name: "Доступ к камерам",
      sort: false
    },
    {
      name: "Ред-е событий",
      sort: false
    },
    {
      name: "Действия",
      sort: false
    }

  ]

  return (

    <div className="admin-container">

      <div className="admin admin-cameras">

        <TableHead

          title = "Пользователи"
          action = { () => popup.showPopup('userAdd') }

        />

        <Dummy />

        { !usersTableLoaded
          
          ? <LoadingSpinner />
          
          : <Table

              className = "users"
              template = "1fr 220px 170px 110px 100px 112px"
              columns = { TABLE_HEAD }
              rows = { usersTable } 
              total = { usersTable.length } 
              edit = { ( id ) => popup.showPopup( "userEdit", { id } ) }
              remove = { users.remove }
              loadMore = { () => {} } 
              openModal = { () => {} }
              
            />

        }

      </div>

    </div>

  );

}

export default Users;