import React, { useEffect } from "react";
import useGlobal from "../../../store";
import TableHead from "../../components/table.head";
import "./MarkupsConfigurationsPage.scss";

import LoadingSpinner from "../../components/loader";
import useReloadDummy from "../../../scripts/hooks/use.reload.dummy";
import Table from "../../components/table";
// import {allUserMarkups} from "../../../store/actions/user.markups";

const MarkupsConfigurationsView = () => {
  const [globalState, globalActions] = useGlobal();
  const { userMarkupTable, userMarkupTableLoaded } = globalState;

  const { userMarkups } = globalActions;

  const [Dummy, reloadDummy] = useReloadDummy();

  useEffect(() => {
    userMarkups.allUserMarkups(reloadDummy);
  }, []);

  const reload = () => {
    userMarkups.allUserMarkups(reloadDummy);
  };

  const TABLE_HEAD = [
    {
      name: "Название",
      sort: false,
      // action: () => handleSort("alphabetical")
    },
    {
      name: "Название модели",
      sort: false,
    },
    {
      name: "Название камеры",
      sort: false,
    },
    {
      name: "Действия",
      sort: false,
    },
  ];

  function switchToEdit(id) {
    window.location.href = `/draw?mode=edit&id=${id}`;
  }

  return (
    <div className="models-container">
      <div className="models models-records">
        <TableHead
          title="Разметка моделей компьютерного зрения"
          action={() => (window.location.href = "/draw")}
          // AdditionalButton={ isAdmin && AdminTemplateButton }
        />

        <Dummy />

        {!userMarkupTableLoaded ? (
          <LoadingSpinner />
        ) : (
          <Table
            className="models"
            template="220px 200px 250px 1rem"
            columns={TABLE_HEAD}
            rows={userMarkupTable}
            total={userMarkupTable.length}
            remove={id => {
              userMarkups.removeUserMarkup(id, reload);
            }}
            edit={id => {
              switchToEdit(id);
            }}
            loadMore={() => {}}
            openModal={() => {}}
          />
        )}
      </div>
    </div>
  );
};

export default MarkupsConfigurationsView;
